//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop
} from "../../lib";

import FeaturedArticle from './FeaturedArticle';
import Articles from './Articles';

import articles from '../../util/resources-providers-articles.json';

import './ResourcesAbout.scss';

import ReactGA from 'react-ga';

class ResourcesProviders extends React.Component {

  constructor (props) {

    super(props);

    this.state = {
      filter: "",
    };

  }

  handleFilter = filter => {
    this.setState({ filter });
  }

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div>

        <Navbar type="providers" />

        <header className="page-header float-hand">
          <div className="hand-right d-none d-md-block">
            <img src="/img-doc-left-hand-cropped.png" alt="hand holding form" className="w-100" />
          </div>
          <div className="container-fluid bgc g3 round">
            <div className="copy-container">
              <div className="copy">
                <h2 className="mb-4 mt-4">
                  The only Endocrine Biomarker
                </h2>
                <p>
                  <b>
                    Level 1B evidence established as a predictive biomarker for extended endocrine therapy response in early-stage, HR+ breast cancer.
                  </b>
                </p>
                <p>
                  Breast Cancer Index is the only test validated to predict a patient’s benefit from extended endocrine therapy so you can determine whether five versus 10 years of treatment is right for her.
                </p>

                <p className="text-center text-md-left mt-5">
                  <a href="#" className="btn btn-primary btn-animated mb-4">Explore BCI Predictive Data</a>
                </p>
              </div>
            </div>
            <div className="bg-pattern"></div>
          </div>
        </header>

        {/*<!-- CONTENT AREA -->*/}
        <div className="container articles">
          <div className="row">
            {/*<!-- LEFT COLUMN -->*/}
            <div className="col-12 col-md-8 left-col">

              {/*<!-- FEATURED ARTICLE -->*/}
              <FeaturedArticle { ...articles[0] } />

              {/*<!-- REGULAR ARTICLES -->*/}
              <Articles articles={articles} selectedArticles={[2,3,4,5,6,7,8,9]} filter='' />

            </div>

            {/*<!-- RIGHT COLUMN -->*/}
            <div className="col-12 col-md-4 p-4 right-col">

              {/* <!-- 1 --> */}
              <article>
                <h6>NEWS &amp; INSIGHTS</h6>
                <h5>
                  Breast Cancer IndexTM and new data on its power to predict extended endocrine therapy benefit presented at ASCO<sup>®</sup> Clinical Practice Guideline 2019
                </h5>
                <p className="truncate fade-out">
                  The power of prediction informing 5 vs. 10 years of endocrine therapy for early-stage, hormone receptor-positive breast cancer.
                </p>
                <p>
                    <a href="/press-release-page" target="_blank" className="read-more">Keep Reading</a>
                </p>
                <h6 className="text-secondary">June 3, 2019</h6>
              </article>

              {/* <!-- 2 --> */}
              <article>
                <h6>NEWS &amp; INSIGHTS</h6>
                <h5>
                    Level 1B evidence established for BCI as a predictive biomarker for extended endocrine therapy
                </h5>
                <p className="truncate fade-out">
                    Results from Trans-aTTom further strengthen the predictive evidence supporting Breast Cancer Index.
                </p>
                <p>
                    <a href="https://ascopubs.org/doi/abs/10.1200/JCO.2019.37.15_suppl.505" target="_blank" className="read-more">Keep Reading</a>
                </p>
              </article>
            </div>

          </div>
        </div>

        <BackToTop />

        <SubFooter
          image=""
          icons={[
            {
              href: "/order-test",
              img: "/icon-clipboard.svg",
              alt: " Order Breast Cancer Index",
              text1: "Order Breast",
              text2: "Cancer Index",
              className: "clipboard"
            },{
              href: "/explore-the-report-providers",
              img: "/icon-clover.svg",
              alt: " Explore the Test Report",
              text1: "Explore the",
              text2: "Test Report"
            },{
              href: "/resources-providers",
              img: "/icon-gear.svg",
              alt: " Resources for Providers",
              text1: "Resources",
              text2: "for Providers"
            }
          ]} />
        <Footer
            showTopLinks={true} topLinks={[
              {link: "/", label: "For Patients"},
              {link: "/know-someone-with-breast-cancer", label: "For Supporters"}
            ]}
            showSecondaryLinks={true} secondaryLinks={[
              {link: "/order-test", label: "Order The Test"},
              {link: "/contact-providers", label: "Contact"}
            ]} />
      </div>
    );
  }
}

export default ResourcesProviders;
