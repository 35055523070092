//Copyright Breast Cancer Index || 2021

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop,
    FloatingPetal
} from "../../lib";

import ReactGA from 'react-ga';

const Article3 = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc hcp-gradient round">
          <div className="clover-container">
            <div className="copy headline middle">
              <h1 className="mt-0 color-hologic-blue">
                It's Time<br></br>For A Change.
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />

      <div className="container content-page">
        <div className="row w-90 mx-auto">
          <div className="col-12 py-5 text-center">
            <h3 className="pb-2">
              Breast Cancer Index<sup>&reg;</sup>
            </h3>
            <h4 className="font1 clr c1">
              Predictive And Prognostic Information For Extended Endocrine
              Therapy Decision-Making
            </h4>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto">
          <div className="col-10 offset-1 py-5 text-center">
            <p>
              <b>
                Breast Cancer Index (BCI) is THE ONLY commercially available
                test validated to inform the decision regarding extended
                endocrine therapy based on:
              </b>
            </p>
          </div>
        </div>
        <div className="row w-90 max-700 mx-auto pb-5">
          <div className="col-12">
            <div className="align-items-center d-block d-sm-flex my-2">
              <div className="article-leaf bgc g5 my-4 my-sm-0 p-4 mx-auto ml-sm-0 mr-sm-5">
                Predictive
                 <br />
                Result:
              </div>
              <div>
                <p>Is she likely to benefit from extended endocrine therapy?</p>
                <ul className="mb-0">
                  <li>Reported as YES (High Benefit) vs NO (Low Benefit)</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="align-items-center d-block d-sm-flex my-2">
              <div className="article-leaf bgc g3 my-4 my-sm-0 p-4 mx-auto ml-sm-0 mr-sm-5">
                Prognostic
                 <br />
                Result:
              </div>
              <div>
                <p>What is her risk of late distant recurrence?</p>
                <ul className="mb-0">
                  <li>
                    Reported as an individualized percentage risk of late
                    distant recurrence
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto">
          <div className="col-12">
            <div className="bgc g6 my-5 py-5 px-3 text-center bracket-box">
              <div className="curly reverse mr-3 d-none d-sm-block">
                <img src="/shape-curly.svg" alt="" className="w-100" />
              </div>
              <h2 className="clr c1">
                Supported by Extensive Clinical Evidence for Extended Endocrine
                Therapy Decision-Making
              </h2>
              <div className="curly ml-3 d-none d-sm-block">
                <img src="/shape-curly.svg" alt="" className="w-100" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <h4 className="font1 clr c1 mb-4">BCI Predictive</h4>
            <p>
              BCI has demonstrated reproducible predictive performance for
              endocrine therapy response. BCI Predictive validations included
              patients with both node-negative and node-positive disease.
            </p>
            <a
              href="/predictive-power-of-bci"
              className="btn btn-primary hcp mt-4 mb-5"
            >
              Read More
            </a>
          </div>
          <div className="col-12 col-md-6 text-center">
            <h4 className="font1 clr c1 mb-4">BCI Prognostic</h4>
            <p>
              The ability of BCI to determine an individual’s risk of late
              distant recurrence has been validated across multiple studies,
              including various treatment histories, and a mix of pre- and post-
              menopausal patients.
            </p>
            <a
              href="/prognostic-validation"
              className="btn btn-primary hcp mt-4 mb-5"
            >
              Read More
            </a>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto pt-5">
          <div className="col-12 text-center">
            <h3>How It Works</h3>
            <h4 className="font1 clr c1 mx-auto mt-4 mb-5">
              In one test, BCI combines two complementary gene-expression
              signatures to assess proliferative and estrogen signaling
              pathways.<sup>1-5</sup>
            </h4>
          </div>
        </div>
        <div className="row w-90 max-700 mx-auto pb-4">
          <div className="col-12 col-md-6">
            <h4 className="font1 clr c1 mb-4 text-center">BCI Predictive</h4>
            <ul>
              <li>Molecular assessment of estrogen signaling pathways</li>
              <li>Genes: HOXB13/IL17BR (H/I)</li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <h4 className="font1 clr c1 mb-4 text-center">BCI Prognostic</h4>
            <ul>
              <li>
                Algorithmic combination of Molecular Grade Index (MGI:
                proliferation) and HOXB13/IL17BR (H/I: estrogen signaling
                pathway)
              </li>
              <li>
                Genes: BUB1B, CENPA, NEK2, RACGAP1, RRM2, HOXB13/IL17BR (H/I)
              </li>
              <li>
                For N1 patients: Risk is determined by incorporating the tumor
                size and grade with the BCI gene expression signature
                <sup>3</sup>
              </li>
            </ul>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto py-5">
          <div className="col-12 text-center">
            <p className="ref">
			  <strong>References:</strong>{' '}
			  <strong>1.&nbsp;</strong>Zhang Y, et al. Clin Cancer Res. 2013;19: 4196-4205.{' '}
			  <strong>2.&nbsp;</strong>Sgroi DC, et al. Lancet Oncol. 2013;14: 1067-76.{' '}
			  <strong>3.&nbsp;</strong>Zhang Y et al. Clin Cancer Res. 2017 Dec 1;23(23):7217-7224.{' '}
			  <strong>4.&nbsp;</strong>Sgroi DC, et al. J Natl Cancer Inst. 2013;105: 1036-1042.{' '}
			  <strong>5.&nbsp;</strong>Bartlett et al. Annals of Oncol 2019 doi: 10. 1093/annonc/mdz289.{' '}
            </p>
            <a
              href="/resources-providers"
              className="btn btn-secondary hcp mt-5 w-250"
            >
              Back To All
            </a>
          </div>
        </div>
        <div className="border-bottom"></div>
      </div>

      <BackToTop />

      <SubFooter
        site="hcp"
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard",
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: "Resources For Providers",
            text1: "Resources",
            text2: "For Providers",
          },
        ]}
        disclaimer=""
      />

      <Footer
        showTopLinks={true}
        topLinks={[
          { link: "/", label: "For Patients" },
          { link: "/know-someone-with-breast-cancer", label: "For Supporters" },
        ]}
        showSecondaryLinks={true}
        secondaryLinks={[
          { link: "/resources-providers", label: "Resources" },
          { link: "/contact-providers", label: "Explore The Report" },
          { link: "/contact-providers", label: "Contact" },
          { link: "/order-test", label: "Order The Test" },
        ]}
      />
    </div>
  );
}

export default Article3;
