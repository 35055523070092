//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import ReactGA from 'react-ga';

function images(article) {

  if ( article.headerImg ) {
    return (
      <div className="image">
        <img src={article.headerImg} alt="" className="w-100" />
      </div>
    ); 
  }

}

function processArticles(articles, selectedArticles, filter) {
  let articlesToShow = [];

  selectedArticles.forEach(selectedArticle => {
    articles.forEach(article => {

      if ( article.link && selectedArticle === article.id ) {
        articlesToShow.push(
          <div key={article.id} className="col-12 col-md-6">
            <article>
              { images(article) }
              <h6>{article.category}</h6>
              <h3>{article.title}</h3>
              <h4>{article.subtitle}</h4>
              <p>{article.preview}</p>
              <div className="button">
                <a target="_blank" href={article.link} className="btn btn-primary btn-animated my-4 px-5">Keep Reading</a>
              </div>
            </article>
          </div>
        );
      } else {
        if ( selectedArticle === article.id && ( filter === '' || filter === article.category ) ) {
          articlesToShow.push(
            <div key={article.id} className="col-12 col-md-6">
              <article>
                { images(article) }
                <h6>{article.category}</h6>
                <h3>{article.title}</h3>
                <h4>{article.subtitle}</h4>
                <p>{article.preview}</p>
                <div className="button">
                  <a target="_blank" href={`individual-article/${article.id}`} className="btn btn-primary btn-animated my-4 px-5">Keep Reading</a>
                </div>
              </article>
            </div>
          );
        }
      } 

    });
  });

  return articlesToShow;

}

function Articles({ articles, selectedArticles, filter }) {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div className="row">
      {processArticles(articles, selectedArticles, filter)}
    </div>
  );
}

export default Articles;