//Copyright Breast Cancer Index || 2021

import React from "react";

import { Navbar, SubFooter, Footer, BackToTop, FloatingPetal } from "../../lib";

import ReactGA from "react-ga";

const Article10 = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const smallBold = {
    fontSize: "11px",
  };

  return (
    <div>
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc hcp-gradient round">
          <div className="clover-container">
            <div className="copy headline middle">
              <h1 className="mt-0 color-hologic-blue">Results from the Recent IDEAL Study</h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />

      <div className="container content-page">
        <div className="row w-90 mx-auto">
          <div className="col-12 py-5 text-center">
            <h3 className="pb-2">
              Results from the IDEAL study demonstrate Breast Cancer Index<sup>&reg;</sup> predicts benefit from extending aromatase inhibitor (AI) therapy
            </h3>
            <h4 className="font1 clr c1">
              The largest Breast Cancer Index validation expands on
              previous evidence, establishing the biomarker as being agnostic to
              tamoxifen (TAM) or AI therapy in women with HR+, early-stage
              breast cancer.
            </h4>
          </div>
        </div>
        <div className="border-bottom"></div>

        <div className="col-12 text-center">
          <p className="font1 mt-5">
            <b>STUDY DESIGN</b>
          </p>
        </div>
        <ul>
          <li>908 patients with HR+, early stage disease</li>
          <li>
            Patients randomized to 5 vs 2.5 years of extended letrozole
            following an initial 5 years of adjuvant endocrine therapy:
          </li>
            <li>
              Primary adjuvant AI (TAM/AI in sequence or AI monotherapy) - 87%
              (N=794)
            </li>
            <li>Primary adjuvant TAM - 13%</li>
        </ul>
        <div className="border-bottom"></div>
      </div>

      <div className="container content-page">
        <div className="row w-90 max-700 mx-auto pt-5">
          <div className="col-12 text-center">
            <h4 className="font1 clr c1 mx-auto my-3">
              Risk reduction and absolute benefit for patients whose primary
              adjuvant treatment included an AI:
            </h4>
          </div>
        </div>
        <div className="row w-90 max-700 mx-auto pb-5">
          <div className="col-12 col-md-6 text-center">
            <b>BCI Yes (Likely to Benefit, H/I High)</b>
             <br />
            was associated with a 66% relative risk reduction, 11.8% absolute
            benefit from a full 5 years of extended letrozole (P = 0.0036)
          </div>
          <div className="col-12 col-md-6 text-center">
            <b>BCI No (Not Likely to Benefit, H/I Low)</b>
             <br />
            was associated with no significant benefit from longer treatment (P
            = 0.7116)
          </div>
        </div>
        <div className="border-bottom"></div>
      </div>

      <div className="container content-page">
        <div className="row w-90 max-700 mx-auto pt-2">
          <div className="col-12 text-center">
            <h4 className="font1 clr c1 mx-auto my-3">
              BCI effectively stratified patients into those who would or would
              not be likely to benefit from a full 5 years of extended treatment
              for those treated with primary adjuvant AI therapy
            </h4>
            <p className="font1 mt-5">
              <b>
                Relative benefit of extended AI treatment by BCI (H/I) status
              </b>
            </p>

            <img src="/articles/ideal-chart-1.svg" alt="" className="w-20" />
          </div>
        </div>
      </div>

      {/* <!--QUARTER ROUND --> */}
      <section className="qtr-round-article my-5">
        <div className="container content">
          <div className="row">
            <div className="col-10 offset-1 col-sm-11 pr-0 pr-sm-5">
              <h2 className="pr-0 pr-sm-5">
                “Breast Cancer Index used a discovery gene analysis that was
                unbiased and the genes chosen had the highest correlation with
                endocrine response and outcomes. As such, Breast Cancer Index
                may prove to be drug class agnostic and a true biologic marker
                for extended endocrine therapy benefit."
                 <br />
                <h2 className="text-right">- Mark Pegram, MD</h2>
              </h2>
            </div>
          </div>
        </div>
        <div className="round-bg bgc hcp-gradient horizontal"></div>
      </section>

      <div className="container content-page">
        <div className="row w-90 max-700 mx-auto pt-5">
          <div className="col-12 text-center">
            <h4 className="font1 clr c1 mx-auto my-3">
              Risk reduction and absolute benefit for patients in the overall
              cohort:
            </h4>
          </div>
        </div>
        <div className="row w-90 max-700 mx-auto pb-5">
          <div className="col-12 col-md-6 text-center">
            <b>BCI Yes (Likely to Benefit, H/I High)</b>
             <br />
            was associated with a 58% relative risk reduction, 9.8% absolute
            benefit from a full 5 years of extended letrozole (P = 0.0111)
          </div>
          <div className="col-12 col-md-6 text-center">
            <b>BCI No (Not Likely to Benefit, H/I Low)</b>
             <br />
            was associated with no significant benefit from longer treatment (P
            = 0.8354)
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto pt-2">
          <div className="col-12 text-center">
            <h4 className="font1 clr c1 mx-auto my-3">
              BCI effectively stratified patients into those who would or would
              not be likely to benefit from extended treatment in the overall
              cohort
            </h4>
            <p className="font1 mt-5">
              <b>
                Relative benefit of extended AI treatment by BCI (H/I) status
              </b>
            </p>

            <img src="/articles/ideal-chart-2.svg" alt="" className="w-100" />
          </div>
        </div>

        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <h4 className="font1 clr c1 mx-auto mt-4 mb-5">
              Read the Full Publication
            </h4>
            <p>
              <a
                href="https://clincancerres.aacrjournals.org/content/early/2020/10/27/1078-0432.CCR-20-2737"
                target="_blank"
              >
                Breast Cancer Index predicts extended endocrine benefit to
                individualize selection of HR+ early stage breast cancer
                patients for 10 years of endocrine therapy
              </a>
            </p>
          </div>
        </div>
        <div className="border-bottom"></div>

        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <p className="ref">
              <strong>References: 1.&nbsp;</strong>Noordhoek et al. Clin Cancer Res. Oct 2020. DOI: 10.1158/1078-0432.CCR-20-2737<br />Mark Pegram, MD is a medical advisor for Biotheranostics, Inc.
            </p>
            <a href="/resources-providers" className="btn btn-secondary hcp mt-5">
              Back To All
            </a>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter
      site="hcp"
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard",
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: "Resources For Providers",
            text1: "Resources",
            text2: "For Providers",
          },
        ]}
        disclaimer=""
      />

      <Footer
        showTopLinks={true}
        topLinks={[
          { link: "/", label: "For Patients" },
          { link: "/know-someone-with-breast-cancer", label: "For Supporters" },
        ]}
        showSecondaryLinks={true}
        secondaryLinks={[
          { link: "/resources-providers", label: "Resources" },
          { link: "/contact-providers", label: "Explore The Report" },
          { link: "/contact-providers", label: "Contact" },
          { link: "/order-test", label: "Order The Test" },
        ]}
      />
    </div>
  );
};

export default Article10;
