//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import ReactGA from 'react-ga';

function FeaturedArticle({ id, category, headerImg, title, subtitle, preview, date, author }) {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div key={id} className="row">
      <div className="col-12">
        <article className="featured">
          <div className="image">
            <img src={headerImg} alt="" className="w-100" />
          </div>
          <h6>{category}</h6>
          <h3>{title}</h3>
          <h4>{subtitle}</h4>
          <p>{preview}</p>
          <div className="button">
            <div className="button">
              <a target="_blank" href={`individual-article/${id}`} className="btn btn-primary btn-animated my-4 px-5">Keep Reading</a>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
}

export default FeaturedArticle;