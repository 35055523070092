//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';
import { Modal, Button } from 'react-bootstrap';


function ModalItem(mod) {
    return (    
        <div>
            <Modal className="fade" >
                <Modal.Dialog className="modal-dialog-centered modal-md" role="document">
                <div className="modal-content white">
                    <Modal.Header>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                        <div className="col-12">
                            <div className="btn btn-popmodal count">1</div>
                            <p className="p-3 p-md-5">                               
                                {mod.Body}
                            </p>
                        </div>
                        </div>
                    </div>
                    </Modal.Body>
                </div>
                </Modal.Dialog>
            </Modal>
        </div>
    );
}

function ExploreModals(props) {
    let modalItems = props.modals;

        modalItems = modalItems.filter(mod => modalItems.includes(mod.id));
        modalItems = modalItems.map(mod => (<ModalItem {...mod} />));

        return;
}

export default ExploreModals;