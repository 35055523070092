import React, { useState } from 'react';
import { Navbar, SubFooter, Footer, BackToTop, DownloadModal, SEO } from "../../lib";
import { FloatingPetal } from "../../lib";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton } from 'react-share';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom'

const KnowSomeoneWithBreastCancer = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [mTitle, setTitleText] = useState("");
  const [mBody, setBodyText] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (type) => {
    if(type === "patient"){
      setTitleText("Download the Conversation Guide");
      setBodyText("");
    }else if(type === "provider"){
      setTitleText("Order Breast Cancer Index");
      setBodyText("Download the Conversation Guide");
    }
    setShow(true);
  }

  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div>
      <SEO title='Supporting Someone With Breast Cancer' description='Looking to support someone diagnosed with breast cancer? Breast Cancer Index is here with resources to help you navigate the best ways to show support.' />
      <Navbar type="patients" />
      
      {/* <!-- HEADER - I KNOW SOMEONE --> */}
      <header className="page-header patient-side">
        <div className="container-fluid not-round">
          <div className="clover-container">
            <div className="copy">
              <h1 className="quote">
                I Know Someone With Breast Cancer, I Wish I Knew How To Help.
              </h1>
            </div>
            <div className="photo large">
              <img src="/img-hero4-header.png" alt="" className="w-100" />
            </div>
            <div className="photo-clover">
              <img src="/clover-red.svg" alt="" className="w-100" />
            </div>
          </div>
        </div>
      </header>

      <FloatingPetal type="patient"/>
      <DownloadModal show={show} title={mTitle} body={mBody} handleClose={handleClose} />

      {/* <!-- SECONDARY --> */}
      <section className="d-flex">
        <div className="secondary-header bgc g1">
          <div className="copy">
            <p>
              A cancer diagnosis shakes a woman to the core. It also means she’s completely overwhelmed while suddenly faced with a million decisions. As a supporter of someone with breast cancer, one of the best ways to help is simply to offer a shoulder to lean on.
            </p>
            <p>
              If your friend or loved one has been diagnosed with early-stage, hormone receptor–positive (HR+) breast cancer, you may be looking for information to share with her as she navigates the overwhelming realm of decisions related to her treatment.
            </p>
            <p>
              This site can provide her with information she and her oncology team need as they consider options for her care.
            </p>
            </div>
          <div className="bg-pattern"></div>
        </div>
      </section>    

      {/* <!-- TITLE --> */}
      <section>
        <div className="text-center p-5">
          <h1 className="page-title m-4">
            You can’t take the fear out of cancer. <br />
            <span>
              But you can take the <br />uncertainty out of treating it.
            </span>
          </h1>
        </div>
      </section>

      {/* <!--FULL ROUND --> */}
      <section className="full-round right mb-5 w-100">
        <div className="image">
          <img src="/img-logomark-hero7.png" alt="" />
        </div>
        <div className="content">
          <div className="copy ml-auto">
            <h2 className="mb-4 mt-4">
              What is HR+ Breast Cancer?
            </h2>
            <p>
              HR+ breast cancer grows in response to estrogen and/or progesterone, hormones naturally produced in the body. It may also be referred to as estrogen receptor–positive (ER+) or progesterone receptor–positive (PR+).
            </p>
            <p>
              According to the <a href="http://www.cancer.org/cancer/breast-cancer/treatment/hormone-therapy-for-breast-cancer.html" target="_blank">American Cancer Society</a>, about two out of three breast cancers are HR+.
            </p>
            <h5 className="font1">
              How Is Anti-Estrogen Therapy Used To Treat HR+ Breast Cancer?
            </h5>
            <p>
              Most women with HR+ breast cancer are prescribed anti-estrogen therapy as part of their treatment, which may suppress estrogen and/or progesterone to help slow or stop additional cancer growth.
            </p>
            <p>
              While the duration of treatment may vary from patient to patient, at least 5 years of anti-estrogen therapy is typically prescribed with the goal of preventing the cancer from recurring.
            </p>
          </div>
          <div className="bg"></div>
        </div>
      </section>

      {/* <!--HALF ROUND --> */}
      <section className="half-round mb-5">
        <div className="image">
          <img src="/img-doc-left-hand-cropped.png" alt="hand holding form" />
        </div>
        <div className="content p-5">
          <div className="copy">
            <h2 className="mb-4 mt-4">
              Nearly 95% of Women Do Not Benefit from Anti-Estrogen Therapy Beyond 5 Years.<sup>1-7</sup>
            </h2>
            <p>
              While some women may benefit from continuing anti-estrogen therapy after 5 years, many women do not.
            </p>
            <p>
              Together, she and her oncology team must decide whether the potential benefit of extending her treatment outweighs the potential side effects and risks associated with the medication.
            </p>
            <p>
              Whether or not she is likely to benefit, Breast Cancer Index<sup>&reg;</sup> gives her and her oncology team information they need to make a more informed treatment decision.
            </p>
            <p>
              If you know someone with HR+ breast cancer, use the button below to share a link to this site with her. 
            </p>
            <div className="icon">
              <img src="/icon-heart.svg" alt="Share Breast Cancer Index" className="w-100" />
            </div>
            <p className="text-center text-md-left">
              <div className="share-container">
                <div className="btn btn-primary with-border mb-2 share-btn"><img src={('/icon-share.svg')} alt='' />Share</div>
                <div className="share-tray btn-primary with-border btn">
                  <LinkedinShareButton children={<img src={'/LinkedIn icons.svg'} width='20px' height='20px' alt='LinkedIn icon' />} url={`https://breastcancerindex.com${location.pathname}`} />
					<TwitterShareButton children={<img src={'/Twitter icons.svg'} width='20px' height='20px' alt='Twitter icon' />} url={`https://breastcancerindex.com${location.pathname}`} />
					<FacebookShareButton children={<img src={'/FB icons.svg'} width='20px' height='20px' alt='Facebook icon' />} url={`https://breastcancerindex.com${location.pathname}`} />
					<EmailShareButton subject="Breast Cancer Index" body="Check out" url='https://breastcancerindex.com' children={<img src={'/Mail icons.svg'} width='20px' height='20px' alt='Email icon' />} />
                </div>
              </div>
            </p>
          </div>
        </div>
        <div className="round-bg"></div>
      </section>

      {/* <!-- JOURNEY --> */}
      <div className="container">            
        <div className="row">
          <div className="col-12 text-center p-5">
            <h2 className="mb-4">
              The Breast Cancer Timeline
            </h2>
            <p className="timeline-subtext">
              When a woman is diagnosed with breast cancer, it’s hard for her to think past the moment she’s in. The results of her Breast Cancer Index can help give her and her oncology team more certainty as they develop her treatment plan.
            </p>
          </div>
        </div>
      </div>

      {/* <!-- TIMELINE --> */}
      <section className="timeline">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12 order-2 order-md-1 col-md-6 left">
              <div className="odd">
                <h4>
                  Diagnosis Is The First Step
                </h4>
                <p>
                  The first point on the timeline for cancer treatment is what doctors refer to as “Time Zero.” For patients, this is a world-shattering time full of chaos and an overload of information.
                </p>
              </div>
            </div>
            <div className="col-12 order-1 order-md-2 col-md-6 right">
              <div className="zero">
                <img src="/timeline-zero.svg" alt="" className="w-100" />
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12 col-md-6 d-none d-md-block">
              &nbsp;
            </div>
            <div className="col-12 col-md-6 right">
              <div className="highlight bgc g4">
                <div className="curly">
                  <img src="/shape-curly.svg" alt="" className="w-100" />
                </div>
                <div className="copy">
                  <h4>Anti-Estrogen Therapy is The Standard Course of Treatment</h4>
                  <p>
                    While anti-estrogen, or “endocrine,“ therapy is a standard course of treatment for early-stage, hormone receptor–positive (HR+) breast cancer, the duration of treatment is an important decision for her and her oncology team to discuss.<sup>12</sup>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 left">
              <div className="odd hline">
                <h4>
                  Nearly 95% of Women Do Not Benefit from Anti-Estrogen Therapy After 5 Years<sup>1-6</sup>
                </h4>
                <p>
                  While most women with early-stage, HR+ breast cancer are prescribed anti-estrogen therapy, the duration of treatment may vary from patient to patient — at least 5 years of anti-estrogen therapy is usually recommended to help prevent a recurrence of your cancer.<sup>12</sup>
                </p>
                <p>
                  Breast Cancer Index will help her and her doctor determine whether a longer course of treatment is likely to benefit her.
                </p>
                <a className="btn btn-primary" onClick={() => handleShow("patient")}>Download The Conversation Guide</a>
              </div>
            </div>
            <div className="col-12 col-md-6 right d-none d-sm-block">
              <div className="photo1">
                <img src="/img-timeline3-2.jpg" alt="" className="w-100" />
              </div>
            </div>
            <div className="col-12">
              <div className="year5">
                <img src="/timeline-year-5.svg" alt="" className="w-100" />
              </div>
            </div>
            <div className="col-12 col-md-6 d-none d-md-block">
              <div className="photo2">
                <img src="/img-timeline4-3.jpg" alt="" className="w-100" />
              </div>
            </div>              
            <div className="col-12 col-md-6 right">
              <div className="even hline mt-5">
                <h4>
                She Deserves To Know If Additional Treatment Is Right For Her
                </h4>
                <p>
                For some women, extending anti-estrogen therapy through year 10 may reduce the risk of recurrence.
                </p>
                <h6>
                  Breast Cancer Index can help her and her doctor decide what’s right for her.
                </h6>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12 cta">
              <div className="year10">
                <img src="/timeline-year-10.svg" alt="" className="w-100" />
              </div>
              <div className="button">
                <a href="/faqs" className="btn btn-primary">Frequently Asked Questions</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <BackToTop />
      
      <SubFooter 
        image="/img-hero6.png"
        icons={[
          {
            href: "/contact-patients",
            img: "/icon-person.svg",
            alt: "Contact & Support",
            text1: "Contact",
            text2: "& Support"
          },{
            modal: true,
            img: "/icon-bubble.svg",
            alt: " Explore the Test Report",
            text1: "Download The",
            text2: "Conversation Guide"
          }
        ]} 
        type='hasHero'/>
      <Footer 
        showReferences={[true, false]}
        showTopLinks={true} topLinks={[
          { link: "/resources-providers", label: "For Providers" },
          { link: "/know-someone-with-breast-cancer", label: "For Supporters" }
        ]}
        showSecondaryLinks={true} secondaryLinks={[
          { link: "/FAQs", label: "FAQs" },
          { link: "/contact-patients", label: "Contact & Support" }
        ]} 
        type='patient'
      />
    </div>
  );
}

export default KnowSomeoneWithBreastCancer;
