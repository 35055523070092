import React from 'react';
import CookieConsent from 'react-cookie-consent';

import './Footer.scss';

function processReferences([showReferences, isSplash]) {
    if (showReferences && !isSplash) {
        return (
            <p>
                <b>References:</b>{' '}
                <strong>1.&nbsp;</strong>Davies C, et al. Lancet Oncol. 2013;381: 805-816.{' '}
				<strong>2.&nbsp;</strong>Gray R et al. J Clin Oncol 2013; 31: (suppl; abstr 5).{' '}
                <strong>3.&nbsp;</strong>Jakesz R, et al. J Natl Cancer Inst. 2007;99: 1845-1853.{' '}
				<strong>4.&nbsp;</strong>Goss PE, et al. J Natl Cancer Inst. 2005;97: 1262-1271.{' '}
                <strong>5.&nbsp;</strong>Goss PE et al. N Engl J Med. 2003;349.{' '}
				<strong>6.&nbsp;</strong>Mamounas EP et al. NSABP B-42. GS4-01. SABCS 2019. Dec 12, 2019.{' '}
                <strong>7.&nbsp;</strong>Adapted from this page.{' '}
				<strong>8.&nbsp;</strong>Femara Full Prescribing Information And Side Effects.{' '}
				<strong>9.&nbsp;</strong><a href='https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=acbfaaa9-503c-4691-9828-76a7146ed6de' target='_blank' rel='noopener noreferrer'>Armidex Full Prescribing Information And Side Effects</a>.{' '}
				<strong>10.&nbsp;</strong><a href='http://labeling.pfizer.com/ShowLabeling.aspx?id=523' target='_blank' rel='noopener noreferrer'>Aromasin Full Prescribing Information And Side Effects</a>.{' '}
				<strong>11.&nbsp;</strong>Tamoxifen Full Prescribing Information And Side Effects.{' '}
				<strong>12.&nbsp;</strong>Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup>®</sup>) for Breast Cancer V.2.2023. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed February 10, 2023. To view the most recent and complete version of the guideline, go online to <a href="https://www.nccn.org/" target='_blank' rel='noopener noreferrer'>NCCN.org</a>. The NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.{' '}
                <strong>13.&nbsp;</strong>Sgroi DC, et al. J Natl Cancer Inst. 2013;105:1036-1042.{' '}
				<strong>14.&nbsp;</strong>Sgroi DC, et al. Lancet Oncol. 2013;14:1067-76.
            </p>
        );
    } else if (showReferences && isSplash) {
        return (
            <p>
                <b>References:</b>
                <strong>1.&nbsp;</strong>Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup>®</sup>) for Breast Cancer V.2.2023. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed February 10, 2023. To view the most recent and complete version of the guideline, go online to <a href="https://www.nccn.org/" target='_blank' rel='noopener noreferrer'>NCCN.org</a>. The NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.{' '}
                <strong>2.&nbsp;</strong>Andre F et al. J Clin Oncol. Published online April 19, 2022. DOI: 10.1200/JCO.22.00069​. Referenced with permission from the American Society of Clinical Oncology (ASCO<sup>®</sup> Clinical Practice Guideline) Clinical Practice Guideline Biomarkers for Adjuvant Endocrine and Chemotherapy in Early-Stage Breast Cancer. © American Society of Clinical Oncology. 2024. All rights reserved. To view the most recent and complete version of the guideline, go online to <a href="https://ascopubs.org/jco/special/guidelines" target="_blank">https://ascopubs.org/jco/special/guidelines</a> [ascopubs.org].  ASCO<sup>®</sup> Clinical Practice Guideline makes no warranties of any kind whatsoever regarding their content, use of application and disclaims any responsibility for their application or use in any way.
            </p>
        );
    } else {
        return null;
    }
}

function processTopLinks(showTopLinks, topLinks) {
    if (showTopLinks) {
        return (
            <div className='highlight-links'>
                <ul>
                    {topLinks.map((link, idx) => {
                        return (
                            <li key={idx}>
                                <a href={link.link}>{link.label}</a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    } else {
        return null;
    }
}

function processSecodaryLinks(showSecondaryLinks, secondaryLinks) {
    if (showSecondaryLinks) {
        return (
            <div className='links'>
                <ul>
                    {secondaryLinks.map((link, idx) => {
                        return (
                            <li key={idx}>
                                <a href={link.link}>{link.label}</a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

const Footer = ({ showReferences = [false, false], showTopLinks = false, topLinks = [], showSecondaryLinks = false, secondaryLinks = [], type = '' }) => {
    return (
        <footer className={type}>
            <CookieConsent
                buttonText='I AGREE'
                flipButtons
                style={{ background: '#ffffff', color: '#333' }}
                buttonStyle={{
                    background: '#6F2877',
                    borderRadius: '50px',
                    color: 'white',
                    display: 'flex',
                    padding: '12px 40px',
                    fontSize: '12px',
                }}
                contentClasses='cookie-text'
            >
                <div style={{ maxWidth: '600px' }}>
                    This website uses cookies to provide you with the best possible experience. By using this website, you agree to our{' '}
                    <a target='_blank' href='/terms-and-conditions'>
                        terms and conditions
                    </a>{' '}
                    and{' '}
                    <a target='_blank' href='https://biotheranostics.com/privacy-practices.html'>
                        notice of privacy practices
                    </a>
                    .
                </div>
            </CookieConsent>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-3'>
                        {showTopLinks && (
                            <>
                                <div className='row mb-3'>
                                    <div className='col-12'>{processTopLinks(showTopLinks, topLinks)}</div>
                                </div>
                                <div className='border-bottom'></div>
                            </>
                        )}
                        {showSecondaryLinks && (
                            <div className='row my-3'>
                                <div className='col-12'>{processSecodaryLinks(showSecondaryLinks, secondaryLinks)}</div>
                            </div>
                        )}
                        <div className='row'>
                            <div className='col-12'>
                                <div className='logo'>
                                    <img src='/BIOT_logo_white_pms.png' alt='Biotheranostics, Inc.' />
                                </div>
                                <div className='address mt-5'>
                                    Biotheranostics, Inc.
                                    <br />
                                    6333 Sequence Drive
                                    <br />
                                    San Diego, CA 92121
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-12'>
                                <div className='copyright'>
                                    &#169; {new Date().getFullYear() } Hologic, Inc. Hologic, <br />
                                    Breast Cancer Index, Biotheranostics, and associated logos are trademarks and/or registered trademarks of Hologic, Inc. and/or its subsidiaries in the United States and/or other countries. All other trademarks are the property of their respective owners.
                                </div>
                                <div className='footer-nav mt-4'>
                                    <ul>
                                        <li>
                                            <a target='_blank' href='https://biotheranostics.com/privacy-practices.html'>
                                                Notice of Privacy Practices
                                            </a>
                                        </li>
                                        <li>
                                            <a target='_blank' href='/privacy-statement'>
                                                Privacy Statement
                                            </a>
                                        </li>
                                        <li>
                                            <a target='_blank' href='/terms-and-conditions'>
                                                Terms and Conditions
                                            </a>
                                        </li>
                                        <li>
                                            <a target='_blank' href='/statement-nondiscrimination'>
                                                Statement on Nondiscrimination
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-9 references'>
                        {processReferences(showReferences)}
                        <p>
                            <b>Breast Cancer Index Intended Uses and Limitations</b>
                            The Breast Cancer Index &#40;BCI&#41; Risk of Recurrence &amp; Extended Endocrine Benefit Test is indicated for use in women diagnosed with hormone
                            receptor-positive &#40;HR+&#41;, lymph node-negative &#40;LN-&#41; or lymph node-positive &#40;LN+; with 1-3 positive nodes&#41; early-stage, invasive
                            breast cancer, who are distant recurrence-free. The BCI test provides: 1&#41; a quantitative estimate of the risk for both late &#40;post-5 years from
                            diagnosis&#41; distant recurrence and of the cumulative distant recurrence risk over 10 years &#40;0-10y&#41; in patients treated with adjuvant
                            endocrine therapy &#40;LN- patients&#41; or adjuvant chemoendocrine therapy &#40;LN+ patients&#41;, and 2&#41; prediction of the likelihood of benefit
                            from extended &#40;&gt;5 year&#41; endocrine therapy. BCI results are adjunctive to the ordering physician’s workup; treatment decisions require
                            correlation with all other clinical findings. This test was developed and its performance characteristics determined by Biotheranostics, Inc. lt has not
                            been cleared or approved by the U.S. Food and Drug Administration. This test is used for clinical purposes, and should not be regarded as experimental
                            or investigational. How this information is used to guide patient care is the responsibility of the treating provider. Biotheranostics is certified
                            under the Clinical Laboratory Improvement Amendments of 1988 to perform high complexity clinical laboratory testing.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
