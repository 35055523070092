//Copyright Breast Cancer Index || 2021

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop,
    FloatingPetal
} from "../../lib";

import ReactGA from 'react-ga';
 
const Article8 = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc hcp-gradient round">
          <div className="clover-container">
            <div className="copy headline middle">
              <h1 className="mt-0 color-hologic-blue">
                Negative
                 <br />
                Side Effects
                 <br />
                May Lead To
                 <br />
                Non-Adherence<sup>1</sup>
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />

      <div className="container content-page">
        <div className="row w-90 mx-auto">
          <div className="col-12 py-5 text-center">
            <h3 className="pb-2">
              Not Another Minute Prescribing Treatment That May Not Benefit Her
            </h3>
            <h4 className="font1 clr c1">
              Endocrine Therapy Is Associated With Side Effects And More Serious
              Health Risks – Is Prolonged Treatment Going To Benefit Her?
            </h4>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto my-5">
          <div className="col-12 text-center">
            <p>
              It is important to determine whether the treatment is likely to
              reduce her risk of recurrence before exposing her to potential
              risks associated with extended treatment. In the ATLAS Trial
              <sup>2</sup> (extended TAM) 16 recurrences were prevented at the
              cost of:<sup>3,4</sup>
            </p>
          </div>
          <div className="col-12 py-3 text-center">
            <img
              src="/articles/article8-chart-main.svg"
              alt=""
              className="w-100"
            />
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto mt-5">
          <div className="col-12 text-center">
            <h4 className="font1 clr c1 mb-5">
              Bone Toxicity Remains A Concern For Extending Patients On
              Aromatase Inhibitors (AIs)<sup>5,6</sup>
            </h4>
          </div>
        </div>
        <div className="row w-90 max-500 mx-auto mb-5">
          <div className="col-12">
            <ul>
              <li>
                Significantly increased rates of bone pain, osteoporosis, and
                bone fractures despite a majority of patients being on
                bisphosphonates, vitamin D/calcium supplementation.<sup>5</sup>
              </li>
              <li>
                Twice as many bone fractures (n=133) as recurrences (n=67) in
                the letrozole group.<sup>5</sup>
              </li>
              <li>
                In an independent study, major fractures were associated with
                increased mortality in women with osteoporosis.<sup>6</sup>
              </li>
            </ul>
          </div>
          <div className="col-12 py-3 text-center">
            <img src="/articles/article8-chart1.svg" alt="" className="w-100" />
          </div>
        </div>
      </div>

      {/* <!--QUARTER ROUND --> */}
      <section className="qtr-round-article my-5">
        <div className="container content">
          <div className="row">
            <div className="col-10 offset-1 col-sm-11 pr-0 pr-sm-5">
              <h2 className="pr-0 pr-sm-5">
                Breast Cancer Index<sup>&reg;</sup> Predicts Whether She Is Likely
                To Benefit From Extended Endocrine Therapy To Help Determine
                Whether Continuing Treatment Is Right For Her.<sup>7,8</sup>
              </h2>
              <p className="text-center">
                <a
                  href="/predictive-power-of-bci"
                  className="btn btn-primary hcp mt-5"
                >
                  The Predictive Power Of Breast Cancer Index
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="round-bg bgc hcp-gradient horizontal"></div>
      </section>

      <div className="container content-page">
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <p className="ref">
              <strong>References:</strong>{' '}
			  <strong>1.&nbsp;</strong>Hershman et al. Breast Cancer Res Treat. 2011; 126(2): 529-537.{' '}
			  <strong>2.&nbsp;</strong>Davies C et al., Lancet. 2013;381(9869):805-16.{' '}
			  <strong>3.&nbsp;</strong>Nolvadex prescribing information. http://www.fda.gov/downloads/Drugs/DrugSafety/ucm088661.pdf.{' '}
			  <strong>4.&nbsp;</strong>Conzen, SD. Managing the side effects of tamoxifen. In: UpToDate, Dizon DS (Ed), UpToDate, Waltham, MA. (Accessed on March 11, 2015.).{' '}
			  <strong>5.&nbsp;</strong>Goss PE et al. N Engl J Med 2016 DOI:10.1056/NEJMoa1604700.{' '}
			  <strong>6.&nbsp;</strong>Bliuc D et al. JAMA 2009; 301(5):513-521.{' '}
			  <strong>7.&nbsp;</strong>Sgroi DC et al. J Natl Cancer Inst 2013;105:1036-1042.{' '}
			  <strong>8.&nbsp;</strong>Bartlett et al. Annals of Oncol 2019 doi: 10.1093/annonc/mdz289.
            </p>
            <a
              href="/resources-providers"
              className="btn btn-secondary hcp mt-5 w-250"
            >
              Back To All
            </a>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter
        site="hcp"
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard",
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: "Resources For Providers",
            text1: "Resources",
            text2: "For Providers",
          },
        ]}
        disclaimer=""
      />

      <Footer
        showTopLinks={true}
        topLinks={[
          { link: "/", label: "For Patients" },
          { link: "/know-someone-with-breast-cancer", label: "For Supporters" },
        ]}
        showSecondaryLinks={true}
        secondaryLinks={[
          { link: "/resources-providers", label: "Resources" },
          { link: "/contact-providers", label: "Explore The Report" },
          { link: "/contact-providers", label: "Contact" },
          { link: "/order-test", label: "Order The Test" },
        ]}
      />
    </div>
  );
}

export default Article8;
