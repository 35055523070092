//Copyright Breast Cancer Index || 2021
//Main Page

import React from "react";
import _ from "lodash";

import {
  Navbar,
  SubFooter,
  Footer,
  BackToTop,
  SecondaryNavbar,
  FloatingPetal,
  SEO,
} from "../../lib";

import Publications from "./Publications";
import NewsItems from "./NewsItems";

import publications from "../../util/resources-providers-publications.json";

import "./ResourcesProviders.scss";

import ReactGA from "react-ga";

class ResourcesProviders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: [],
    };
  }

  handleFilter = (filter) => {
    // explicit request to empty filter
    if (filter === null) {
      this.setState({
        filter: [],
      });
    } else {
      filter = filter.toLowerCase();

      // look to see if our current filter has the element
      let f = this.state.filter;
      let k = f.indexOf(filter);

      // if not, let's add it
      if (k === -1) {
        this.setState({
          filter: [...f, filter],
        });
      } else {
        // if so, let's remove it
        f.splice(k, 1);
        this.setState({
          filter: f,
        });
      }
    }
  };

  filterNewsArticles = (data) => {
    const findMatches = (category) => {
      let matches = [];
      _.each(data, (value) => {
        let found = _.find(value, (item) => _.includes(item, category));
        if (found) {
          matches.push(value);
        }
      });
      return matches;
    };

    const categories = ["NEWS & INSIGHTS"];

    _.each(categories, (category) => {
      console.log("matches for " + category, findMatches(category));
    });
  };

  render() {
    // console.log("[FILTER]", this.state.filter);
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div>
        <SEO
          title="Provider Resources"
          description="If you are a doctor or oncologist treating breast cancer patients, use our Breast Cancer Index resources to learn about the latest in treatment resources."
        />
        <Navbar type="providers" />

        <header className="page-header float-hand">
          <div className="container-fluid w-100 bgc hcp-gradient not-round">
            <div className="bg-pattern"></div>
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-7 col-lg-6 py-5">
                  <h2 className="mb-4">
                    Know when extended endocrine therapy is beneficial.
                  </h2>
                  <h2> Avoid it when it isn't.</h2>
                  <div className="right-hand-mobile d-block d-md-none mb-5">
                    <a href="/nccn-inclusion" className="absolute"></a>
                    <img
                      src="/guideline-inclusion.png"
                      alt="hand holding form"
                      className="w-100"
                    />
                  </div>

                  <p className="mt-5">
				  	The NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup>®</sup>) and the ASCO<sup>®</sup> Clinical Practice Guideline now recognize Breast Cancer Index<sup>&reg;</sup> (BCI) as the only genomic test that can predict
					the benefit of extended endocrine therapy in early-stage, HR+ breast cancer.<sup>1-2</sup> That means
					avoiding potential overtreatment of patients for whom endocrine therapy beyond 5 years is unlikely
					to provide benefit.<sup>3-7</sup> And informing treatment for the patients it may help.<sup>3-7</sup>
                  </p>
                  <p className="text-center text-md-left mt-5">
                    <a
                      href="/nccn-inclusion"
                      target="_blank"
                      className="btn btn-primary hcp btn-animated mb-4"
                    >
                      Read About BCI’s Inclusion in Guidelines
                    </a>
                  </p>
                </div>
                <div className="col-md-5 col-lg-6 col-xl-5 d-none d-md-flex align-items-end flex-column relative">
                  <a href="/nccn-inclusion" className="absolute"></a>
                  <img
                    src="/guideline-inclusion.png"
                    alt="hand holding form"
                    className="my-auto d-md-block d-lg-none right-hand-tablet"
                  />
                  <img
                    src="/guideline-inclusion.png"
                    alt="hand holding form"
                    className="mt-auto d-md-none d-lg-block large-hero"
                  />
                </div>
              </div>
            </div>
          </div>
        </header>

        <SecondaryNavbar
          onFiltered={(filter) => this.handleFilter(filter)}
          filter={this.state.filter}
        />

        <FloatingPetal type="provider" />

        {/*<!-- CONTENT AREA -->*/}
        <div className="container articles">
          <div className="row">
            {/*<!-- LEFT COLUMN -->*/}
            <div className="col-12 col-sm-7 col-md-8 left-col">
              <Publications
                publications={publications}
                includes={[1, 2, 3, 4, 5, 202206, 6, 7, 8, 9, 10, 15]}
                filter={this.state.filter}
              />
              {/* {console.log(filterNewsArticles(publications))} */}
            </div>
            <div className="col-12 col-sm-5 col-md-4 right-col">
              <h4 className="mb-2">News &amp; Insights</h4>
              <div className="border-bottom mb-4"></div>
              <NewsItems
                publications={publications}
                includes={[23, 11, 12, 13, 14]}
              />
            </div>
          </div>
        </div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className=" col-12 text-center">
            <p className="ref">
				<strong>References:</strong>{' '}
				<strong>1.&nbsp;</strong>Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup>®</sup>) for Breast Cancer V.2.2023. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed February 10, 2023. To view the most recent and complete version of the guideline, go online to <a href="https://www.nccn.org/" target='_blank' rel='noopener noreferrer'>NCCN.org</a>. The NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.{' '}
				<strong>2.&nbsp;</strong>Andre F et al. J Clin Oncol. Published online April 19, 2022. DOI: 10.1200/JCO.22.00069​. Referenced with permission from the American Society of Clinical Oncology (ASCO®) Clinical Practice Guideline Biomarkers for Adjuvant Endocrine and Chemotherapy in Early-Stage Breast Cancer. © American Society of Clinical Oncology. 2024. All rights reserved. To view the most recent and complete version of the guideline, go online to https://ascopubs.org/jco/special/guidelines [ascopubs.org].  ASCO makes no warranties of any kind whatsoever regarding their content, use of application and disclaims any responsibility for their application or use in any way.{' '}
				<strong>3.&nbsp;</strong>Davies C, et al. Lancet. 2013;381:805-816.{' '}
				<strong>4.&nbsp;</strong>Gray R, et al. J Clin Oncol. 2013;31:(suppl; abstr 5).{' '}
				<strong>5.&nbsp;</strong>Jakesz R, et al. J Natl Cancer Inst. 2007;99:1845-1853.{' '}
				<strong>6.&nbsp;</strong>Goss PE, et al. J Natl Cancer Inst. 2005;97:1262-1271.{' '}
				<strong>7.&nbsp;</strong>Mamounas EP, et al. NSABP B-42. GS4-01. SABCS 2019.{' '}
				<strong>8.&nbsp;</strong>Goss PE, et al. N Engl J Med. 2016;375:209-219.{' '}
            </p>
          </div>
        </div>
        <BackToTop />

        <SubFooter
          site="hcp"
          image=""
          icons={[
            {
              href: "/order-test",
              img: "/icon-clipboard.svg",
              alt: " Order Breast Cancer Index",
              text1: "Order Breast",
              text2: "Cancer Index",
              className: "clipboard",
            },
            {
              href: "/resources-providers",
              img: "/icon-gear.svg",
              alt: " Resources for Providers",
              text1: "Resources",
              text2: "for Providers",
            },
          ]}
        />
        <Footer
          showTopLinks={true}
          topLinks={[
            { link: "/", label: "For Patients" },
            { link: "/", label: "For Supporters" },
          ]}
          showSecondaryLinks={true}
          secondaryLinks={[
            { link: "/resources-providers", label: "Resources" },
            {
              link: "/explore-the-report-providers",
              label: "Explore The Report",
            },
            { link: "/contact-providers", label: "Contact" },
            { link: "/order-test", label: "Order The Test" },
          ]}
        />
      </div>
    );
  }
}

export default ResourcesProviders;
