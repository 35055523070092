//Copyright Breast Cancer Index || 2021
//Accordion function for the menus

import React from 'react';
import { Accordion as BootstrapAccordion } from 'react-bootstrap';

class Accordion extends React.Component {

  constructor (props) {
    
    super(props);
  
    this.state = { uncollapsed: '' };

  }

  handleUncollapse = (eventKey) => {
    this.setState({ uncollapsed: eventKey });
  }

  render () {

    let childrenWithProps = React.Children.map(this.props.children, child => {
      const isCollapsed = child.props.eventKey === this.state.uncollapsed ? false : true;
      return (
        React.cloneElement(child, { handleUncollapse: this.handleUncollapse, collapsed: isCollapsed })
      );
    });

    return (
      <BootstrapAccordion className="accordion mt-5">
        { childrenWithProps }
      </BootstrapAccordion>
    );
  }

}

export default Accordion;