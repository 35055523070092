import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Navbar, SubFooter, Footer, BackToTop, FloatingPetal, DownloadModal, SEO } from "../../lib";
import ReactGA from 'react-ga';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton } from 'react-share';

const WhatToExpect = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [mTitle, setTitleText] = useState("");
  const [mBody, setBodyText] = useState("");

  const handleClose = () => setShow(false);

  const handleShow = (type) => {
    if(type === "patient"){
      setTitleText("Download the Conversation Guide");
      setBodyText("");
    }else if(type === "provider"){
      setTitleText("Order Breast Cancer Index");
      setBodyText("Download the Conversation Guide");
    }
    setShow(true);
  }

  ReactGA.pageview(window.location.pathname + window.location.search);
  
  return (
    <div>
      <SEO title='What to Expect - Breast Cancer Index Testing' description='BCI can help you and your doctors determine the right length for your anti-estrogen therapy. Learn what to expect from Breast Cancer Index testing here.' hideSuffix={true}/>
      <Navbar type="patients" />

      {/* <!-- HEADER - DOCTOR ORDERED INDEX --> */}
      <header className="page-header  patient-side">
        <div className="container-fluid not-round">
          <div className="clover-container">
            <div className="copy">
              <h1 className="quote">
                If I Am Likely To Benefit, Maybe I’ll Soldier Through Treatment For Another Five Years.
              </h1>
            </div>
            <div className="photo large">
              <img src="/img-hero3-header.png" alt="" className="w-100" />
            </div>
            <div className="photo-clover">
              <img src="/clover-hologic-blue.svg" alt="" className="w-100" />
            </div>
          </div>
        </div>
      </header>

      <FloatingPetal type="patient"/>
      <DownloadModal show={show} title={mTitle} body={mBody} handleClose={handleClose} />

      {/* <!-- SECONDARY --> */}
      <section className="d-flex">
        <div className="secondary-header bgc g1">
          <div className="copy">
            <p>
              If you and your oncology team are considering Breast Cancer Index<sup>&reg;</sup>, you’re taking an important step in reducing uncertainty around treating your breast cancer.
            </p>
            <p>
              Breast Cancer Index may improve clarity for you and your doctor as you consider the duration of your anti-estrogen therapy – in fact, it’s the only validated, commercially available test that can tell you whether you’re likely to benefit from treatment beyond 5 years.
            </p>
            <h6>Not Another Minute Living My Life On Hold</h6>
            </div>
          <div className="bg-pattern"></div>
        </div>
      </section>    

      {/* <!-- TITLE --> */}
      <section>
        <div className="text-center p-5">
          <h1 className="page-title m-4">
            You can’t take the fear out of cancer.  <br />
            <span>
              But you can reduce the  <br />uncertainty around treating it.
            </span>
          </h1>
        </div>
      </section>

      {/* <!--HALF ROUND --> */}
      <section className="half-round mb-5">
        <div className="image">
          <img src="/img-doc-left-hand-cropped.png" alt="hand holding form" />
        </div>
        <div className="content p-5">
          <div className="copy">
            <h2 className="mb-4 mt-4">
              Breast Cancer Index Is Changing  <br />The Game.
            </h2>
            <p>
              Breast Cancer Index is a test that provides individualized information to help you and your doctor make a decision about extending anti-estrogen therapy beyond  5 years for early-stage hormone receptor–positive (HR+) breast cancer. The test provides two pieces of valuable information to help make a personalized decision:
            </p>
            <ol>
              <li>Whether or not an additional five years of anti-estrogen therapy is likely to help reduce your risk of the cancer returning</li>
              <li>Your individual risk of recurrence after 5 years based on your original tumor biology</li>
            </ol>
            <hr/>
            <h5 className="font1">
              Is Breast Cancer Index Right for Me?
            </h5>
            <p>
              Breast Cancer Index may be right for you if you are looking for more information to help you and your doctor determine your treatment plan beyond year 5, and if you: 
            </p>
            <ul className="t2">
              <li>Were diagnosed with early-stage, HR+ breast cancer.</li>
              <li>Are pre- or post-menopausal and are lymph node–negative (LN-) or lymph node–positive (LN+) with one to three positive nodes.</li>
              <li>Are currently disease-free and want to know whether an additional 5 years of anti-estrogen treatment will help you reduce your risk of your cancer returning.</li>
            </ul>
            <div className="icon mt-3">
              <img src="/icon-bubble.svg" alt="" className="w-100" />
            </div>
            <p className="text-center text-md-left">
              <a className="btn btn-primary with-border mb-2" onClick={() => handleShow("patient")}>Download The Conversation Guide</a>
            </p>
            <hr/>
            <h5 className="font1">
              Ready to get tested?
            </h5>
            <p>
              We'll give you the resources to start the BCI conversation with your doctor. Once your healthcare provider had ordered the test, you don't need to do a thing. Tissue from your original biopsy is sent to our lab. Your results will be sent to your doctor, who will review the test report with you.
            </p>
			<p className="text-center text-md-left">
              <a href="/getting-bci" className="btn btn-primary with-border mb-2">How To Get The Test</a>
            </p>
            <p className="text-center text-md-left">
              <div className="share-container">
                <div className="btn btn-primary with-border mb-2 share-btn"><img src={('/icon-share.svg')} alt=''/>Share</div>
                <div className="share-tray btn-primary with-border btn">
					<LinkedinShareButton children={<img src={'/LinkedIn icons.svg'} width='20px' height='20px' alt='LinkedIn icon' />} url={`https://breastcancerindex.com${location.pathname}`} />
					<TwitterShareButton children={<img src={'/Twitter icons.svg'} width='20px' height='20px' alt='Twitter icon' />} url={`https://breastcancerindex.com${location.pathname}`} />
					<FacebookShareButton children={<img src={'/FB icons.svg'} width='20px' height='20px' alt='Facebook icon' />} url={`https://breastcancerindex.com${location.pathname}`} />
					<EmailShareButton subject="Breast Cancer Index" body="Check out" url='https://breastcancerindex.com' children={<img src={'/Mail icons.svg'} width='20px' height='20px' alt='Email icon' />} />
                </div>
              </div>
            </p>
          </div>
        </div>
        <div className="round-bg"></div>
      </section>

      <BackToTop />

      <SubFooter 
        image="/img-hero2.png"
        icons={[
          {
            href: "/contact-patients",
            img: "/icon-person.svg",
            alt: " Contact & Support",
            text1: "Contact",
            text2: "& Support"
          },{
            modal: true,
            img: "/icon-bubble.svg",
            alt: " Explore the Test Report",
            text1: "Download The",
            text2: "Conversation Guide"
          }
        ]} 
        type='hasHero'/>
      <Footer 
        showReferences={[true, false]}
        showTopLinks={true} topLinks={[
          { link: "/resources-providers", label: "For Providers" },
          { link: "/know-someone-with-breast-cancer", label: "For Supporters" }
        ]}
        showSecondaryLinks={true} secondaryLinks={[
          { link: "/FAQs", label: "FAQs" },
          { link: "/contact-patients", label: "Contact & Support" }
        ]} 
        type='patient'
      />
    </div>
  );
}

export default WhatToExpect;
