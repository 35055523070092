//Copyright Breast Cancer Index || 2021
//Flowting petal for the download button

import React, { useState } from "react";

import { Modal } from 'react-bootstrap';

const DownloadModal = (props) => {

  const mTitle = props.title;
  const mBody = props.body;
  const type = props && props.type;

  const handleClose = () => {
      props.handleClose();
  }
  
  const ActivateModal = () => {
    if(type === "ty"){
      return (
        <Modal className="purple-modal" show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Thanks for submitting! More information is on the way.</Modal.Title>
            </Modal.Header>
            <Modal.Body>Your submission is the first step in learning more and getting additional information. 
                        Over the next couple of days, you will be receiving tools to help guide you through the
                        process. In the meantime, please click on the downloads below to help you get started.
            <div className="row mt-4"></div>
            <hr/>
            <a target="_blank" href="/PatientEducation.pdf"><p className="text-center">WHAT IS BREAST CANCER INDEX AND HOW DO I KNOW IF IT’S RIGHT FOR ME?</p></a> <br />
            <a target="_blank" href=""><p className="text-center">HOW TO ORDER THE TEST AND THE APPROVAL PROCCESS?</p></a> <br />
            <a target="_blank" href=""><p className="text-center">QUESTIONS TO CONSIDER</p></a>

            <div className="row mt-4">
              <a target="_blank" href="/PatientEducation.pdf" className="btn btn-popmodal-alt w-100 my-2">Download PDF</a>
            </div>

            </Modal.Body>
        </Modal>
      );
    }else {
        return (
        <Modal className="covid-modal" show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>{mTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{mBody}
            <div className="row mt-4">
                <div className="col-12 col-md-3">
                <a target="_blank" href="/bci-patient-education-digital-brochure-eng.pdf" className="btn btn-primary w-100 my-2">English</a>
                </div>
                <div className="col-12 col-md-3">
                <a target="_blank" href="/bci-patient-education-digital-brochure-chn.pdf" className="btn btn-primary w-100 my-2">Chinese</a>
                </div>
                <div className="col-12 col-md-3">
                <a target="_blank" href="/bci-patient-education-digital-brochure-esp.pdf" className="btn btn-primary w-100 my-2">Spanish</a>
                </div>
                <div className="col-12 col-md-3">
                <a target="_blank" href="/bci-patient-education-digital-brochure-vnm.pdf" className="btn btn-primary w-100 my-2">Vietnamese</a>
                </div>
            </div>
            </Modal.Body>
        </Modal>
        );
    }

  }
  
  return (
    <div>
      { ActivateModal()}
    </div>
  );
}
  
export default DownloadModal;
