//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import { Navbar, SubFooter, Footer, BackToTop, FloatingPetal, SEO } from "../../lib";

import ReactGA from 'react-ga';

function PressReleasePage0603() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='New Data on Power to Predict Endocrine Therapy' description='Breast Cancer IndexTM has new data on its power to predict extended endocrine therapy benefits being presented at ASCO 2019. Read the press release here.' />
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc g3 round">
          <div className="clover-container">
            <div className="copy headline">
              <h1 className="mt-0 color-hologic-blue">
                The Power <br />To Predict
              </h1>
            </div>
            <div className="photo-clover">                          
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />
      

      <div className="container content-page">
      <div className="row w-90 mx-auto my-5">
          <div className="col-12 pt-5 pb-2 text-center">
            <h3 className="pb-2">
            Breast Cancer Index<sup>&reg;</sup> and new data on its power to predict extended endocrine therapy benefit presented at ASCO 2019
            </h3>
            <h4 className="font1 clr c1">
              The power of prediction informing 5 vs. 10 years of endocrine therapy for early-stage, hormone receptor-positive breast cancer
            </h4>
            <h6 className="font1 clr c10 mt-5">
              SAN DIEGO, Calif., June 03, 2019 (GLOBE NEWSWIRE)
            </h6>
          </div>
        </div>       
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">   
          <div className="col-12">
            <p>Biotheranostics, Inc. announces new pivotal data on the Breast Cancer Index<sup>&reg;</sup> (BCI) being presented at the ASCO conference June 3, 2019 that further validates its ability to accurately predict which women with early-stage, hormone receptor positive (HR+) breast cancer will benefit from extended endocrine therapy. Findings from the Translational-aTTom study (Trans-aTTom) led by an international team of cancer researchers strengthen BCI clinical evidence as a predictive biomarker and highlight its utility in helping physicians determine 5 vs. 10-year endocrine treatment plans for their patients.</p>
            <p>The Trans-aTTom study to date includes 1,822 patients treated in the aTTom trial, which randomized nearly 7,000 HR+ women to either stop or continue tamoxifen for 5 more years after completing at least 4 years of endocrine therapy. Data presented on 583 patients with N+ disease demonstrated that the Breast Cancer Index effectively stratified patients based on their response to extended endocrine therapy, identifying 49% of patients that showed a statistically significant benefit of 10.2% with 10 years of tamoxifen, and 51% of patients that did not show a statistically significant benefit from continued treatment.  These data provide further validation and establish level 1B evidence for Breast Cancer Index as the only predictive biomarker for benefit from extended endocrine therapy in early-stage, HR+ breast cancer.</p>
            <p>A significant dilemma in early-stage, HR+ breast cancer is determining the optimal duration of endocrine therapy to reduce the ongoing risk of distant recurrence. For breast cancer patients on endocrine therapy, the side effects and toxicities associated with treatment often have a severe impact on health and quality of life (e.g., osteoporosis, endometrial cancer, blood clots, joint pain) For these women, weighing the health risks against the potential benefit of continuing endocrine therapy beyond 5 years is essential for individual treatment planning. Thus, identifying women for whom extending endocrine therapy helps reduce their risk of recurrence is important to help decrease their anxiety, increase their satisfaction and comfort with their treatment decision, and potentially enable them to comply effectively with their treatment plan. Breast Cancer Index is the only test validated to predict which patients will benefit from endocrine therapy beyond 5 years, helping inform the decision of 5 vs 10 years of endocrine therapy. No other genomic tests or clinicopathologic factors have demonstrated the ability to predict extended endocrine therapy benefit.</p>
            <p>Catherine Schnabel, Ph.D., Chief Scientific Officer, Biotheranostics, said, “Results from the Trans-aTTom study add to the growing body of evidence that BCI accurately identifies which early-stage HR+ patients are associated with better outcomes and preferential response to extended endocrine therapy. Clinicians may use BCI to personalize their approach and provide validated genomic information to help patients weigh the risks and benefits of prolonging their endocrine treatment.”</p>
            <p>Don Hardison, Biotheranostics’ President, and CEO added, “We are very pleased with results from the Trans-aTTom validation study. It provides further validation that Breast Cancer Index is the only predictive biomarker for extended endocrine therapy in early-stage, HR+ breast cancer. We are committed to ensuring that every breast cancer patient and her physician have information to help determine the best approach to individualize treatment.”</p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">
          <div className="col-12">
            <h4>About Breast Cancer Index<sup className="sup-sm">TM</sup></h4>
            <p>Breast Cancer Index is a molecular, gene expression-based test uniquely positioned to provide information to help physicians individualize treatment decisions for patients with early stage, ER+ breast cancer. This breakthrough test helps oncologists and patients navigate the difficult trade-off between taking steps to prevent recurrence of their disease and facing significant side effects and safety challenges related to unnecessary treatment. Breast Cancer Index holds guidelines designation from the American Joint Committee on Cancer for cancer staging based on molecular profile; ASCO, NCCN, European Group on Tumor Markers (EGTM), and St. Gallen to inform the chemotherapy decision; and ASCO and EGTM to inform the extended endocrine treatment dilemma. It is the only validated, commercially available test that provides risk of overall and late distant recurrence and predicts the likelihood of benefit from extended endocrine therapy. For more information, visit <a href="https://www.breastcancerindex.com" target="_blank">www.breastcancerindex.com</a>.</p>
            <h4>About Biotheranostics</h4>
            <p>
              Biotheranostics, Inc. operates a CLIA-certified and CAP-accredited diagnostic laboratory in
              San Diego, California. Biotheranostics, Inc., is a leading healthcare provider in the
              oncology field, assisting physicians in the treatment of cancer patients. Its suite of
              proprietary molecular diagnostic tests provides important information to physicians to
              tailor treatment to individual patients. The company’s <a href="https://breastcancerindex.com" target="_blank">Breast Cancer Index</a><sup>&reg;</sup> and <a href="https://www.cancertypeid.com/" target="_blank">CancerTYPE ID</a><sup>&reg;</sup> tests address a variety of unmet medical
              needs in the management of cancer patients, and extensive clinical studies have confirmed
              the accuracy, clinical validity, clinical utility, and cost-effectiveness of the tests.
              Learn more at <a href="http://www.biotheranostics.com" target="_blank">www.biotheranostics.com</a>.</p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <a href="/resources-providers" className="btn btn-secondary hcp w-250">Back To All</a>
          </div>
        </div>
      </div>
      
      <BackToTop />

      <SubFooter 
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard"
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: " Resources for Providers",
            text1: "Resources",
            text2: "for Providers"
          }
        ]} />
      
      <Footer 
            showTopLinks={true} topLinks={[
              {link: "/", label: "For Patients"},
              {link: "/know-someone-with-breast-cancer", label: "For Supporters"}
            ]}
            showSecondaryLinks={true} secondaryLinks={[
              {link: "/providers-resources", label: "Resources"},
              {link: "/contact-providers", label: "Explore The Report"},
              {link: "/contact-providers", label: "Contact"},
              {link: "/order-test", label: "Order The Test"}
            ]} />

    </div>   
  );

}

export default PressReleasePage0603;
