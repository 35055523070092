import React from 'react';

import { Navbar, SubFooter, Footer, BackToTop, FloatingPetal, SEO } from "../../lib";

import ReactGA from 'react-ga';

function PressReleasePage120820() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='IDEAL Study Expands Clinical Evidence' description='Biotheranostics’ Breast Cancer IndexTM IDEAL study expands clinical evidence for prediction of extended endocrine benefit to patients treated with aromatase inhibitors' />
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc g3 round">
          <div className="clover-container">
            <div className="copy headline">
              <h1 className="mt-0 color-hologic-blue">
                New Data at SABCS 2020
              </h1>
            </div>
            <div className="photo-clover">                          
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />
      

      <div className="container content-page">
      <div className="row w-90 mx-auto my-5">
          <div className="col-12 pt-5 pb-2 text-center">
            <h3 className="pb-2">
              Biotheranostics to premiere new data on Breast Cancer Index<sup>&reg;</sup> and prediction of
              endocrine therapy benefit at SABCS 2020
            </h3>
            <h6 className="font1 clr c10 mt-5">
              SAN DIEGO, Calif., December 08, 2020.
            </h6>
          </div>
        </div>       
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">   
          <div className="col-12">
            <p>
              SAN DIEGO, Calif., December 8, 2020. New data on Biotheranostics’ Breast Cancer Index<sup>&reg;</sup> test
              will be highlighted at the 43rd annual San Antonio Breast Cancer Symposium (SABCS) being held
              virtually from December 8-12, 2020. Breast Cancer Index<sup>&reg;</sup> continues to expand on a strong body of
              evidence as the only commercially available genomic assay to help inform the decision of extended
              endocrine therapy based on prediction of benefit for HR+ early stage breast cancer patients.
            </p>
            <p>
              Results from three independent studies will be presented during this week’s meeting, including data
              that will be featured in a Spotlight Session Wednesday, December 9<sup>th</sup> , as well as the General
              Session Friday morning, December 11<sup>th</sup>. Presentations include new results from the IDEAL study,
              which validated BCI as the first commercially available test with the ability to predict benefit from
              extended AI therapy in post-menopausal patients who have already been treated with 5 years of AI
              therapy. In addition, a correlative biomarker study in the Trans-aTTom cohort will also be
              presented. The collective evidence supports routine clinical use of BCI for HR+ early stage breast
              cancer as a predictive biomarker of benefit and outcome from extended endocrine therapy that is
              agnostic to tamoxifen and/or AI treatment.
            </p>
            <p>
              <b>General Session GS4-09; Fri 12/11 10:45am CST</b> <br />
              Correlative studies of the Breast Cancer Index (HOXB13/IL17BR) and ER, PR, AR, AR/ER ratio
              and Ki67 for prediction of extended endocrine benefit: a Trans-aTTom Study. Sgroi <i>et al</i>
            </p>
            <p>
              <b>Spotlight Session PD2-11; Wed. 12/9 5:15-6:30pm CST</b>  <br />
              Breast Cancer Index is a molecular signature of endocrine responsiveness that determines extended
              endocrine benefit independent of prognostic risk. Liefers <i>et al</i>
            </p>
            <p>
              <b>Poster Session PS17-32; Wed. 12/9 8:00am CST</b>  <br />
              Characterization of HOXB13-induced estrogen receptor reprogramming in breast cancer cells.
              Treuner <i>et al</i>
            </p>
            <p>
              Catherine Schnabel, Biotheranostics’ Chief Scientific Officer states, “As SABCS is the leading
              breast oncology meeting that presents practice-changing advancements in the field, it is an
              important milestone to have data from both the Trans-aTTom and IDEAL studies, which strengthen
              the clinical utility and distinct ability of BCI to predict which patients will benefit from extended
              endocrine therapy, featured this year.”
            </p>            
            <p>
              Don Hardison, Biotheranostics’ President and CEO adds, “We continue to add to the strength of our
              evidence with the singular goal of making Breast Cancer Index standard of care for all HR+ early
              stage breast cancer patients. We believe every woman should be offered a test that helps guide the
              joint decision related to extended endocrine therapy.”
            </p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">
          <div className="col-12">
            <h4>About Breast Cancer Index<sup className="sup-sm">TM</sup></h4>
            <p>Breast Cancer Index is a molecular, gene expression-based test uniquely positioned to provide information to help physicians individualize treatment decisions for patients with early stage, ER+ breast cancer. This breakthrough test helps oncologists and patients navigate the difficult trade-off between taking steps to prevent recurrence of their disease and facing significant side effects and safety challenges related to unnecessary treatment. Breast Cancer Index holds guidelines designation from the American Joint Committee on Cancer for cancer staging based on molecular profile; ASCO, NCCN, European Group on Tumor Markers (EGTM), and St. Gallen to inform the chemotherapy decision; and ASCO and EGTM to inform the extended endocrine treatment dilemma. It is the only validated, commercially available test that provides risk of overall and late distant recurrence and predicts the likelihood of benefit from extended endocrine therapy. For more information, visit <a href="https://www.breastcancerindex.com" target="_blank">www.breastcancerindex.com</a>.</p>
            <h4>About Biotheranostics</h4>
            <p>
              Biotheranostics, Inc. operates a CLIA-certified and CAP-accredited diagnostic laboratory in 
              San Diego, California. Biotheranostics, Inc., is a leading healthcare provider in the 
              oncology field, assisting physicians in the treatment of cancer patients. Its suite of 
              proprietary molecular diagnostic tests provides important information to physicians to 
              tailor treatment to individual patients. The company’s <a href="https://breastcancerindex.com" target="_blank">Breast Cancer Index</a><sup>&reg;</sup>; and <a href="https://www.cancertypeid.com/" target="_blank">CancerTYPE ID</a><sup>&reg;</sup> tests address a variety of unmet medical 
              needs in the management of cancer patients, and extensive clinical studies have confirmed 
              the accuracy, clinical validity, clinical utility, and cost-effectiveness of the tests. 
              Learn more at <a href="http://www.biotheranostics.com" target="_blank">www.biotheranostics.com</a>.</p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <a href="/resources-providers" className="btn btn-secondary hcp w-250">Back To All</a>
          </div>
        </div>
      </div>
      
      <BackToTop />

      <SubFooter 
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard"
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: " Resources for Providers",
            text1: "Resources",
            text2: "for Providers"
          }
        ]} />
      
      <Footer 
            showTopLinks={true} topLinks={[
              {link: "/", label: "For Patients"},
              {link: "/know-someone-with-breast-cancer", label: "For Supporters"}
            ]}
            showSecondaryLinks={true} secondaryLinks={[
              {link: "/providers-resources", label: "Resources"},
              {link: "/contact-providers", label: "Explore The Report"},
              {link: "/contact-providers", label: "Contact"},
              {link: "/order-test", label: "Order The Test"}
            ]} />

    </div>   
  );

}

export default PressReleasePage120820;
