//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import { Navbar, SubFooter, Footer, BackToTop } from "../../lib";

import { Col, Row } from "react-bootstrap";

import ReactGA from 'react-ga';

const Stories = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <Navbar type="patients" />
      <div className="container">
        <Row>
          <Col>
            <svg className="bd-placeholder-img rounded float-left" width="100%" height="300" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 200x200">
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#868e96"></rect>
              <text x="50%" y="50%" fill="#dee2e6" dy=".3em">Breast cancer is not a 5-year disease.</text>
            </svg>
          </Col>
        </Row>
        <Row className="mb-5 position-relative">
          <div className="float-button">
            <a href="/PatientEducation.pdf" className="btn btn-primary">Download the Conversation Guide</a>
          </div>
          <Col sm={6} md={3} className="mt-5">
            <p>Hear what patients say about Breast Cancer Index</p>
            <svg className="bd-placeholder-img rounded float-left" width="100%" height="150" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 200x200">
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#868e96"></rect>
              <text x="50%" y="50%" fill="#dee2e6" dy=".3em"></text>
            </svg>
            <p>PATIENT STORIES</p>
            <p>Ari te res qui conet vel et labor sit quidero cones dunt, cupturernam, ne int. Optatur, cor sus. Abo. Ximporib ernate cullition pratur aspis autem etus aut que nos dolupti berrovit</p>
            <p>OCT 30 / Dr Whitehurst</p>
            <a href="/individual-story" className="btn btn-primary">Keep Reading</a>
          </Col>
          <Col sm={6} md={3} className="mt-5">
            <p>Hear what patients say about Breast Cancer Index</p>
            <svg className="bd-placeholder-img rounded float-left" width="100%" height="150" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 200x200">
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#868e96"></rect>
              <text x="50%" y="50%" fill="#dee2e6" dy=".3em"></text>
            </svg>
            <p>PATIENT STORIES</p>
            <p>Ari te res qui conet vel et labor sit quidero cones dunt, cupturernam, ne int. Optatur, cor sus. Abo. Ximporib ernate cullition pratur aspis autem etus aut que nos dolupti berrovit</p>
            <p>OCT 30 / Dr Whitehurst</p>
            <a href="/individual-story" className="btn btn-primary">Keep Reading</a>
          </Col>
          <Col sm={6} md={3} className="mt-5">
            <p>Hear what patients say about Breast Cancer Index</p>
            <svg className="bd-placeholder-img rounded float-left" width="100%" height="150" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 200x200">
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#868e96"></rect>
              <text x="50%" y="50%" fill="#dee2e6" dy=".3em"></text>
            </svg>
            <p>PATIENT STORIES</p>
            <p>Ari te res qui conet vel et labor sit quidero cones dunt, cupturernam, ne int. Optatur, cor sus. Abo. Ximporib ernate cullition pratur aspis autem etus aut que nos dolupti berrovit</p>
            <p>OCT 30 / Dr Whitehurst</p>
            <a href="/individual-story" className="btn btn-primary">Keep Reading</a>
          </Col>
          <Col sm={6} md={3} className="mt-5">
            <p>Hear what patients say about Breast Cancer Index</p>
            <svg className="bd-placeholder-img rounded float-left" width="100%" height="150" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 200x200">
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#868e96"></rect>
              <text x="50%" y="50%" fill="#dee2e6" dy=".3em"></text>
            </svg>
            <p>PATIENT STORIES</p>
            <p>Ari te res qui conet vel et labor sit quidero cones dunt, cupturernam, ne int. Optatur, cor sus. Abo. Ximporib ernate cullition pratur aspis autem etus aut que nos dolupti berrovit</p>
            <p>OCT 30 / Dr Whitehurst</p>
            <a href="/individual-story" className="btn btn-primary">Keep Reading</a>
          </Col>
          <Col sm={6} md={3} className="mt-5">
            <p>Hear what patients say about Breast Cancer Index</p>
            <svg className="bd-placeholder-img rounded float-left" width="100%" height="150" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 200x200">
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#868e96"></rect>
              <text x="50%" y="50%" fill="#dee2e6" dy=".3em"></text>
            </svg>
            <p>PATIENT STORIES</p>
            <p>Ari te res qui conet vel et labor sit quidero cones dunt, cupturernam, ne int. Optatur, cor sus. Abo. Ximporib ernate cullition pratur aspis autem etus aut que nos dolupti berrovit</p>
            <p>OCT 30 / Dr Whitehurst</p>
            <a href="/individual-story" className="btn btn-primary">Keep Reading</a>
          </Col>
          <Col sm={6} md={3} className="mt-5">
            <p>Hear what patients say about Breast Cancer Index</p>
            <svg className="bd-placeholder-img rounded float-left" width="100%" height="150" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 200x200">
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#868e96"></rect>
              <text x="50%" y="50%" fill="#dee2e6" dy=".3em"></text>
            </svg>
            <p>PATIENT STORIES</p>
            <p>Ari te res qui conet vel et labor sit quidero cones dunt, cupturernam, ne int. Optatur, cor sus. Abo. Ximporib ernate cullition pratur aspis autem etus aut que nos dolupti berrovit</p>
            <p>OCT 30 / Dr Whitehurst</p>
            <a href="/individual-story" className="btn btn-primary">Keep Reading</a>
          </Col>
          <Col sm={6} md={3} className="mt-5">
            <p>Hear what patients say about Breast Cancer Index</p>
            <svg className="bd-placeholder-img rounded float-left" width="100%" height="150" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 200x200">
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#868e96"></rect>
              <text x="50%" y="50%" fill="#dee2e6" dy=".3em"></text>
            </svg>
            <p>PATIENT STORIES</p>
            <p>Ari te res qui conet vel et labor sit quidero cones dunt, cupturernam, ne int. Optatur, cor sus. Abo. Ximporib ernate cullition pratur aspis autem etus aut que nos dolupti berrovit</p>
            <p>OCT 30 / Dr Whitehurst</p>
            <a href="/individual-story" className="btn btn-primary">Keep Reading</a>
          </Col>
          <Col sm={6} md={3} className="mt-5">
            <p>Hear what patients say about Breast Cancer Index</p>
            <svg className="bd-placeholder-img rounded float-left" width="100%" height="150" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 200x200">
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#868e96"></rect>
              <text x="50%" y="50%" fill="#dee2e6" dy=".3em"></text>
            </svg>
            <p>PATIENT STORIES</p>
            <p>Ari te res qui conet vel et labor sit quidero cones dunt, cupturernam, ne int. Optatur, cor sus. Abo. Ximporib ernate cullition pratur aspis autem etus aut que nos dolupti berrovit</p>
            <p>OCT 30 / Dr Whitehurst</p>
            <a href="/individual-story" className="btn btn-primary">Keep Reading</a>
          </Col>
        </Row>
        <div className="text-center p-5 ref">
          <BackToTop />
        </div>
      </div>
      <SubFooter
        image="/img-hero5.png"
        icons={[
          {
            href: "/explore-the-report-patients",
            img: "/icon-clover.svg",
            alt: " Explore the Test Report",
            text1: "Explore the",
            text2: "Test Report"
          },{
            href: "#",
            img: "/icon-bubble.svg",
            alt: " Explore the Test Report",
            text1: "Download the",
            text2: "Conversation Guide"
          }
        ]} />
      <Footer />
    </div>
  );
}

export default Stories;
