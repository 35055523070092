//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import { Navbar, SubFooter, Footer, BackToTop, FloatingPetal, SEO } from "../../lib";

import ReactGA from 'react-ga';

function PressReleasePage102920() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='IDEAL Study Expands Clinical Evidence' description='Biotheranostics’ Breast Cancer IndexTM IDEAL study expands clinical evidence for prediction of extended endocrine benefit to patients treated with aromatase inhibitors' />
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc g3 round">
          <div className="clover-container">
            <div className="copy headline">
              <h1 className="mt-0 color-hologic-blue">
                IDEAL Study <br />Expands BCI Predictive Evidence
              </h1>
            </div>
            <div className="photo-clover">                          
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />
      

      <div className="container content-page">
      <div className="row w-90 mx-auto my-5">
          <div className="col-12 pt-5 pb-2 text-center">
            <h3 className="pb-2">
              Biotheranostics' Breast Cancer Index<sup>&reg;</sup> IDEAL study expands clinical evidence for prediction of extended endocrine benefit to patients treated with aromatase inhibitors
            </h3>
            <h6 className="font1 clr c10 mt-5">
              SAN DIEGO, Calif., October 29, 2020.
            </h6>
          </div>
        </div>       
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">   
          <div className="col-12">
            <p>
              SAN DIEGO, Calif., October 29, 2020. Biotheranostics announces the publication of results from
              the IDEAL study in <i>Clinical Cancer Research</i> which reports novel findings of the Breast Cancer
              Index (BCI) and predictive evidence in patients treated with aromatase inhibitors. In collaboration
              with Leiden University Medical Center in the Netherlands, the Investigation on the Duration of
              Extended Adjuvant Letrozole (IDEAL) trial examined the BCI HOXB13/IL17BR ratio (H/I) and its
              ability to predict benefit from extended endocrine therapy in 908 patients randomized to an
              additional 2.5 versus 5 years of extended endocrine therapy with letrozole after completing 5 years
              of therapy including combination tamoxifen/aromatase inhibitor or aromatase inhibitor monotherapy.
            </p>
            <p>
              Findings from the study further strengthen the clinical evidence for the Breast Cancer Index as the
              endocrine response biomarker that identifies patients with improved outcomes as well as those who
              are not likely to benefit from longer durations of endocrine treatment. With practice changing data
              from the IDEAL study, BCI clinical evidence for endocrine prediction has been demonstrated in
              three distinct therapy groups - patients treated with Tamoxifen/Tamoxifen, Tamoxifen/Aromatase
              Inhibitor and now, for the first time, Aromatase Inhibitor/Aromatase Inhibitor. Consistent
              performance across multiple studies supports the comprehensive clinical utility of BCI in extended
              endocrine therapy decision-making, agnostic to background therapy with tamoxifen or aromatase
              inhibitors.
            </p>
            <p>
              BCI is the only commercially available genomic assay with predictive evidence to inform the
              decision of extended endocrine therapy, allowing physicians to improve their ability to guide
              individualized patient care and joint decision making for women with HR+, early-stage breast
              cancer. Notably, 70% of women are diagnosed with HR+ breast cancer in the post-menopausal
              setting. The IDEAL study represents the first ever validation demonstrating prediction of patient
              benefit from extended aromatase inhibitor therapy following 5 years of primary adjuvant treatment
              that included an aromatase inhibitor, the standard of care for post-menopausal patients. This newly
              published predictive data supports a significant increase in the number of women for whom BCI
              may assist in the decision to extend aromatase inhibitor therapy.
            </p>
            <p>
              Catherine Schnabel, Ph.D., Chief Scientific Officer, Biotheranostics, said, “Results from the IDEAL
              study represent a major milestone for BCI in regards to evidentiary rigor and to better align its
              predictive utility to the standard of care for postmenopausal women with HR+ breast cancer.”
            </p>
            <p>
              Don Hardison, Biotheranostics’ President and CEO adds, “We are thrilled that the Breast Cancer
              Index IDEAL study has been published in <i>Clinical Cancer Research</i>. This practice-changing study
              broadens the evidence supporting the use of Breast Cancer Index for women with HR+, early-stage
              breast cancer faced with the decision of extending their endocrine therapy, regardless of treatment
              with tamoxifen or an aromatase inhibitor. We are fortunate to offer the only commercially available
              test that predicts benefit from extended endocrine therapy.”
            </p>            
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">
          <div className="col-12">
            <h4>About Breast Cancer Index<sup className="sup-sm">TM</sup></h4>
            <p>Breast Cancer Index is a molecular, gene expression-based test uniquely positioned to provide information to help physicians individualize treatment decisions for patients with early stage, ER+ breast cancer. This breakthrough test helps oncologists and patients navigate the difficult trade-off between taking steps to prevent recurrence of their disease and facing significant side effects and safety challenges related to unnecessary treatment. Breast Cancer Index holds guidelines designation from the American Joint Committee on Cancer for cancer staging based on molecular profile; ASCO, NCCN, European Group on Tumor Markers (EGTM), and St. Gallen to inform the chemotherapy decision; and ASCO and EGTM to inform the extended endocrine treatment dilemma. It is the only validated, commercially available test that provides risk of overall and late distant recurrence and predicts the likelihood of benefit from extended endocrine therapy. For more information, visit <a href="https://www.breastcancerindex.com" target="_blank">www.breastcancerindex.com</a>.</p>
            <h4>About Biotheranostics</h4>
            <p>
              Biotheranostics, Inc. operates a CLIA-certified and CAP-accredited diagnostic laboratory in 
              San Diego, California. Biotheranostics, Inc., is a leading healthcare provider in the 
              oncology field, assisting physicians in the treatment of cancer patients. Its suite of 
              proprietary molecular diagnostic tests provides important information to physicians to 
              tailor treatment to individual patients. The company’s <a href="https://breastcancerindex.com" target="_blank">Breast Cancer Index</a><sup>&reg;</sup>; and <a href="https://www.cancertypeid.com/" target="_blank">CancerTYPE ID</a><sup>&reg;</sup> tests address a variety of unmet medical 
              needs in the management of cancer patients, and extensive clinical studies have confirmed 
              the accuracy, clinical validity, clinical utility, and cost-effectiveness of the tests. 
              Learn more at <a href="http://www.biotheranostics.com" target="_blank">www.biotheranostics.com</a>.</p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <a href="/resources-providers" className="btn btn-secondary hcp w-250">Back To All</a>
          </div>
        </div>
      </div>
      
      <BackToTop />

      <SubFooter 
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard"
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: " Resources for Providers",
            text1: "Resources",
            text2: "for Providers"
          }
        ]} />
      
      <Footer 
            showTopLinks={true} topLinks={[
              {link: "/", label: "For Patients"},
              {link: "/know-someone-with-breast-cancer", label: "For Supporters"}
            ]}
            showSecondaryLinks={true} secondaryLinks={[
              {link: "/providers-resources", label: "Resources"},
              {link: "/contact-providers", label: "Explore The Report"},
              {link: "/contact-providers", label: "Contact"},
              {link: "/order-test", label: "Order The Test"}
            ]} />

    </div>   
  );

}

export default PressReleasePage102920;
