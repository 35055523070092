//Copyright Breast Cancer Index || 2021
//SEO Helper

import React from 'react';
import { Helmet } from 'react-helmet';

const SUFFIX = 'Breast Cancer Index';

/**
 * SEO wrapper for pages to allow for appending a site-wide suffix to the title
 * @param {object} props 
 */
export default function SEO(props) {
  const title       = (props && props.title) || SUFFIX;
  const hideSuffix  = props && props.hideSuffix;
  const description = props && props.description;
  const meta        = (props && props.meta) || [];
  const finalTitle  = hideSuffix ? title : `${title} - ${SUFFIX}`;

  // add description, if provided
  if (description) {
    meta.push({ name: 'description', content: description });
  }

  return (
    <Helmet>
      <title>{finalTitle}</title>
      {meta.map(({ name, content }, i) => <meta key={i} name={name} content={content} />)}
    </Helmet>
  );
};
