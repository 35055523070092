//Copyright Breast Cancer Index || 2021

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop,
    FloatingPetal
} from "../../lib";
 
import ReactGA from 'react-ga';

const Article4 = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc hcp-gradient round">
          <div className="clover-container">
            <div className="copy headline middle">
              <h1 className="mt-0 color-hologic-blue">
                Individualizing
                 <br />
                Extended
                 <br />
                Endocrine
                 <br />
                Therapy
                 <br />
                Decisions
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />

      <div className="container content-page">
        <div className="row w-90 max-700 mx-auto">
          <div className="col-12 py-5 text-center">
            <h3 className="pb-2">
              Ten Years Of Endocrine Therapy: Is It Right For Your Patients?
            </h3>
            <h4 className="font1 clr c1">
              Nearly 95% Of Women With Early-Stage, Hormone Receptor–Positive
              (HR+) Breast Cancer Do Not Benefit From Extended Endocrine Therapy
              <sup>1-6</sup>
            </h4>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto my-5">
          <div className="col-12 text-center">
            <p>
              Trials consistently demonstrate that only 3-5% of women benefit
              from extended endocrine therapy<sup>1-6</sup>, supporting the
              strong need for individualized patient selection rather than broad
              use of prolonged treatment.
            </p>
            <p className="mt-5 text-center">
              <b>
                Adjuvant Endocrine Therapy Extension: 5 vs 10 Years
                <sup>1-5</sup>
              </b>
            </p>
          </div>
          <div className="col-12 py-3 text-center">
            <img
              src="/articles/article4-chart-main.png"
              alt=""
              className="w-100"
            />
            <p className="ref text-left mt-3">
              * Based on disease-free survival or cumulative risk of recurrence
              rates as reported in the primary publications (note that the
              definitions of disease-free were not identical across trials)
            </p>
          </div>
        </div>
      </div>

      {/* <!--QUARTER ROUND --> */}
      <section className="qtr-round-article my-5">
        <div className="container content">
          <div className="row">
            <div className="col-10 offset-1 col-sm-11 pr-0 pr-sm-5">
              <h2 className="pr-0 pr-sm-5 quote no-close big">
                Genomic Classifiers That Predict Risk Of Late Recurrence And/Or
                Benefit From Extended Endocrine Therapy May Help To Further
                Individualize The Recommendation For Extended Aromatase
                Inhibitor Therapy.”<sup>5</sup>
              </h2>
              <p>- Eleftherios P. Mamounas, MD</p>
            </div>
          </div>
        </div>
        <div className="round-bg bgc hcp-gradient horizontal"></div>
      </section>

      <div className="container content-page">
        <div className="row mx-auto">
          <div className="col-12">
            <div className="bgc g6 my-5 py-5 px-3 text-center bracket-box">
              <div className="curly reverse mr-3 d-none d-sm-block">
                <img src="/shape-curly.svg" alt="" className="w-100" />
              </div>
              <h4 className="clr c1">
                While A Small Percentage Of Early-Stage, HR+ Patients Benefit
                From Extended Endocrine Treatment, Nearly 95% Do Not.
                <sup>1-6</sup>
                 <br />
                 <br />
                100% Of Women Deserve To Know  <br />
                What Is Right For Them.
              </h4>
              <div className="curly ml-3 d-none d-sm-block">
                <img src="/shape-curly.svg" alt="" className="w-100" />
              </div>
            </div>
          </div>
        </div>
        <div className="row w-90 max-700 mx-auto">
          <div className="col-12 text-center">
            <p className="mb-5">
              <b>
                Learn how Breast Cancer Index<sup>&reg;</sup> can help determine
                which of your patients are among the women who are likely to
                benefit from extended endocrine therapy.
              </b>
            </p>
            <a href="/predictive-power-of-bci" className="btn btn-primary hcp my-5">
              The Predictive Power of Breast Cancer Index
            </a>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <p className="ref">
              <strong>References:</strong>{' '}
			  <strong>1.&nbsp;</strong>Davies C, et al. Lancet Oncol. 2013;381: 805-816.{' '}
              <strong>2.&nbsp;</strong>Gray R et al. J Clin Oncol 2013; 31: (suppl; abstr 5).{' '}
			  <strong>3.&nbsp;</strong>Jakesz R, et al. J Natl Cancer Inst. 2007;99: 1845-1853.{' '}
			  <strong>4.&nbsp;</strong>Goss PE, et al. J Natl Cancer Inst. 2005;97: 262-1271.{' '}
			  <strong>5.&nbsp;</strong>Mamounas EP et al. NSABP B-42. GS4-01. SABCS 2019. Dec 12, 2019.{' '}
			  <strong>6.&nbsp;</strong>Goss PE et al. N Engl J Med. 2016; 375:209-219.
            </p>
            <a
              href="/resources-providers"
              className="btn btn-secondary hcp mt-5 w-250"
            >
              Back To All
            </a>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter
        site="hcp"
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard",
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: "Resources For Providers",
            text1: "Resources",
            text2: "For Providers",
          },
        ]}
        disclaimer=""
      />

      <Footer
        showTopLinks={true}
        topLinks={[
          { link: "/", label: "For Patients" },
          { link: "/know-someone-with-breast-cancer", label: "For Supporters" },
        ]}
        showSecondaryLinks={true}
        secondaryLinks={[
          { link: "/resources-providers", label: "Resources" },
          { link: "/contact-providers", label: "Explore The Report" },
          { link: "/contact-providers", label: "Contact" },
          { link: "/order-test", label: "Order The Test" },
        ]}
      />
    </div>
  );
}

export default Article4;
