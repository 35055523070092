//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import { Navbar, SubFooter, Footer, BackToTop, FloatingPetal, SEO } from '../../lib';

import ReactGA from 'react-ga';

const Contact = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO
        title='Contact Us'
        description='If you are a breast cancer patient looking for more information about Breast Cancer Index, contact the BCI support team today for help and insight.'
      />
      <Navbar type='patients' />

      {/* <!-- IMAGE HEADER --> */}
      <header className='page-header'>
        <div className='container-fluid mr-auto round'>
          <div className='image'></div>
        </div>
      </header>

      <FloatingPetal type='patient' />

      {/* <!-- CONTENT AREA --> */}
      <div className='container content-page'>
        <div className='row'>
          <div className='col-12 col-sm-6 py-5'>
            <h1>Contact & Support</h1>
            <p>
              Our Patient Services Team is here to support you. For questions or concerns, feel free to give us a call or send us an email and we’ll be happy to
              assist you.
            </p>
            <h4 className='font1 clr c1'>Contact The Patient Services Team:</h4>
            <p>
              <b>Call:</b> <a href='tel:844-319-8111'>(844) 319-8111</a>
            </p>
            <p>
              <b>Email:</b> <a href='mailto:patientservices@biotheranostics.com'>patientservices@biotheranostics.com</a>
            </p>
            <p>
              <b>Hours:</b> 6:00am - 4:00pm (PST)
            </p>
            <p className='mt-5'>
              <a href='mailto:patientservices@biotheranostics.com' className='btn btn-primary w-250'>
                Send Us An Email
              </a>
            </p>
          </div>
          <div className='col-12 col-sm-6 py-5'>
            <h1>Billing &amp; Insurance</h1>
            <ul>
              <li>
                Medicare covers Breast Cancer Index<sup>&reg;</sup> at a predetermined rate with no fees charged to you (subject to specific coverage
                criteria).*
              </li>
              <li>Breast Cancer Index is reimbursed by many commercial insurance companies.</li>
              <li>We offer a Financial Assistance Program designed to minimize your financial burden if certain criteria are met.</li>
            </ul>
            <p className='ref'>
              *
              <a
                href='https://www.cms.gov/medicare-coverage-database/view/lcd.aspx?lcdid=37822&ver=16&keyword=breast%20cancer%20index&keywordType=starts&areaId=all&docType=F,P&contractOption=all&sortBy=relevance&bc=1'
                target='_blank'
                rel='noopener noreferrer'
              >
                Click here to see coverage criteria under the current LCD (Local Coverage Determination)
              </a>
            </p>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter icons={[]} />

      <Footer
        showTopLinks={true}
        topLinks={[{ link: '/resources-providers', label: 'For Providers' }]}
        showSecondaryLinks={true}
        secondaryLinks={[{ link: '/', label: "I'm A Patient" }]}
        type='patient'
      />
    </div>
  );
};

export default Contact;
