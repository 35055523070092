//Copyright Breast Cancer Index || June 2022
import React, { useState } from 'react';
import { Navbar, SubFooter, Footer, BackToTop, FloatingPetal, DownloadModal, SEO } from '../../lib';
import { IoPlayCircleOutline, IoPauseCircleOutline } from 'react-icons/io5';
import ReactGA from 'react-ga';
import './styles.scss';
const ArticleObgynPerspectiveOnBCI = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const [videoPlaying, setVideoPlaying] = useState(false);
    const [show, setShow] = useState(false);
    const [mTitle, setTitleText] = useState('');
    const [mBody, setBodyText] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (type) => {
        if (type === 'patient') {
            setTitleText('Download the Conversation Guide');
            setBodyText('');
        } else if (type === 'provider') {
            setTitleText('Order Breast Cancer Index');
            setBodyText('Download the Conversation Guide');
        }
        setShow(true);
    };

    const playVideo = async () => {
        const videoElem = document.getElementById('obgynVideo');
        if (videoElem.paused) {
            try {
                await videoElem.play();
                setVideoPlaying(true);
            } catch (err) {}
        } else {
            try {
                await videoElem.pause();
                setVideoPlaying(false);
            } catch (err) {}
        }
    };

    return (
        <>
            <SEO title="An OB/GYN's Perspective on BCI" description='The unique role of OB/GYNs in the ongoing management of breast cancer patients.' />
            <Navbar type='providers' />
            <FloatingPetal type='provider' />
            <header className='page-header float-hand'>
                <div className='container-fluid w-100 bgc hcp-gradient not-round'>
                    <div className='bg-pattern'></div>
                    <div className='container py-4'>
                        <h2 className='my-4 w-lg-75'>The unique role of OB/GYNs in the ongoing management of breast cancer patients</h2>
                        <div className='row flex-column-reverse flex-lg-row'>
                            <div className='col-12 col-lg-5'>
                                <p>
                                    OB/GYNs serve as vital confidants and trusted sources of information as patients navigate long-term breast cancer treatment, including its side
                                    effects. In this supportive role, a normal exam can turn into a candid conversation about side effects and their impact on quality of life.
                                </p>
                                <p>
                                    Alison Cowan, MD, is one of these OB/GYNs. She&nbsp;understands the importance of patients with&nbsp;HR+, early-stage breast cancer talking to
                                    their oncologists about their concerns and options beyond 5 years.{' '}
                                    <strong>
                                        Watch as she shares the opportunity for OB/GYNs to encourage conversation between patients and their oncologists regarding endocrine therapy
                                        options and Breast Cancer Index<sup>&reg;</sup>.
                                    </strong>
                                </p>
                            </div>
                            <div className='col-12 col-lg-7'>
                                <div className={`video-wrap ${videoPlaying ? 'video-playing' : ''}`}>
                                    <div className='play-button' onClick={playVideo}>
                                        {videoPlaying ? <IoPauseCircleOutline /> : <IoPlayCircleOutline />}
                                    </div>
                                    <video id='obgynVideo' poster='/articles/obgyn-video-thumb.png' playsInline='playsinline' {...(videoPlaying && { controls: true })}>
                                        <source src='/obgyn-hologic-bci-video.mp4' type='video/mp4' />
                                    </video>
                                </div>
                                <div className='q mobile d-block d-lg-none my-4 text-center'>
                                    “We’re already involved in managing side effects associated with endocrine therapy, and now we can give them information about Breast Cancer
                                    Index to help them make an informed decision.”
                                </div>
                                <div className='bio mobile d-block d-lg-none my-4 pb-4'>
                                    ALISON COWAN, MD <br />
                                    OB/GYN <br />
                                    <em>Former Medical Director, Hologic, Inc.</em>
                                </div>
                            </div>
                        </div>
                        <div className='q desktop d-none d-lg-block mt-4 text-center'>
                            “We’re already involved in managing side effects associated with endocrine therapy, and now we can give them information about Breast Cancer Index to
                            help them make an informed decision.”
                        </div>
                        <div className='bio d-none d-lg-block my-4'>
                            ALISON COWAN, MD <br />
                            OB/GYN <br />
                            <em>Former Medical Director, Hologic, Inc.</em>
                        </div>
                    </div>
                </div>
            </header>
            <main>
                <div className='container py-4'>
                    <h3 className='text-left text-md-center mt-0 mt-md-4'>About Endocrine Therapy</h3>
                    <h4 className='font1 clr c1 text-left text-md-center my-4 font-weight-bold'>
                        Endocrine therapy (or anti-estrogen therapy) is used to help lower the risk of recurrence for women diagnosed with hormone receptor-positive (HR+) breast
                        cancer.
                    </h4>
                    <div className='row flex-column flex-lg-row'>
                        <div className='bleed-left col-11 col-lg-6'>
                            <p style={{ fontSize: '18px' }}>
                                The most commonly used endocrine therapies are aromatase inhibitors (anastrozole, exemestane, letrozole) and Tamoxifen
                            </p>
                        </div>
                        <div className='col-12 col-lg-6'>
                            <p>
                                Standard treatment is at least 5 years of endocrine therapy, but oncologists will sometimes recommend longer treatment to help reduce the risk of
                                recurrence.
                            </p>
                            <p>
                                However, the majority of women DO NOT receive any benefit from extending endocrine therapy past year 5, but may still experience potential side
                                effects and other health risks associated with treatment that can be significant.<sup>1-6</sup>
                            </p>
                            <p>Breast Cancer Index helps identify which women are likely to benefit from extended endocrine therapy.</p>
                        </div>
                    </div>
                    <hr className='my-0 my-md-4'></hr>
                    <div className='row'>
                        <div className='col-12 col-lg-6 pr-lg-5'>
                            <h4 className='font1 clr c1 text-left text-md-center text-lg-left my-4'>
                                Help your patients with early-stage, HR+ breast cancer better understand their endocrine therapy options beyond 5 years
                            </h4>
                            <ul>
                                <li>Help them avoid debilitating side effects they may not need to endure</li>
                                <li>Make them secure in their decision to continue with treatment if they are likely to benefit from extending endocrine therapy</li>
                            </ul>
                            <p>
                                You already lend a supportive ear and help them manage side effects. Now you can give them the tools to talk to their oncologist about their
                                options.
                            </p>
                            <img className='d-block d-lg-none mx-auto' width='90%' src='/articles/obgyn-clover.svg' alt='clover' />
                            <a
                                href='/BTNBCI_1423450-BCI-Pilot Sales-Aid_Digital_2-18.pdf'
                                className='d-block btn btn-primary hcp mt-5 w-100 w-lg-75 mx-auto mx-lg-0'
                                target='_blank'
                            >
                                Download a BCI Brochure
                            </a>
                            <button onClick={() => handleShow('patient')} className='d-block btn btn-primary hcp mt-3 w-100 w-lg-75 mx-auto mx-lg-0'>
                                Download Patient Conversation Guide
                            </button>
                        </div>
                        <div className='col-12 col-lg-6 d-none d-lg-block'>
                            <img className='clover' src='/articles/obgyn-clover.svg' alt='clover' />
                        </div>
                    </div>
                    <hr className='my-5' />
                    <p className='ref max-700 mx-auto text-left text-md-center mb-5'>
                        <strong>References:</strong>{' '}
						<b>1.&nbsp;</b>Davies C, et al. Lancet Oncol. 2013;381:805-816.{' '}
						<b>2.&nbsp;</b>Gray R, et al. J Clin Oncol. 2013;31:(suppl; abstr 5).
						<b>3.&nbsp;</b>Jakesz R, et al. J Natl Cancer Inst. 2007;99:1845-1853.{' '}
						<b>4.&nbsp;</b>Goss PE, et al. J Natl Cancer Inst. 2005;97:1262-1271.{' '}
						<b>5.&nbsp;</b>Goss PE, et al. N Eng J Med. 2003;349(19):1793-1802.{' '}
						<b>6.&nbsp;</b>Mamounas EP, et al. GS4-01 presented at SABCS; December 12, 2019.
                    </p>
                    <div className='d-flex justify-content-center'>
                        <a href='/resources-providers' className='btn btn-secondary hcp'>
                            Back To All
                        </a>
                    </div>
                </div>
            </main>
            <DownloadModal show={show} title={mTitle} body={mBody} handleClose={handleClose} />
            <BackToTop />
            <SubFooter
                site='hcp'
                image=''
                icons={[
                    {
                        href: '/order-test',
                        img: '/icon-clipboard.svg',
                        alt: ' Order Breast Cancer Index',
                        text1: 'Order Breast',
                        text2: 'Cancer Index',
                        className: 'clipboard',
                    },
                    {
                        href: '/resources-providers',
                        img: '/icon-gear.svg',
                        alt: 'Resources For Providers',
                        text1: 'Resources',
                        text2: 'For Providers',
                    },
                ]}
                disclaimer=''
            />
            <Footer
                showReferences={[true, true]}
                showTopLinks={true}
                topLinks={[
                    { link: '/', label: 'For Patients' },
                    { link: '/', label: 'For Supporters' },
                ]}
                showSecondaryLinks={true}
                secondaryLinks={[
                    { link: '/resources-providers', label: 'Resources' },
                    {
                        link: '/explore-the-report-providers',
                        label: 'Explore The Report',
                    },
                    { link: '/contact-providers', label: 'Contact' },
                    { link: '/order-test', label: 'Order The Test' },
                ]}
            />
        </>
    );
};

export default ArticleObgynPerspectiveOnBCI;
