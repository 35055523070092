//Copyright Breast Cancer Index || 2021

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop,
    FloatingPetal
} from "../../lib";

import ReactGA from 'react-ga';

const Article2 = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc hcp-gradient round">
          <div className="clover-container">
            <div className="copy headline middle">
              <h1 className="mt-0 color-hologic-blue">
                Is Additional Endocrine Therapy Likely to Reduce Her Risk?
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />

      <div className="container content-page">
        <div className="row w-90 mx-auto">
          <div className="col-12 py-5 text-center">
            <h3>Prognostic ≠ Predictive</h3>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto">
          <div className="col-12 py-5 text-center">
            <h4 className="font1 clr c1 mb-5">
              In determining whether extended endocrine therapy is right for
              your early-stage, hormone receptor–positive (HR+) breast cancer
              patient, understanding her risk of recurrence is an important
              consideration.
               <br />
               <br />
              Knowing whether the treatment is likely to benefit her is crucial.
            </h4>

            <p>
              <b>What are Prognostic vs. Predictive factors?</b>
            </p>
          </div>
        </div>
        <div className="row w-90 max-700 mx-auto pb-5">
          <div className="col-12">
            <div className="align-items-center d-block d-sm-flex my-2">
              <div className="article-leaf bgc g5 my-4 my-sm-0 p-4 mx-auto ml-sm-0 mr-sm-5">
                Predictive
                 <br />
                Factors:
              </div>
              <div>
                <ul className="mb-0">
                  <li>
                    Assess the most likely response to a particular treatment
                  </li>
                  <li>
                    May help determine whether a patient is likely to benefit
                    from treatment
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="align-items-center d-block d-sm-flex my-2">
              <div className="article-leaf bgc g3 my-4 my-sm-0 p-4 mx-auto ml-sm-0 mr-sm-5">
                Prognostic
                 <br />
                Factors:
              </div>
              <div>
                <ul className="mb-0">
                  <li>
                    Evaluate the progression of a disease, with or without
                    treatment
                  </li>
                  <li>May help determine a patient’s risk of recurrence</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 pb-5">
            <h4 className="font1 clr c1 text-center">
              Clinical and Pathologic Factors Do Not Reliably Predict Benefit
              From Extended Endocrine Therapy
            </h4>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-6">
                <p>
                  While clinicopathologic factors may help in the prognostic
                  evaluation of recurrence risk, these factors and other
                  commercially available tests have not been validated to
                  predict benefit from extended endocrine therapy<sup>1-4</sup>
                </p>
              </div>
              <div className="col-12 col-md-6 text-center x-bg mt-5 mt-md-0">
                <h5 className="font1 mb-2 text-uppercase">Tumor size</h5>
                <h5 className="font1 mb-2 text-uppercase">Tumor grade</h5>
                <h5 className="font1 mb-2 text-uppercase">
                  RS + quantitative ER
                </h5>
                <h5 className="font1 mb-2 text-uppercase">Menopausal status</h5>
                <h5 className="font1 mb-2 text-uppercase">KI67</h5>
                <h5 className="font1 mb-2 text-uppercase">Age</h5>
                <h5 className="font1 mb-2 text-uppercase">
                  Other genomic assays
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="row w-90 mx-auto">
          <div className="col-10 offset-1 col-sm-8 offset-sm-2 text-center">
            <h4 className="font1 clr c1">
              BCI Is THE ONLY Commercially Available Test That Can Predict
              Whether Another 5 Years Of Endocrine Therapy Is Likely To Help
              Reduce Her Risk Of Late-Distant Recurrence.
            </h4>
            <a href="/predictive-power-of-bci" className="btn btn-primary hcp my-5">
              Learn More About the Predictive Power of BCI
            </a>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row py-5">
          <div className="col-10 offset-1 text-center">
            <p className="ref">
              <strong>References:</strong>{' '}
			  <strong>1.&nbsp;</strong>Davies C et al., Lancet. 2013 ;381(9869):805-16.{' '}
			  <strong>2.&nbsp;</strong>Goss PE et al, J Natl Cancer Inst 2005;97:1262–71.{' '}
			  <strong>3.&nbsp;</strong>Goss PE et al., N Engl J Med. 2016 Jun 5 (Online).{' '}
			  <strong>4.&nbsp;</strong>Mamounas EP et al. San Antonio Breast Cancer Symposium, 2016.
            </p>
            <a
              href="/resources-providers"
              className="btn btn-secondary hcp mt-5 w-250"
            >
              Back To All
            </a>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter
        site="hcp"
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard",
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: "Resources For Providers",
            text1: "Resources",
            text2: "For Providers",
          },
        ]}
        disclaimer=""
      />

      <Footer
        showTopLinks={true}
        topLinks={[
          { link: "/", label: "For Patients" },
          { link: "/know-someone-with-breast-cancer", label: "For Supporters" },
        ]}
        showSecondaryLinks={true}
        secondaryLinks={[
          { link: "/resources-providers", label: "Resources" },
          { link: "/contact-providers", label: "Explore The Report" },
          { link: "/contact-providers", label: "Contact" },
          { link: "/order-test", label: "Order The Test" },
        ]}
      />
    </div>
  );
}

export default Article2;
