//Copyright Breast Cancer Index || 2021
//Main Page

import React, { useState } from 'react';

import { Navbar, SecondaryPatientNavbar, SubFooter, Footer, BackToTop, FloatingPetal, DownloadModal, SEO } from "../../lib";

import ReactGA from 'react-ga';
import './style.scss';

const AlreadyOnEndocrineTherapy = () => {

  const [show, setShow] = useState(false);
  const [mTitle, setTitleText] = useState("");
  const [mBody, setBodyText] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (type) => {
    if(type === "patient"){
      setTitleText("Download the Conversation Guide");
      setBodyText("");
    }else if(type === "provider"){
      setTitleText("Order Breast Cancer Index");
      setBodyText("Download the Conversation Guide");
    }
    setShow(true);
  }

  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='Is Anti-Estrogen Therapy Right For You?' description='Are you a breast cancer patient on anti-estrogen therapy? Use our Breast Cancer Index resources to learn more about testing and extended treatment.' />
      <Navbar type="patients" />

      {/* <!-- HEADER - ALREADY ON ANTI-ESTROGEN --> */}
      <header className="page-header patient-side">
        <div className="container-fluid not-round">
          <div className="clover-container">
            <div className="copy">
              <h1 className="quote">
                Anti-Estrogen Therapy Is Not Fun. But Nothing’s Worse Than the Fear Of My Cancer Coming Back.
              </h1>
            </div>
            <div className="photo large">
              <img src="/img-hero2-header.png" alt="" className="w-100" />
            </div>
            <div className="photo-clover">
              <img src="/clover-teal.svg" alt="" className="w-100" />
            </div>
          </div>
        </div>
      </header>

      <FloatingPetal type="patient"/>
      <DownloadModal show={show} title={mTitle} body={mBody} handleClose={handleClose} />

      {/* <!-- SECONDARY --> */}
      <section className="d-flex">
        <div className="secondary-header bgc g1">
          <div className="copy">
            <p>
              Breast Cancer Index<sup>&reg;</sup> may improve clarity for you and your oncology team as you consider the duration of your anti-estrogen therapy — in fact, it’s the only validated, commercially available test that can tell you whether you’re likely to benefit from treatment after 5 years.
            </p>
            <p>
              Knowing your risk of recurrence after 5 years of therapy and whether or not additional anti-estrogen therapy is likely to benefit you gives you the information to make joint decisions with your oncology team. Tolerating side effects is not always necessary, understanding your benefit is.
            </p>
            <h6>Not Another Minute Not Knowing If Treatment Is Benefiting You</h6>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </section>

      {/* <!-- TITLE --> */}
      <section>
        <div className="text-center p-3 p-sm-5">
          <h1 className="page-title m-4">
            You can’t take the fear out of cancer.  <br />
            <span>
              But you can reduce the  <br />uncertainty around treating&nbsp;it.
            </span>
          </h1>
        </div>
      </section>

      {/* <!--FULL ROUND --> */}
      <section className="full-round mb-5 w-100 side-effects">
        <div className="image">
          <img src="/img-logomark-hero3.png" alt="" />
			<p className="mt-2 mb-0">
				Use this conversation guide to talk to your doctor about managing side effects&nbsp;and where BCI fits in your plan.
			</p>
			<div className="icon">
              <img src="/icon-bubble-yellow.svg" alt="" className="w-100" />
            </div>
			<a className="btn btn-primary with-border" onClick={() => handleShow("patient")}>Download Conversation Guide</a>
        </div>
        <div className="content">
          <div className="copy">
            <h2 className="mb-4 mt-4">
              Side Effects: An Expected Part of Treatment
            </h2>
            <p className="mb-5">
              If you experience uncomfortable and frustrating side effects as a result of taking anti-estrogen therapy routinely, you are not alone. And while many of these may have a negative impact on your everyday life, some have the potential to pose an even greater risk to your health.<sup>7-11</sup>
            </p>
            <div className="hlines">
              <p>
                Possible side effects for aromatase inhibitors:<sup>7-10</sup>
              </p>
              <ul className="t2">
                <li>Hot flashes</li>
                <li>Joint pain and stiffness</li>
                <li>Vaginal dryness</li>
                <li>Hair thinning</li>
                <li>High cholesterol</li>
                <li>High blood pressure</li>
                <li>Osteoporosis</li>
                <li>Bone fractures</li>
              </ul>
              <p>
                Possible side effects for tamoxifen:<sup>11</sup>
              </p>
              <ul className="t2">
                <li>Mood swings</li>
                <li>Hot flashes</li>
                <li>Vaginal dryness</li>
                <li>Weight gain</li>
                <li>Early-onset menopause</li>
                <li>Cataracts</li>
                <li>Blood clots</li>
                <li>Endometrial cancer</li>
                <li>Stroke</li>
              </ul>
            </div>
          </div>
          <div className="bg"></div>
        </div>
      </section>

      {/* <!--HALF ROUND --> */}
      <section className="half-round right mb-5">
        <div className="image">
            <img src="/img-doc-right-hand-cropped.png" alt="hand holding form" />
        </div>
        <div className="content">
          <div className="copy ml-auto">
            <h2 className="mb-4 mt-4">
              Breast Cancer Index Is Changing  <br />The Game.
            </h2>
            <hr/>
            <p>
              While some women may benefit from continuing anti-estrogen therapy beyond 5 years, many women do not — it’s important to talk with your doctor about whether the potential benefit outweighs the side effects and risks that may come with five more years of treatment.
            </p>
            <p>
              Whether or not you are likely to benefit, Breast Cancer Index gives you and your doctor information you need to make the right treatment decision for you.
            </p>
            <hr/>
            <p>
              The Test Will Give You Information To Help You Answer These Questions:
            </p>
            <ul>
              <li>Am I likely to benefit from anti-estrogen therapy beyond 5 years?</li>
              <li>What is my risk of late-distant recurrence after 5 years?</li>
            </ul>
            <hr/>
            <p>
              This test can be an important tool in helping you and your oncology team determine whether an additional five years of anti-estrogen therapy may be beneficial for you.
            </p>
            <p>
              The test is performed only by Biotheranostics, Inc.
            </p>
          </div>
        </div>
        <div className="round-bg"></div>
      </section>

      {/* <!-- JOURNEY --> */}
      <div className="container">            
        <div className="row">
          <div className="col-12 text-center p-5">
            <h2 className="mb-4">
              The Breast Cancer Timeline
            </h2>
            <p>
              If you’ve been diagnosed with breast cancer, it may be hard to think beyond the minute you’re in. But the results of your Breast Cancer Index test can help give you and your oncology team more certainty as you develop your treatment plan.
            </p>
          </div>
        </div>
      </div>

      {/* <!-- TIMELINE --> */}
      <section className="timeline">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12 order-2 order-md-1 col-md-6 left">
              <div className="odd">
                <h4>
                  Diagnosis is the first step
                </h4>
                <p className="timeline-subtext">
                  The first point on the timeline for cancer treatment is what doctors refer to as “Time Zero.” For patients, this can be a world-shattering time full of chaos and information overload.
                </p>
              </div>
            </div>
            <div className="col-12 order-1 order-md-2 col-md-6 right">
              <div className="zero">
                <img src="/timeline-zero.svg" alt="" className="w-100" />
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12 col-md-6 d-none d-md-block">
              &nbsp;
            </div>
            <div className="col-12 col-md-6 right">
              <div className="highlight bgc g4">
                <div className="curly">
                  <img src="/shape-curly.svg" alt="" className="w-100" />
                </div>
                <div className="copy">
                  <h4>Anti-Estrogen Therapy Is The Standard Course Of Treatment</h4>
                  <p>
                  While anti-estrogen, or “endocrine,“ therapy is a standard course of treatment for hormone receptor–positive (HR+) breast cancer, the duration of treatment is an important decision for you and your oncology team to discuss.<sup>12</sup>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 left">
              <div className="odd hline">
                <h4>
                  Nearly 95% of Women Do Not Benefit From Treatment After 5 Years<sup>1-6</sup>
                </h4>
                <p>
                  While most women with early-stage HR+  breast cancer are prescribed anti-estrogen therapy, the duration of treatment may vary from patient to patient — at least 5 years of anti-estrogen therapy is usually recommended to help prevent a recurrence of your cancer. <sup>12</sup>
                </p>
                <p>
                  Breast Cancer Index can help you and your doctor determine whether a longer course of treatment is likely to benefit you.
                </p>
                <h6>
                  Talk to your doctor about Breast Cancer Index to understand when the test may be right for you.
                </h6>
                <a className="btn btn-primary mt-4" onClick={() => handleShow("patient")}>Download The Conversation Guide</a>
              </div>
            </div>
            <div className="col-12 col-md-6 right d-none d-sm-block">
              <div className="photo1">
                <img src="/img-timeline2-1.jpg" alt="" className="w-100" />
              </div>
            </div>
            <div className="col-12">
              <div className="year5">
                <img src="/timeline-year-5.svg" alt="" className="w-100" />
              </div>
            </div>
            <div className="col-12 col-md-6 d-none d-md-block">
              <div className="photo2">
                <img src="/img-timeline2-2.jpg" alt="" className="w-100" />
              </div>
            </div>           
            <div className="col-12 col-md-6 right">
              <div className="even hline">
                <h4>
                  Know If Additional Treatment Is Right For You
                </h4>
                <p>
                For some women, extending anti-estrogen therapy through year 10 may reduce the risk of recurrence.
                </p>
                <h6>
                  Breast Cancer Index can help you and your doctor decide what is right for you.
                </h6>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12 cta">
              <div className="year10">
                <img src="/timeline-year-10.svg" alt="" className="w-100" />
              </div>
              <div className="button">
                <a className="btn btn-primary" onClick={() => handleShow("patient")}>Download The Conversation Guide</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <BackToTop />
      
      <SubFooter 
        image="/img-hero1.png"
        icons={[
          {
            href: "/contact-patients",
            img: "/icon-person.svg",
            alt: " Contact & Support",
            text1: "Contact",
            text2: "& Support"
          },{
            modal: true,
            img: "/icon-bubble.svg",
            alt: " Explore the Test Report",
            text1: "Download The",
            text2: "Conversation Guide"
          }
        ]} 
        type='hasHero'/>
        
      <Footer 
        showReferences={[true, false]}
        showTopLinks={true} topLinks={[
          { link: "/resources-providers", label: "For Providers" },
          { link: "/know-someone-with-breast-cancer", label: "For Supporters" }
        ]}
        showSecondaryLinks={true} secondaryLinks={[
          { link: "/FAQs", label: "FAQs" },
          { link: "/contact-patients", label: "Contact & Support" }
        ]} 
        type='patient'
      />
    </div>
  );
}

export default AlreadyOnEndocrineTherapy;
