import React, { useState, useEffect } from 'react';
import { Navbar, SubFooter, Footer, BackToTop, SEO } from '../../lib';
import ReactGA from 'react-ga';

const OrderTest = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const { width: windowWidth } = useWindowSize();

  return (
    <div>
      <SEO
        title='Ordering Breast Cancer Index Test'
        description='Breast Cancer Index Testing is available in all 50 states. Click here to order a test directly from Breast Cancer Index or request an order form.'
        hideSuffix={true}
      />
      <Navbar type='providers' />

      {/* <!-- IMAGE HEADER --> */}
      <header className='page-header'>
        <div className='container-fluid bgc hcp-gradient round'>
          <div className='clover-container'>
            <div className='copy headline'>
              <h1 className='larger color-hologic-blue'>
                Not <br />
                Another <br />
                Minute
              </h1>
            </div>
            <div className='photo ordertest'>
              <img src='/img-hero-order-header.png' alt='' className='w-100' />
            </div>
            <div className='photo-clover'>
              <img src='/clover-gold.svg' alt='' className='w-100' />
            </div>
          </div>
          <div className='bg-pattern'></div>
        </div>
      </header>

      {/* <!-- CONTENT AREA --> */}
      <div className='container content-page'>
        <div className='row w-90 mx-auto'>
          <div className='col-12 py-5 text-center border-bottom'>
            <h3 className='pb-2'>
              Ordering Breast Cancer Index<sup>&reg;</sup>
            </h3>
            <h4 className='font1 clr c1'>Breast Cancer Index provides clinically actionable information that can impact her treatment plan beyond year 5.</h4>
          </div>
        </div>

        <div className='row w-90 max-700 mx-auto'>
          <div className='col-10 offset-1 py-5'>
            <p className='text-center'>
              <b>Insurance Information</b>
            </p>
            <ul className='bullets'>
              <li>
                Medicare covers Breast Cancer Index at a predetermined rate with no fees charged to your patients. Subject to specific coverage criteria.
                <sup>*</sup>
              </li>
              <li>
                Her commercial insurance plan may cover Breast Cancer Index. A member of our Patient Services Team will contact her to discuss the billing
                process and what to expect.
              </li>
              <li>
                We also offer a Financial Assistance Program designed to minimize financial burden for your patients if certain financial hardship criteria are
                met.
              </li>
            </ul>
            {windowWidth > 768 && <div id='order-form'></div>}
            <p className='text-center'>
              <sup>*</sup>
              <a
                href='https://www.cms.gov/medicare-coverage-database/details/lcd-details.aspx?LCDId=37822&amp;ContrId=360&amp;ver=14&amp;ContrVer=1&amp;CntrctrSelected=365*1&amp;Cntrctr=365&amp;name=&amp;DocType=4%7C5&amp;LCntrctr=365*1&amp;bc=AAAAAACAIAAA'
                target='_blank'
              >
                <i>Click here to see coverage criteria under the current Local Coverage Determination (LCD)</i>
              </a>{' '}
              »
            </p>
          </div>
        </div>
        <div className='border-bottom'></div>
        <div className='row w-90 mx-auto border-bottom'>
          <div className='col-12 col-md-6 pt-5'>
            <p className='text-center'>
              <b>Logistics</b>
            </p>
            <ul className='bullets'>
              <li>Offered in all 50 states</li>
              <li>CLIA-certified, CAP-accredited clinical laboratory</li>
              <li>Biotheranostics will coordinate sample shipment directly from hospital to lab</li>
              <li>Test results available within 7 business days (from receipt of specimen and required information)</li>
            </ul>
            <a href='/Patient_Acceptance_Criteria_Slim_Jim_PDF_Final_2024.pdf' target='_blank' style={{ fontWeight: '300' }}>
              <i>Download Eligibility Criteria</i>
            </a>
          </div>
          <div className='col-12 col-md-6 pt-5'>
            <p className='text-center'>
              <b>Specimen Preparation</b>
            </p>
            <ul className='bullets'>
              <li>BCI uses her original tissue specimen, no additional biopsy needed</li>
              <li>Formalin-fixed, paraffin-embedded (FFPE) tissue block preferred, or</li>
              <li>3-4 unstained, 10µm-thick sections on glass slides (an area of tumor that contains ≥40% neoplastic cells) and one H&E-stained slide</li>
            </ul>
            {windowWidth < 767 && <div id='order-form' style={{ paddingTop: '2rem', width: '100%' }}></div>}
          </div>

          <div className='col-12 py-5 text-center'>
            <p>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://hologicproviderportal.force.com/s/login/'
                className='btn btn-primary w-250'
                data-toggle='modal'
                data-target='#exampleModal'
              >
                Order Breast Cancer Index
              </a>
            </p>
            <p>
              <a target='_blank' href='/test-form.pdf' className='btn btn-secondary hcp w-250'>
                Download The Order Form
              </a>
            </p>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter
        site='hcp'
        icons={[
          {
            href: '/order-test',
            img: '/icon-clipboard.svg',
            alt: 'Order Breast Cancer Index',
            text1: 'Order Breast',
            text2: 'Cancer Index',
            className: 'clipboard',
          },
          {
            href: '/resources-providers',
            img: '/icon-gear.svg',
            alt: 'Resources For Providers',
            text1: 'Resources',
            text2: 'For Providers',
          },
        ]}
      />

      <Footer
        showTopLinks={true}
        topLinks={[{ link: '/', label: 'For Patients' }]}
        showSecondaryLinks={true}
        secondaryLinks={[
          { link: '/order-test', label: 'Order The Test' },
          { link: '/contact-providers', label: 'Contact' },
        ]}
        type='provider'
      />
    </div>
  );
};

export default OrderTest;

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
