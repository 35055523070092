//Copyright Breast Cancer Index || 2021
//Main Page

import React, { useState } from 'react';

import { Accordion, Navbar, SubFooter, Footer, BackToTop, CollapsibleCard, FloatingPetal, DownloadModal, SEO } from '../../lib';

import ReactGA from 'react-ga';

const FAQs = () => {
  const [show, setShow] = useState(false);
  const [mTitle, setTitleText] = useState('');
  const [mBody, setBodyText] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = (type) => {
    if (type === 'patient') {
      setTitleText('Download the Conversation Guide');
      setBodyText('');
    } else if (type === 'provider') {
      setTitleText('Order Breast Cancer Index');
      setBodyText('Download the Conversation Guide');
    }
    setShow(true);
  };

  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO
        title='Frequently Asked Questions'
        description='Wondering whether or not Breast Cancer Index testing is right for you? Check out our frequently asked questions.'
      />
      <Navbar type='patients' />

      {/* <!--QUARTER  ROUND HEADER --> */}
      <header className='page-header'>
        <div className='container-fluid bgc g3 round'>
          <div className='clover-container'>
            <div className='copy headline'>
              <h1 className='faq'>
                Frequently <br />
                Asked <br />
                Questions
              </h1>
            </div>
            <div className='photo-clover'>
              <img src='/clover-purple-tint.svg' alt='' className='w-100' />
            </div>
          </div>
          <div className='bg-pattern'></div>
        </div>
      </header>

      <FloatingPetal type='patient' />
      <DownloadModal show={show} title={mTitle} body={mBody} handleClose={handleClose} />

      {/* <!-- CONTENT AREA --> */}
      <div className='container content-page'>
        <div className='row'>
          <div className='col-12 py-5 border-bottom'>
            <h1>Breast Cancer Is Complex</h1>
            <h4 className='font1 clr c1'>Understanding Your Treatment Options Shouldn’t Be</h4>
            <Accordion>
              {/* <!-- 1 --> */}
              <CollapsibleCard eventKey='headingOne' title='What is Breast Cancer Index<sup>&reg;</sup>?'>
                <p>
                  Breast Cancer Index (BCI) is a test that provides information to help women diagnosed with early-stage, hormone receptor-positive (HR+) breast
                  cancer, and their oncologists make personalized decisions about extending anti-estrogen (also called hormonal or endocrine) therapy beyond 5
                  years.
                </p>
                <p>Breast Cancer Index is a test that looks at your initial tumor sample to provide two pieces of information:</p>
                <ol>
                  <li>
                    Whether an additional 5 years of anti-estrogen therapy is likely to help reduce your risk of the cancer returning (recurrence) as metastatic
                    disease
                  </li>
                  <li>
                    Your individual risk of metastatic recurrence between 5 and 10 years after your initial diagnosis, which is presented as a percentage risk
                    [e.g., 3.2% or 7.5%]
                  </li>
                </ol>
              </CollapsibleCard>
              {/* <!-- 2 --> */}
              <CollapsibleCard eventKey='headingTwo' title='How can Breast Cancer Index help my oncologist and me with treatment decisions?'>
                <p>
                  Not every woman treated with anti-estrogen therapy will have the same level of benefit from five additional years of therapy. In clinical
                  trials, approximately three to five out of every 100 women treated with 5 additional years of therapy benefited from extending anti-estrogen
                  therapy.<sup>1-7</sup>
                </p>
                <p>
                  Some women experience significant side effects from treatment and exposure to long term risks from these medications. Breast Cancer Index
                  helps identify women that may be more likely to benefit from extending anti-estrogen therapy beyond year 5, and also helps to identify women
                  who are not likely to benefit from extended therapy, and thus may be spared the side effects from continuing therapy with these drugs.
                  <sup>13, 14</sup>
                </p>
                <p>
                  Your oncology team will use Breast Cancer Index in combination with additional clinical information to help determine your treatment plan.
                </p>
              </CollapsibleCard>
              {/* <!-- 3 --> */}
              <CollapsibleCard
                eventKey='collapseThree'
                title='I had a genomic test to provide information about my likelihood of benefitting
                    from chemotherapy. Isn’t Breast Cancer Index the same thing?'
              >
                <p>
                  No. Oncotype DX predicts likely benefit from chemotherapy. Breast Cancer Index is a test that predicts whether you are likely to benefit from
                  an additional 5 years of anti-estrogen medication.
                </p>
              </CollapsibleCard>
              {/* <!-- 4 --> */}
              <CollapsibleCard eventKey='collapseFour' title='Are there other tests like Breast Cancer Index available?'>
                <p>
                  Breast Cancer Index is THE ONLY commercially available test validated to predict the likelihood of benefit from anti-estrogen therapy beyond 5
                  years.
                </p>
              </CollapsibleCard>
              {/* <!-- 5 --> */}
              <CollapsibleCard eventKey='collapseFive' title='Am I eligible for Breast Cancer Index?'>
                <p>Am I eligible for Breast Cancer Index?</p>
                <ol>
                  <li>You are a woman who has been diagnosed with stage I to IIIa breast cancer.</li>
                  <li>Your breast cancer is estrogen receptor–positive (ER+) and/or progesterone receptor–positive (PR+).</li>
                  <li>You have been prescribed anti-estrogen (also called hormonal or endocrine) therapy.</li>
                  <li>You are breast-cancer free.</li>
                </ol>
                <p>The decision whether or not to order Breast Cancer Index is one that you and your oncologist will make together.</p>
                <p>
                  <button class='btn btn-link' onClick={() => handleShow('patient')}>
                    Download the conversation guide
                  </button>{' '}
                  for a printable list of questions you can take directly to your next appointment.
                </p>
              </CollapsibleCard>
              {/* <!-- 6 --> */}
              <CollapsibleCard eventKey='collapseSix' title='Do I need to go to the hospital to get a Breast Cancer Index test?'>
                <p>
                  Breast Cancer Index testing does not require any additional procedures. After your doctor orders Breast Cancer Index, Biotheranostics reaches
                  out to the hospital to collect the tumor specimen that was taken during your biopsy or surgery. No additional procedure (no new sample) is
                  required for Breast Cancer Index testing. After Biotheranostics receives the original sample, a test result is sent to your oncologist within
                  10 business days.
                </p>
              </CollapsibleCard>
              {/* <!-- 7 --> */}
              <CollapsibleCard eventKey='collapseSeven' title='When does it make sense to ask my doctor about Breast Cancer Index?'>
                <p>
                  Talk to your oncologist when you have a question about how long you will need to take anti- estrogen therapy and when Breast Cancer Index is
                  right for you.
                </p>
              </CollapsibleCard>
              {/* <!-- 8 --> */}
              <CollapsibleCard eventKey='collapseEight' title='When is Breast Cancer Index ordered?'>
                <p>Your physician may order the test:</p>
                <ol>
                  <li>
                    As you approach the fifth year after diagnosis to further inform whether to end anti- estrogen therapy or extend your treatment for an
                    additional 5 years.
                  </li>
                  <li>After 5 years post-diagnosis to provide additional information about continuing or restarting your anti-estrogen therapy.</li>
                </ol>
              </CollapsibleCard>
              {/* <!-- 9 --> */}
              <CollapsibleCard eventKey='collapseNine' title='Will my insurance cover Breast Cancer Index?'>
                <p>
                  <b>For Medicare Patients</b>
                </p>
                <p>
                  Breast Cancer Index is covered by Medicare when certain criteria are met. Detailed information on the criteria under the Local Coverage
                  Determination can be found here:{' '}
                  <a
                    href='https://www.cms.gov/medicare-coverage-database/details/lcd-details.aspx?LCDId=37822&amp;ContrId=360&amp;ver=14&amp;ContrVer=1&amp;CntrctrSelected=365*1&amp;Cntrctr=365&amp;name=&amp;DocType=4%7C5&amp;LCntrctr=365*1&amp;bc=AAAAAACAIAAA'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    CMS.gov
                  </a>{' '}
                  or by contacting our Patient Services Team <a href='/contact-patients'>Contact & Support</a> with specific questions.
                </p>
                <p>
                  <b>For Commercial Insurance Patients</b>
                </p>
                <p>
                  Your commercial insurance plan may cover Breast Cancer Index. A member of our Patient Services Team will contact you to discuss the billing
                  process and what to expect in order to move forward with testing.
                </p>
              </CollapsibleCard>
              {/* </div><!-- 10 --> */}
            </Accordion>
          </div>
        </div>

        <div className='row'>
          <div className='col-12 py-5'>
            <h4 className='font1 clr c1'>Still Have Questions?</h4>
            <p>
              Our Patient Services Team is here to support you. For questions or concerns, feel free to give us a call or send us an email and we’ll be happy to
              assist you.
            </p>
            <p>
              <b>Contact the Patient Services Team:</b> <br />
              Call: (844) 319 – 8111
            </p>
            <p>
              <b>Email:</b> <a href='mailto:patientservices@biotheranostics.com'>patientservices@biotheranostics.com</a> <br />
              Hours: 6:00am – 4:00pm (PST)
            </p>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter
        image='/img-hero5.png'
        icons={[
          {
            href: '/explore-the-report-patients',
            img: '/icon-clover.svg',
            alt: ' Explore the Test Report',
            text1: 'Explore the',
            text2: 'Test Report',
          },
          {
            modal: true,
            //href: "http://division2-wmh-bci-website-prod.s3-website-us-east-1.amazonaws.com/PatientEducation.pdf",
            img: '/icon-bubble.svg',
            alt: ' Explore the Test Report',
            text1: 'Download The',
            text2: 'Conversation Guide',
          },
        ]}
        type='hasHero'
      />

      <Footer
        showReferences={[true, false]}
        showTopLinks={true}
        topLinks={[
          { link: '/resources-providers', label: 'For Providers' },
          { link: '/know-someone-with-breast-cancer', label: 'For Supporters' },
        ]}
        showSecondaryLinks={true}
        secondaryLinks={[
          { link: '/FAQs', label: 'FAQs' },
          { link: '/contact-patients', label: 'Contact & Support' },
        ]}
        type='patient'
      />
    </div>
  );
};

export default FAQs;
