//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop,
    SEO
} from "../../lib";

import ReactGA from 'react-ga';

const OrderTestPatient = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='Ordering Breast Cancer Index Test' description='Breast Cancer Index Testing is available in all 50 states. Click here to order a test directly from Breast Cancer Index or request an order form.' hideSuffix={true}/>
      <Navbar type="patients" />
      
      {/* <!-- IMAGE HEADER --> */}
      <header className="page-header">
        <div className="container-fluid bgc g3 round">
          <div className="clover-container no-clover">
            <div className="copy headline">
              <h1>
                How Do I Get A Breast Cancer Index<sup>&reg;</sup> Test?
              </h1>
              <p className="mt-3">
                By providing us with your information below, you are taking the first step in obtaining information to help start the conversation with your care team. 
                Depending on your stage of care, Biotheranostics, Inc. will send you valuable information about Breast Cancer Index, tools to help manage side effects and resources throughout your care.
              </p>
            </div>
            <div className="photo ordertest">
              <img src="/img-hero-order-header2.png" alt="" className="w-100" />
            </div>
            {/* <div className="photo-clover">
                <img src="/clover-closed.svg" alt="" className="w-100" />
            </div> */}
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      {/* <!-- CONTENT AREA --> */}
      <div className="container content-page">
        <div className="row w-75 mx-auto">
          <div className="col-12 pt-5 text-center">
            <h3 className="pb-2">
              Not Another Minute Without Information To Take The Next Step
            </h3>
            <p className="py-2 text-center">
              Although Breast Cancer Index must be ordered by a licensed healthcare provider, deciding to
              get the test is a joint decision between you and your care team. Take charge of your care
              today.
            </p>
            <p>
              By submitting your information below, you will be able to immediately download valuable tools
              to help you start the conversation today!
            </p>
          </div>
        </div>
        

        <div>
        <iframe
              src="https://biotheranostics1.formstack.com/forms/bci_how_to_get_the_test"
              title="BCI How to Get the Test"
              width="100%"
              height="900"
              style={{ boder: '1px solid #fff', borderWidth: '0' }} />
        </div>
      </div>

      <BackToTop />

      <SubFooter 
        icons={[
          {
            href: "/contact-patients",
            img: "/icon-person.svg",
            alt: " Contact & Support",
            text1: "Contact",
            text2: "& Support"
          },{
            modal: true,
            img: "/icon-bubble.svg",
            alt: " Explore the Test Report",
            text1: "Download The",
            text2: "Conversation Guide",
          }
        ]}  />
        
      <Footer
        showTopLinks={true} topLinks={[
          { link: "/", label: "For Patients" }
        ]}
        showSecondaryLinks={true} secondaryLinks={[
          { link: "/order-test", label: "Order The Test" },
          { link: "/contact-providers", label: "Contact" }
        ]}
        type='provider'
      />

    </div>
  );
}

export default OrderTestPatient;
