//Copyright Breast Cancer Index || 2021
//Inner navbar

import React from 'react';

import './SecondaryNavbar.scss';

import {
  Nav,
  Navbar,
  NavItem,
} from 'react-bootstrap';

let searchString = '';

function FilterMultiple (s) {
  let tss = searchString;
  console.log(s + " is what we're looking for");
  if(s == ''){
    searchString = '';
    tss = '';
  }
  else if(s != '' && searchString == ''){
    searchString = s;
    tss = searchString;
  }else if(s != '' && searchString != ''){
    tss = searchString + ', ' + s; 
    searchString = tss;
  }
}

function SecondaryNavbar (props) {
  let f = props.filter || [];
  return (

  <Navbar expand="lg" className="filter">
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mx-auto">
        <div className="endcap">
          <Nav.Link className="label">Filter By <span> Topic</span>:</Nav.Link>
        </div>
        <Nav.Link className={"nav-link ul" + (f.includes('about the test') ? ' active' : '')} onClick={() => {props.onFiltered('about the test')}}>About the <br className="d-none d-lg-block" />Test</Nav.Link>
        <Nav.Link className={"nav-link ul" + (f.includes('bci clinical evidence') ? ' active' : '')} onClick={() => {props.onFiltered('bci clinical evidence')}}>BCI Clinical <br className="d-none d-lg-block" />Evidence</Nav.Link>
        <Nav.Link className={"nav-link ul" + (f.includes('clinical insights') ? ' active' : '')} onClick={() => {props.onFiltered('clinical insights')}}>Clinical <br className="d-none d-lg-block" />Insights</Nav.Link>
        <Nav.Link className={"nav-link ul" + (f.includes('side effects') ? ' active' : '')} onClick={() => {props.onFiltered('side effects')}}>Side <br className="d-none d-lg-block" />Effects</Nav.Link>
        <Nav.Link className={"nav-link ul" + (f.includes('ob/gyn resources') ? ' active' : '')} onClick={() => {props.onFiltered('ob/gyn resources')}}>OB/GYN <br className="d-none d-lg-block" />Resources</Nav.Link>
        <div className="endcap">
          <Nav.Link className="show-all d-none d-lg-flex float-right" onClick={() => {props.onFiltered(null)}}>Show All <span className="x-close">X</span></Nav.Link>
        </div>
        <div className="buttons d-flex d-lg-none mx-auto mt-4">
          <button className="btn btn-secondary" onClick={() => {props.onFiltered(null)}}>Clear</button>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="btn btn-primary">Done</Navbar.Toggle>
        </div>
      </Nav>
    </Navbar.Collapse>
  </Navbar>

  );
}

export default SecondaryNavbar;