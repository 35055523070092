//Copyright Breast Cancer Index || 2021

import React from "react";

import { Navbar, SubFooter, Footer, BackToTop, FloatingPetal } from "../../lib";

import ReactGA from "react-ga";

const Article12 = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const smallBold = {
    fontSize: "11px",
  };

  return (
    <div>
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc hcp-gradient round">
          <div className="clover-container">
            <div className="copy headline middle">
              <h1 className="mt-0 color-hologic-blue">
                Breast Cancer Index<sup>&reg;</sup> Included in Guidelines
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />

      <div className="container content-page">
        <div className="row w-90 mx-auto">
          <div className="col-12 py-5 text-center">
            <h4 className="pb-2">
				Breast Cancer Index (BCI) is the only genomic test recognized by the NCCN and the ASCO<sup>®</sup> Clinical Practice Guideline to predict which patients are likely to benefit from extension of adjuvant anti-estrogen therapy beyond 5 years.<sup>1,2</sup>
            </h4>
            <p>
				The NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup>®</sup>) and the ASCO<sup>®</sup> Clinical Practice Guideline identify BCI as the only genomic test to predict which patients are likely to
				benefit from extension of adjuvant anti-estrogen therapy beyond 5 years.The inclusion of BCI in
				both guidelines supports its routine clinical use in node-negative and node-positive (1-3 nodes)
				patients across anti-estrogen therapies, including tamoxifen and aromatase inhibitors, based on the
				test’s comprehensive predictive evidence.
            </p>
            <div className="col-12 text-center mt-5">
              <p>
                <a href="/predictive-power-of-bci">
                  Read about the comprehensive predictive evidence supporting
                  the NCCN inclusion
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="bgc g6 my-4 py-5 px-3  bracket-box">
            <div className="curly reverse mr-3 d-none d-sm-block">
              <img src="/shape-curly.svg" alt="" className="w-100" />
            </div>
			<div className="column w-90 max-700 mx-auto pb-1">
				<h3 className="text-left font1 clr c1"><b>Guideline Inclusion</b></h3>
				<div>NCCN BINV-N (4 of 5)<sup>1</sup>: Treatment Implications of BCI</div>
				<ul className="col-12">
					<li className="py-0">
						In secondary analyses of the MA.17, Trans-aTTom and IDEAL trials, patients with HR-positive, T1-T3, pN0 or pN+ who had a BCI (H/I) high-demonstrated significant improvements in DFS when adjuvant endocrine therapy was extended, compared to the control arm
					</li>
					<li className="py-0">
						In contrast, BCI (H/I) low patients derived no benefit from extended adjuvant therapy
					</li>
				</ul>
				<div>Biomarkers for Adjuvant Endocrine and Chemotherapy in Early-Stage Breast Cancer<sup>2</sup>:</div>
				<ul>
					<li className="py-0">
            The ASCO<sup>®</sup> Clinical Practice Guideline (Guideline Update 1.24):
						If a patient has node-negative or node-positive
						breast cancer with 1-3 positive nodes and&nbsp;has
						been treated with 5 years of primary endocrine
						therapy without evidence of recurrence, the
						clinician may offer the BCI test to help guide
						decisions about extended endocrine therapy
						with either tamoxifen, an AI, or a sequence of
						tamoxifen followed by&nbsp;AI
					</li>
				</ul>
            </div>
            <div className="curly ml-3 d-none d-sm-block">
              <img src="/shape-curly.svg" alt="" className="w-100" />
            </div>
          </div>
        </div>
      </div>

      <div className="container content-page">
        <div className="border-bottom"></div>

        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <p className="ref">
				<strong>References:</strong>{' '}
				<strong>1.&nbsp;</strong>Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup>®</sup>) for Breast Cancer V.2.2023. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed February 10, 2023. To view the most recent and complete version of the guideline, go online to NCCN.org. NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.{' '}
				<strong>2.&nbsp;</strong>Andre F et al. J Clin Oncol. Published online April 19, 2022. DOI: 10.1200/JCO.22.00069​. Referenced with permission from the American Society of Clinical Oncology (ASCO<sup>®</sup>) Clinical Practice Guideline Biomarkers for Adjuvant Endocrine and Chemotherapy in Early-Stage Breast Cancer. © American Society of Clinical Oncology. 2024. All rights reserved. To view the most recent and complete version of the guideline, go online to <a href="https://ascopubs.org/jco/special/guidelines" target="_blank">https://ascopubs.org/jco/special/guidelines</a> [ascopubs.org].  ASCO<sup>®</sup> Clinical Practice Guideline makes no warranties of any kind whatsoever regarding their content, use of application and disclaims any responsibility for their application or use in any way.{' '}
            </p>
            <a href="/resources-providers" className="btn btn-secondary hcp mt-5">
              Back To All
            </a>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter
        site="hcp"
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard",
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: "Resources For Providers",
            text1: "Resources",
            text2: "For Providers",
          },
        ]}
        disclaimer=""
      />

      <Footer
        showTopLinks={true}
        topLinks={[
          { link: "/", label: "For Patients" },
          { link: "/", label: "For Supporters" },
        ]}
        showSecondaryLinks={true}
        secondaryLinks={[
          { link: "/resources-providers", label: "Resources" },
          {
            link: "/explore-the-report-providers",
            label: "Explore The Report",
          },
          { link: "/contact-providers", label: "Contact" },
          { link: "/order-test", label: "Order The Test" },
        ]}
      />
    </div>
  );
};

export default Article12;
