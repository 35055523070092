//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import { Navbar, SubFooter, Footer, BackToTop, FloatingPetal, SEO } from "../../lib";

import ReactGA from 'react-ga';

function PressReleasePage1211() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='The Power to Predict' description='Biotheranostics and the Not Another Minute Campaign, exploring how BCI is revolutionary for early-stage, hormone-receptor-positive breast cancer patients.' />
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc g3 round">
          <div className="clover-container">
            <div className="copy headline">
              <h1 className="mt-0 color-hologic-blue">
                The Power <br />To Predict
              </h1>
            </div>
            <div className="photo-clover">                          
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />
      

      <div className="container content-page">
      <div className="row w-90 mx-auto my-5">
          <div className="col-12 pt-5 pb-2 text-center">
            <h3 className="pb-2">
            Biotheranostics Launches Not Another Minute Campaign for its Breast Cancer Index<sup>&reg;</sup> Test
            </h3>
            <h6 className="font1 clr c10 mt-5">
              SAN DIEGO, Calif., December 11, 2019 (GLOBAL NEWSWIRE)
            </h6>
          </div>
        </div>       
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">   
          <div className="col-12">
            <p>San Diego, CA, Dec. 11, 2019 (GLOBE NEWSWIRE) -- Biotheranostics, Inc., announces the launch of its <b>Not Another Minute campaign</b>, revealing how Breast Cancer Index (BCI) is changing the game for early-stage, hormone receptor-positive (HR+) breast cancer patients. At the 42nd annual San Antonio Breast Cancer Symposium (SABCS) this week, thousands of the nation’s top oncologists, surgeons, and patient advocates will experience the new campaign which focuses on individualized care, patient education, and empowerment.</p>
            <p><b>Not Another Minute</b> encourages physicians, patients, and caregivers to make informed, individualized decisions about extending anti-estrogen treatment after five years.  While a small percentage of women diagnosed with HR+, early-stage breast cancer have improved outcomes with longer treatment, nearly 95% of patients do not benefit from continuing anti-estrogen therapy beyond five years, based on results from published extended endocrine therapy trials.1-6 The campaign reinforces that 100% of women deserve to know what is right for them regarding extension of endocrine therapy. Breast Cancer Index is the only commercially-available test that predicts whether a woman is likely to benefit from continuing her anti-estrogen therapy to ten years.</p>
            <p>For the thousands of oncologists across the US who have already used Breast Cancer Index to help individualize their patients’ anti-estrogen treatment decisions, Biotheranostics is pleased to introduce a newly designed test report intended to facilitate patient and physician understanding and discussions for joint decision-making.</p>
            <p>Lisa Whitmyer, Biotheranostics’ VP of Marketing, shares, “As a breast cancer survivor myself, taking anti-estrogen therapy can be a life-altering five years.  The <b>Not Another Minute</b> campaign empowers women to understand whether tolerating an additional five years of therapy will benefit them. We were grateful to be able to incorporate feedback from patients and their providers as we redesigned the report to present the Breast Cancer Index results in a clearer and more meaningful way.  We are pleased that so many women have already benefited from including the test in their decision making with their provider.”</p>
            <p>Don Hardison, Biotheranostics’ President and CEO adds, “We are thrilled that the reception of the new campaign has been so positive.  <b>Not another Minute</b> of taking additional treatment that may not benefit her has resonated for patients who feel the impact of taking a daily pill for the last five years, and for providers, knowing if treatment is benefiting their patient gives them the confidence when prescribing an additional five years of anti-estrogen therapy. We are fortunate to offer the only biomarker that predicts the benefit of extended endocrine therapy.”</p>
            <p>References: 1. Davies C, et al. Lancet Oncol. 2013;381: 805-816. 2. Gray R et al. J Clin Oncol 2013; 31: (suppl; abstr 5). 3. Jakesz R, et al. J Natl Cancer Inst. 2007;99: 1845-1853. 4. Goss PE, et al. J Natl Cancer Inst. 2005;97: 1262-1271. 5. Goss PE et al. N Engl J Med. 2003;349. 6. Mamounas EP et al. NSABP B-42. S1-05. SABCS 2016 Dec 7, 2016</p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">
          <div className="col-12">
            <h4>About Breast Cancer Index<sup className="sup-sm">TM</sup></h4>
            <p>Breast Cancer Index is a molecular, gene expression-based test uniquely positioned to provide information to help physicians individualize treatment decisions for patients with early stage, ER+ breast cancer. This breakthrough test helps oncologists and patients navigate the difficult trade-off between taking steps to prevent recurrence of their disease and facing significant side effects and safety challenges related to unnecessary treatment. Breast Cancer Index holds guidelines designation from the American Joint Committee on Cancer for cancer staging based on molecular profile; ASCO, NCCN, European Group on Tumor Markers (EGTM), and St. Gallen to inform the chemotherapy decision; and ASCO and EGTM to inform the extended endocrine treatment dilemma. It is the only validated, commercially available test that provides risk of overall and late distant recurrence and predicts the likelihood of benefit from extended endocrine therapy. For more information, visit <a href="https://www.breastcancerindex.com" target="_blank">www.breastcancerindex.com</a>.</p>
            <h4>About Biotheranostics</h4>
            <p>
              Biotheranostics, Inc. operates a CLIA-certified and CAP-accredited diagnostic laboratory in
              San Diego, California. Biotheranostics, Inc., is a leading healthcare provider in the
              oncology field, assisting physicians in the treatment of cancer patients. Its suite of
              proprietary molecular diagnostic tests provides important information to physicians to
              tailor treatment to individual patients. The company’s <a href="https://breastcancerindex.com" target="_blank">Breast Cancer Index</a><sup>&reg;</sup> and <a href="https://www.cancertypeid.com/" target="_blank">CancerTYPE ID</a><sup>&reg;</sup> tests address a variety of unmet medical
              needs in the management of cancer patients, and extensive clinical studies have confirmed
              the accuracy, clinical validity, clinical utility, and cost-effectiveness of the tests.
              Learn more at <a href="http://www.biotheranostics.com" target="_blank">www.biotheranostics.com</a>.</p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <a href="/resources-providers" className="btn btn-secondary hcp w-250">Back To All</a>
          </div>
        </div>
      </div>
      
      <BackToTop />

      <SubFooter 
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard"
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: " Resources for Providers",
            text1: "Resources",
            text2: "for Providers"
          }
        ]} />
      
      <Footer 
            showTopLinks={true} topLinks={[
              {link: "/", label: "For Patients"},
              {link: "/know-someone-with-breast-cancer", label: "For Supporters"}
            ]}
            showSecondaryLinks={true} secondaryLinks={[
              {link: "/providers-resources", label: "Resources"},
              {link: "/contact-providers", label: "Explore The Report"},
              {link: "/contact-providers", label: "Contact"},
              {link: "/order-test", label: "Order The Test"}
            ]} />

    </div>   
  );

}

export default PressReleasePage1211;
