//Copyright Breast Cancer Index || 2021
//Back to top component

import React from "react";
import './BackToTop.scss';

const backToTop = () => {

  window.scrollTo({top: 0, behavior: 'smooth'});

}

const BackToTop = () => (
  <div className="back-to-top" onClick={() => backToTop()}>
    <i className="fa fa-chevron-up"></i>
    Back to Top
  </div>
);

export default BackToTop;