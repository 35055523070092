//Copyright Breast Cancer Index || 2021
//supporting element for the accordion

import React from 'react';

import {
  Accordion,
  Card
} from 'react-bootstrap';

class CollapsibleCard extends React.Component {

  render () {
    const icon = this.props.collapsed ? "fa-chevron-right" : "fa-chevron-down";
    return (
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey={this.props.eventKey} onClick={() => this.props.handleUncollapse(this.props.eventKey)}>
          <i className={`fa ${icon}`} aria-hidden="true"></i>
          <div className="label" dangerouslySetInnerHTML={{ __html: this.props.title }} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={this.props.eventKey}>
          <Card.Body>
            { this.props.children }
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }

}

export default CollapsibleCard;