//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop
} from "../../lib";

import ReactGA from 'react-ga';

const ProviderThankYou = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <Navbar type="blank" />
      
      {/* <!-- TEMP LANDING --> */}
      <header className="page-header photo-grad">
        <div className="container-fluid bgc g3 round">
          <div className="clover-container">
            <div className="copy headline">
              <h1>
                Thank You                                
              </h1>
            </div>          
            <div className="photo-clover">
              <img src="/clover-closed.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      {/* <!-- CONTENT AREA --> */}
      <div className="container content-page">
        <div className="row">
          <div className="col-12 pt-5 border-bottom">
            <h2 className="font1 clr c1 py-2">
              Thank You
            </h2>
            <p>
            Thanks for contacting Biotheranostics. A member of our team will reach out very soon to follow up with your request.
            </p>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter 
        image=""
        icons={[
          {
            href: "/contact-providers",
            img: "/icon-bubble.svg",
            alt: " Contact Breast Cancer Index",
            text1: "Contact Breast",
            text2: "Cancer Index"
          },{
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard"
          }
        ]} />
        
      <Footer
        showTopLinks={true} topLinks={[
          { link: "/", label: "For Patients" }
        ]}
        showSecondaryLinks={true} secondaryLinks={[
          { link: "/order-test", label: "Order The Test" },
          { link: "/contact-providers", label: "Contact" }
        ]}
        type='provider'
      />

    </div>
  );
}

export default ProviderThankYou;
