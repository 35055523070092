//Copyright Breast Cancer Index || 2021
//Rounded footer

import React, { useState } from 'react';

import DownloadModal from '../DownloadModal';

import './SubFooter.scss';

const SubFooter = ({ icons, image, type, disclaimer, site }) => {
    const [show, setShow] = useState(false);
    const [mTitle, setTitleText] = useState('');
    const [mBody, setBodyText] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (type) => {
        if (type === 'patient') {
            setTitleText('Download the Conversation Guide');
            setBodyText('');
        } else if (type === 'provider') {
            setTitleText('Order Breast Cancer Index');
            setBodyText('Download the Conversation Guide');
        }
        setShow(true);
    };

    function processSubfooter(icons) {
        return icons.map((icon, idx) => {
            if (icon.modal) {
                return (
                    <>
                        <a key={idx} href={icon.href} className={`element count${icons.length}`} onClick={() => handleShow('patient')}>
                            <div className='shape'>
                                <div className={`icon ${icon.className ? icon.className : undefined}`}>
                                    <img src={icon.img} alt={icon.alt} />
                                </div>
                                <div className='label'>
                                    {icon.text1}
                                    <br />
                                    {icon.text2}
                                </div>
                            </div>
                        </a>
                        <DownloadModal show={show} title={mTitle} body={mBody} handleClose={handleClose} />
                    </>
                );
            } else {
                return (
                    <>
                        <a key={idx} target='blank' href={icon.href} className={`element count${icons.length}`}>
                            <div className='shape'>
                                <div className={`icon ${icon.className ? icon.className : undefined}`}>
                                    <img src={icon.img} alt={icon.alt} />
                                </div>
                                <div className='label'>
                                    {icon.text1}
                                    <br />
                                    {icon.text2}
                                </div>
                            </div>
                        </a>
                    </>
                );
            }
        });
    }

    function processDisclaimer(d) {
        if (d) {
            return (
                <div className='disclaimer'>
                    For full anti-estrogen prescribing information including side effects, go to: <nbsp />
                    <a href='https://medlineplus.gov/druginfo/meds/a682414.html' target='_blank'>
                        Tamoxifen
                    </a>{' '}
                    |{' '}
                    <a href='https://www.pharma.us.novartis.com/sites/www.pharma.us.novartis.com/files/Femara.pdf' target='_blank'>
                        Femara
                    </a>
                    <sup>&reg;</sup>; |{' '}
                    <a href='https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=acbfaaa9-503c-4691-9828-76a7146ed6de' target='_blank'>
                        Arimidex
                    </a>{' '}
                    |{' '}
                    <a href='http://labeling.pfizer.com/ShowLabeling.aspx?id=523' target='_blank'>
                        Aromasin
                    </a>
                </div>
            );
        }
    }

    return (
        <section className={'d-flex ' + type}>
            <div className={`secondary-footer bgc ${site && site === 'hcp' ? 'hcp-gradient' : 'g3'}`}>
                <div className={'photo-container ' + (image ? '' : 'd-none')}>
                    <img src={image} alt='' />
                </div>
                <div className='container mx-auto'>
                    <div className='row'>
                        <div className='col-12 mx-auto'>
                            <div className='shape-container'>
                                {processSubfooter(icons)}
                                {processDisclaimer(disclaimer)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SubFooter;
