//Copyright Breast Cancer Index || 2021

import React from 'react';
import ReactGA from 'react-ga';
import {
  BackToTop,
  FloatingPetal, Footer, Navbar,
  SubFooter
} from "../../lib";



const Article1 = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const smallBold = {
    fontSize: '11px',
  }

  return (
    <div>
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc hcp-gradient round">
          <div className="clover-container">
            <div className="copy headline middle">
              <h1 className="mt-0 color-hologic-blue">
                The Most Validated Assay For Prediction of Benefit from Extended Endocrine Therapy
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />

      <div className="container content-page">
        <div className="row w-90 mx-auto">
          <div className="col-12 py-5 text-center">
            <h3 className="pb-2">
              The Predictive Power Of Breast Cancer Index<sup>&reg;</sup>
            </h3>
            <h4 className="font1 clr c1">
              Breast Cancer Index Has Demonstrated Consistent Predictive Evidence Across 5 Studies and Over 4,500 Patients<sup>1-5</sup>
            </h4>
            <p>
              Data supports comprehensive utility in pre- and post-menopausal
              women with various treatment histories and clinical features
            </p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto">
          <div className="pt-5 pb-3 text-center w-100">
            <img
              src="/article1-chart-main-c_redraw.svg"
              alt=""
              className="w-100"
            />
          </div>
        </div>
        <div className="col-12 pb-5 text-center small">
          <p>
            Studies included patients with node-positive and node-negative
            disease, encompassing tumor sizes T1-3 and grades 1-3.
          </p>
          <p><sup>&dagger;</sup>For patients disease-free at year 5</p>
        </div>

        <div className="border-bottom"></div>
      </div>

      {/* <!--QUARTER ROUND --> */}
      <section className="qtr-round-article my-5">
        <div className="container content">
          <div className="row">
            <div className="col-10 offset-1 col-sm-11 pr-0 pr-sm-5">
              <h2 className="pr-0 pr-sm-5 hanging-quote">
              The HOXB13/IL17BR (BCI) ratio remains the only biomarker that has demonstrated significant
              treatment interaction for prediction of
              extended endocrine therapy benefit, earning guideline
              inclusion earlier this&nbsp;year.” <br /> <br />
              &mdash; Mark Pegram, MD
              </h2>
            </div>
          </div>
        </div>
        <div className="round-bg bgc hcp-gradient horizontal"></div>
      </section>

      <div className="container content-page">
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <h4 className="font1 clr c1 mx-auto mt-4 mb-5">
              Read the Full Breast Cancer Index Predictive Validation Studies
            </h4>
            <p>
              1.{" "}
              <a
                href="https://clincancerres.aacrjournals.org/content/19/15/4196.long"
                target="_blank"
              >
                Breast Cancer Index Identifies Early-Stage Estrogen
                Receptor–Positive Breast Cancer Patients at Risk for Early- and
                Late-Distant Recurrence »
              </a>
            </p>
            <p>
              2.{" "}
              <a
                href="https://academic.oup.com/jnci/article/105/14/1036/964978"
                target="_blank"
              >
                Prediction of Late Disease Recurrence and Extended Adjuvant
                Letrozole Benefit by the HOXB13/IL17BR Biomarker »
              </a>
            </p>
            <p>
              3.{" "}
              <a
                href="https://www.annalsofoncology.org/article/S0923-7534(20)32589-8/fulltext"
                target="_blank"
              >
                Breast Cancer Index and Prediction of Benefit From Extended
                Endocrine Therapy in Breast Cancer Patients Treated in the
                Adjuvant Tamoxifen—To Offer More? (aTTom) Trial »
              </a>
            </p>
            <p>
              4.{" "}
              <a
                href="https://clincancerres.aacrjournals.org/content/early/2020/10/27/1078-0432.CCR-20-2737"
                target="_blank"
              >
                Breast Cancer Index predicts extended endocrine benefit to
                individualize selection of HR+ early stage breast cancer
                patients for 10 years of endocrine therapy »
              </a>
            </p>

            <p>
              5.{" "}
              <a
                href="https://ascopubs.org/doi/abs/10.1200/JCO.2021.39.15_suppl.501"
                target="_blank"
              >
                Breast Cancer Index (BCI) and prediction of benefit from extended aromatase inhibitor (AI) therapy (tx) in HR+ breast cancer: NRG oncology/NSABP B-42 »
              </a>
            </p>

          </div>
        </div>
        <div className="border-bottom"></div>

        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <p className="ref">
				<strong>References:</strong>{' '}
				<strong>1.&nbsp;</strong>Zhang Y, et al. Clin Cancer Res. 2013;19(15):4196-4205.{' '}
				<strong>2.&nbsp;</strong>Sgroi DC, et al. J Natl Cancer Inst. 2013;105(14):1036-1042.{' '}
				<strong>3.&nbsp;</strong>Bartlett JMS, et al. Ann Oncol. 2019;300(11):1776-1783.{' '}
				<strong>4.&nbsp;</strong>Noordhoek I, et al. Clin Cancer Res. 2021;27(1):311-319.{' '}
				<strong>5.&nbsp;</strong>Mamounas EP, et al. Abstract 501; ASCO<sup>®</sup> Clinical Practice Guideline June 2021.{' '}
				<strong>6.&nbsp;</strong>Simon RM, et al. J Natl Cancer Inst. 2009;101(21):1446-1452.
            </p>
            <a href="/resources-providers" className="btn btn-secondary hcp mt-5">
              Back To All
            </a>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter
        site="hcp"
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard",
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: "Resources For Providers",
            text1: "Resources",
            text2: "For Providers",
          },
        ]}
        disclaimer=""
      />

      <Footer
        showTopLinks={true}
        topLinks={[
          { link: "/", label: "For Patients" },
          { link: "/know-someone-with-breast-cancer", label: "For Supporters" },
        ]}
        showSecondaryLinks={true}
        secondaryLinks={[
          { link: "/resources-providers", label: "Resources" },
          { link: "/contact-providers", label: "Explore The Report" },
          { link: "/contact-providers", label: "Contact" },
          { link: "/order-test", label: "Order The Test" },
        ]}
      />
    </div>
  );
}

export default Article1;
