//Copyright Breast Cancer Index || 2021
//Main Page

import React, { useState } from 'react';

import { Modal, Button } from 'react-bootstrap';

import ExploreModals from './ExploreModals';

import { DownloadModal } from '../../lib';

import modals from '../../util/explore-report-patients.json';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop,
    FloatingPetal,
    SEO
} from "../../lib";

import ReactGA from 'react-ga';

function ExploreTheReportPatients() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  let modalNum = 0;
  let activeModal = "";
  const [front, setFront] = useState(true);
  const [modal, setModal] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [show, setShow] = useState(false);

  // Sets the modal to the correct information from the JSON
  const changeModal = (ms, e) => {
    if(ms != modalNum){
      modalNum = ms;
      activeModal = modals.find(e => e.id === modalNum);
      if(ms === "2yes" || ms === '2no') {setModal('2')} else {setModal(ms)};
	  setModalTitle(activeModal.title);
      setModalBody(activeModal.body);
      setShow(!show);
      e.target.classList.add('active');
    }
  }
  const handleClose = () => {
    setShow(false);
    let el = document.querySelector(".btn-popmodal.active");
    if (el !== null) el.classList.remove("active");
  };

  const [downloadModal, setDownloadModal] = useState(false)

  return (
    <>
      <SEO title='BCI Testing Report' description='Get a live example of the Breast Cancer Index Report to see what you can expect from our testing results. ' />
      <Navbar type="patients" />
      {/* <!-- INTERACTIVE HEADER --> */}
      <header className="page-header interactive">
        <div className="container-fluid w-100 bgc g3 not-round interactive">
          <div className="container interactive-content">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3 pt-5">
                  <h2 className="mb-4">  
                    Not Another <br />Minute <br />Not Knowing
                  </h2>
                  <p>
                  Breast cancer is complicated enough. Your doctor will receive your Breast Cancer Index<sup>&reg;</sup> results in one clear and easy-to-understand report. Breast Cancer Index<sup>&reg;</sup> provides 2 pieces of important information, which along with other clinical information, helps you and your doctor make a decision about the appropriate length of anti-estrogen treatment for you. 
                  </p>
                  <p className="highlight">
				  	Click the blue number tabs to explore the report. <br />
					Use the arrows below the report to switch between a YES result and a NO result.
                  </p>
              </div>
              <div className="col-12 col-md-8 col-lg-9 py-5">
                <div className="report-container px-4 px-sm-0">
                  <div className={"report front" + (front ? "" : " d-none")} >
				  	<div className="verticle-hide">
                      <button onClick={(e) => {changeModal("1", e)}} className="btn btn-popmodal b1">1</button>
                      <button onClick={(e) => {changeModal("2yes", e)}} className="btn btn-popmodal b2">2</button>
                      <button onClick={(e) => {changeModal("3a", e)}} className="btn btn-popmodal b3a">3<span>a</span></button>
					  <button onClick={(e) => {changeModal("3b", e)}} className="btn btn-popmodal b3b">3<span>b</span></button>
                    </div>

                    <img src="/report-yes.jpg" alt="Report" className="w-100" />
                    
                    <button className="flip-report-btn" onClick={() => {setFront(false)}}>
                      <div className="controls mt-4">
                        <p className="instruction mb-0">
                          Click to View a
                        </p>
                        <p className="side">
						  NO Result
                        </p>
                      </div>
                    </button>

                  </div>
                  <div className={"report back" + (!front ? "" : " d-none")} >
                    <div className="verticle-hide">
					  <button onClick={(e) => {changeModal("1", e)}} className="btn btn-popmodal b1">1</button>
                      <button onClick={(e) => {changeModal("2no", e)}} className="btn btn-popmodal b2">2</button>
                      <button onClick={(e) => {changeModal("3", e)}} className="btn btn-popmodal b3">3</button>
                    </div>
                    
                    <img src="/report-no.jpg" alt="Report" className="w-100" />

                    <button className="flip-report-btn" onClick={() => {setFront(true)}}>
                      <div className="controls mt-4">
                        <p className="instruction mb-0">
                          Click to View a
                        </p>
                        <p className="side">
                          YES Result
                        </p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="patient"/>

      {/* <!-- HCP CONTENT AREA --> */}
      <div className="container hcp-content pb-xl-5">
        <div className="row">
          <div className="col-12 pt-5">
            <h2 className="mb-4 px-5 text-center">
              Regardless of your Breast Cancer Index results, 100% of women deserve to know what is right for them.
            </h2>
            <h3 className="mb-4 px-5 text-center font1 clr c1">
			  Talk to your oncologist about getting a Breast Cancer Index test today.
            </h3>
            <p className="text-center m-5">
              <a className="btn btn-primary" onClick={()=> setDownloadModal(true)}>Download The Conversation Guide</a>
            </p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row pb-xl-5">
          <div className="col">
            <p className="text-center ref m-5">
				<b>References:</b>{' '}
				<b>1.&nbsp;</b>Sgroi DC, et al. J Natl Cancer Inst. 2013;105(14):1036-1042.{' '}
				<b>2.&nbsp;</b>Bartlett JMS, et al. Clin Cancer Res. DOI: 10.1158/1078-0432.CCR-21-3385. 2022{' '}
				<b>3.&nbsp;</b>Noordhoek I, et al. Clin Cancer Res. 2021;27(1):311-319.
            </p>
          </div>
        </div>
      </div>
      <BackToTop />
      <SubFooter 
          image="/img-hero6.png"
          icons={[
            {
              modal: true,
              img: "/icon-bubble.svg",
              alt: " Download The Conversation Guide",
              text1: "Download The",
              text2: "Conversation Guide",
              className: "bubble"
            }
          ]} 
          type='hasHero'/>
          
      <Footer
        showTopLinks={true} topLinks={[
          {link: "/", label: "For Patients"},
          {link: "/know-someone-with-breast-cancer", label: "For Supporters"}
        ]}
        showSecondaryLinks={true} secondaryLinks={[
          {link: "/order-test", label: "Order The Test"},
          {link: "/contact-patients", label: "Contact"}
        ]} />

      <DownloadModal show={downloadModal} title={"Download the Conversation Guide"} handleClose={()=>setDownloadModal(false)} />
      {/* <!-- Interactive Header Modals --> */}
      <Modal className="fade" show={show} onHide={handleClose}>
        <Modal.Header>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center mb-3">
					<div className="btn btn-popmodal count mb-0 mr-2">{modal}</div>
					<div className="title"><b>{modalTitle}</b></div>
				</div>
                <p dangerouslySetInnerHTML={{ __html: modalBody }}/>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ExploreTheReportPatients;
