//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import { Navbar, SubFooter, Footer, BackToTop, FloatingPetal, SEO } from "../../lib";

import ReactGA from 'react-ga';

function PressReleasePage1210() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='New Data Supporting Breast Cancer Index Testing' description='Biotheranostics bringing new data to support expanding the clinical utility of Breast Cancer Index at the 42nd Annual San Antonio Breast Cancer Symposium' hideSuffix={true}/>
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc g3 round">
          <div className="clover-container">
            <div className="copy headline">
              <h1 className="mt-0 color-hologic-blue">
                The Power <br />To Predict
              </h1>
            </div>
            <div className="photo-clover">                          
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />
      

      <div className="container content-page">
      <div className="row w-90 mx-auto my-5">
          <div className="col-12 pt-5 pb-2 text-center">
            <h3 className="pb-2">
              Biotheranostics to Present New Data Expanding the Clinical Utility of Breast Cancer Index<sup>&reg;</sup> at the 42nd Annual San Antonio Breast Cancer Symposium
            </h3>
            <h4 className="font1 clr c1">
              Expanded results of the pivotal Trans-aTTom study will be presented
            </h4>
            <h6 className="font1 clr c10 mt-5">
              SAN DIEGO, Calif., December 10, 2019 (GLOBE NEWSWIRE)
            </h6>
          </div>
        </div>       
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">   
          <div className="col-12">
            <p>San Diego, CA, Dec. 10, 2019 (GLOBE NEWSWIRE) -- Biotheranostics, Inc. announced today that several abstracts featuring updates on ongoing research and new data examining the Breast Cancer Index<sup>&reg;</sup> (BCI) will be presented at the 42nd annual San Antonio Breast Cancer Symposium.</p>
            <p>Data selected for presentation provide expanded analysis of the pivotal Trans-aTTom study, which evaluated the ability of Breast Cancer Index to predict benefit from ten versus five years of extended tamoxifen therapy. Additional presentations include an analysis of the prognostic performance of the Breast Cancer Index and unique tumor biology underlying invasive lobular breast cancer. The third abstract examines the potential mechanism of action of the Breast Cancer Index and prediction of endocrine response.</p>
            <p>Breast Cancer Index is the only test validated to predict which patients will benefit from endocrine therapy beyond 5 years, helping inform the decision of 5 vs. 10 years of endocrine therapy. No other genomic tests or clinicopathologic factors have demonstrated the ability to predict extended endocrine therapy benefit.</p>
            <p>The schedule and details of the Biotheranostics poster presentations are:</p>
            <ul>
              <li><p>Breast Cancer Index Predicts Benefit from Extended Endocrine Therapy in HR+ Breast Cancer. J. Bartlett, et al. Thursday, December 12, 2019, 7:00 AM - 9:00 AM - Poster Session 2, Hall 1</p></li>
              <li><p>Breast Cancer Index and Prognostic Performance in Invasive Lobular Breast Cancer. T. Sella, et al. Thursday, December 12, 2019, 5:00 PM - 7:00 PM - Poster Session 3, Hall 1</p></li>
              <li><p>Role of HOXB13 in Modulating Estrogen Signaling in Breast Cancer Cells. K. Treuner, et al. Saturday, December 14, 2019, 7:00 AM - 9:00 AM - Poster Session 6, Hall 1</p></li>
            </ul>
            <p>Catherine Schnabel, Ph.D., Chief Scientific Officer, Biotheranostics, said, “We are excited to share these new data that provide further support and insight into the unique ability of the Breast Cancer Index to identify patients with the endocrine-responsive disease. Biotheranostics remains committed to strengthening the clinical evidence supporting BCI towards individualizing care for each HR+ early-stage breast cancer patient and extension of endocrine therapy.”</p>
            <p>Don Hardison, Biotheranostics’ President, and CEO added, “We continue our commitment to furthering the validation of Breast Cancer Index for women with HR+ early-stage breast cancer.  The final results from the Trans-aTTom validation study further validation that Breast Cancer Index is the only predictive biomarker for extended endocrine therapy in early-stage HR+ breast cancer. We are committed to ensuring that every breast cancer patient and her physician have information to help determine the best approach to individualize treatment.”</p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">
          <div className="col-12">
            <h4>About Breast Cancer Index<sup className="sup-sm">TM</sup></h4>
            <p>Breast Cancer Index is a molecular, gene expression-based test uniquely positioned to provide information to help physicians individualize treatment decisions for patients with early stage, ER+ breast cancer. This breakthrough test helps oncologists and patients navigate the difficult trade-off between taking steps to prevent recurrence of their disease and facing significant side effects and safety challenges related to unnecessary treatment. Breast Cancer Index holds guidelines designation from the American Joint Committee on Cancer for cancer staging based on molecular profile; ASCO, NCCN, European Group on Tumor Markers (EGTM), and St. Gallen to inform the chemotherapy decision; and ASCO and EGTM to inform the extended endocrine treatment dilemma. It is the only validated, commercially available test that provides risk of overall and late distant recurrence and predicts the likelihood of benefit from extended endocrine therapy. For more information, visit <a href="https://www.breastcancerindex.com" target="_blank">www.breastcancerindex.com</a>.</p>
            <h4>About Biotheranostics</h4>
            <p>
              Biotheranostics, Inc. operates a CLIA-certified and CAP-accredited diagnostic laboratory in
              San Diego, California. Biotheranostics, Inc., is a leading healthcare provider in the
              oncology field, assisting physicians in the treatment of cancer patients. Its suite of
              proprietary molecular diagnostic tests provides important information to physicians to
              tailor treatment to individual patients. The company’s <a href="https://breastcancerindex.com" target="_blank">Breast Cancer Index</a><sup>&reg;</sup>; and <a href="https://www.cancertypeid.com/" target="_blank">CancerTYPE ID</a><sup>&reg;</sup> tests address a variety of unmet medical
              needs in the management of cancer patients, and extensive clinical studies have confirmed
              the accuracy, clinical validity, clinical utility, and cost-effectiveness of the tests.
              Learn more at <a href="http://www.biotheranostics.com" target="_blank">www.biotheranostics.com</a>.</p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <a href="/resources-providers" className="btn btn-secondary hcp w-250">Back To All</a>
          </div>
        </div>
      </div>
      
      <BackToTop />

      <SubFooter 
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard"
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: " Resources for Providers",
            text1: "Resources",
            text2: "for Providers"
          }
        ]} />
      
      <Footer 
            showTopLinks={true} topLinks={[
              {link: "/", label: "For Patients"},
              {link: "/know-someone-with-breast-cancer", label: "For Supporters"}
            ]}
            showSecondaryLinks={true} secondaryLinks={[
              {link: "/providers-resources", label: "Resources"},
              {link: "/contact-providers", label: "Explore The Report"},
              {link: "/contact-providers", label: "Contact"},
              {link: "/order-test", label: "Order The Test"}
            ]} />

    </div>   
  );

}

export default PressReleasePage1210;
