import React from 'react';
import "./style.scss"
import {AiOutlineDownload} from 'react-icons/ai'

import ReactGA from 'react-ga';

const GetBCIThankYou = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      {/* <!-- CONTENT AREA --> */}
      <div className="container content-page">
        <div className="row">
          <div className="col-12 pt-5">
            <h1>
              Thanks For Submitting!
            </h1>
            <h4 className="font1 clr c1">
              More Information Is On The Way.
            </h4>
             <br />
            
            <p className="mb-0">
            You will be receiving tools to help guide you through the process. In the meantime, if you're ready to talk to your doctor about the test, download the Request a Test Card and bring it to your next appointment.
            </p>
			<a target="_blank" href="/BTNBCI_1829350_BCI_Take_Action_Sheet_M10.pdf" className="btn btn-primary my-5 primary"><AiOutlineDownload /> Download The Request a Test Card</a>
			<hr />
            <p className="mt-5">
				You can also download digital versions of the Breast Cancer Resource Guide and Navigation Notebook to get started.
			</p>
            <div className="buttons">
              <a target="_blank" href="/breast-cancer-index-resource-guide.pdf" className="btn mt-5 mw-75"><AiOutlineDownload />Download The Breast Cancer Resource Guide</a>
			  <a target="_blank" href="/breast-cancer-index-navigation-notebook.pdf" className="btn mt-4"><AiOutlineDownload />Download The Breast Cancer Navigation Notebook</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetBCIThankYou;