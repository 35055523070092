//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

function NewsItem(ni) {
  return (
    <div key={ni.id} className="col-12">
      <article>
        <h6>{ni.publishedDate}</h6>
        <h1 dangerouslySetInnerHTML={{ __html: ni.title }}/>
        <p dangerouslySetInnerHTML={{ __html: ni.preview }}></p>
        <a target="_blank" href={ni.link} className="read-more">Read more</a>
      </article>
    </div>
  );
}

function NewsItems(props) {
  let includes = props.includes || [],
      excludes = props.excludes || [],
      newsItems = props.publications;

    if (includes.length) {
    newsItems = newsItems.filter(ni => includes.includes(ni.id)); // includes
    } else if (excludes.length) {
    newsItems = newsItems.filter(ni => !excludes.includes(ni.id)); // excludes
    }

  newsItems = newsItems.map(ni => (<NewsItem {...ni} />));

  return (
    <div className="row">
      {newsItems}
    </div>
  );
}

export default NewsItems;