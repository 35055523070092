//Copyright Breast Cancer Index || 2021
//Main Page

import React, { useState } from 'react';
import CookieConsent from "react-cookie-consent";

import { Navbar, SecondaryPatientNavbar, SubFooter, Footer, BackToTop, FloatingPetal, DownloadModal, SEO } from "../../lib";

import ReactGA from 'react-ga';

const RecentlyBeenDiagnosed = () => {

  const [show, setShow] = useState(false);
  const [mTitle, setTitleText] = useState("");
  const [mBody, setBodyText] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (type) => {
    if(type === "patient"){
      setTitleText("Download the Conversation Guide");
      setBodyText("");
    }else if(type === "provider"){
      setTitleText("Order Breast Cancer Index");
      setBodyText("Download the Conversation Guide");
    }
    setShow(true);
  }

  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='Recently Diagnosed With Breast Cancer - BCI Resources' description='As a breast cancer diagnosis brings uncertainty into your life, the team at Breast Cancer Index is here to help you and your oncology team find clarity.' hideSuffix={true}/>
      <Navbar type="patients" />
      
      {/* <!-- HEADER --> */}
      <header className="page-header patient-side">
        <div className="container-fluid not-round">
          <div className="clover-container">
            <div className="copy">
              <h1 className="quote">
                The day I was diagnosed was the scariest day of my life.
              </h1>
            </div>
            <div className="photo large">
                <img src="/img-hero1-header.png" alt="" className="w-100" />
            </div>
            <div className="photo-clover">
              <img src="/clover-purple-tint.svg" alt="" className="w-100" />
            </div>
          </div>
        </div>
      </header>

      <FloatingPetal type="patient"/>
      <DownloadModal show={show} title={mTitle} body={mBody} handleClose={handleClose} />

      {/* <!-- SECONDARY HEADER --> */}
      <section className="d-flex">
        <div className="secondary-header bgc g1">
          <div className="copy">
            <p>
            A breast cancer diagnosis shakes you to the core. It also means you’re suddenly faced with a million decisions.
            </p>
            <p>
              Breast Cancer Index<sup>&reg;</sup> will help bring clarity to you and your oncology team as you approach the five-year point after your initial diagnosis and consider your duration of anti-estrogen therapy.
            </p>
            <h6>Not Another Minute Not Knowing If Treatment is Benefiting You</h6>
            </div>
          <div className="bg-pattern"></div>
        </div>
      </section>    

      {/* <!-- TITLE --> */}
      <section>
        <div className="text-center p-5">
          <h1 className="page-title m-4">
            You can’t take the fear out of cancer.  <br />
            <span>
              But you can reduce the  <br />uncertainty around treating it.
            </span>
          </h1>
        </div>
      </section>

      {/* <!--HALF ROUND --> */}
      <section className="half-round mb-5">
        <div className="image">
          <img src="/img-doc-left-hand-cropped.png" alt="hand holding form" />
        </div>
        <div className="content p-5">
          <div className="copy">
            <h2 className="mb-4 mt-4">
              Breast Cancer Index Is Changing  <br />The Game.
            </h2>
            <p>
              If you have early-stage hormone receptor–positive (HR+) breast cancer and you and your oncologist are weighing treatment options — Breast Cancer Index will give you and your care team information you need to make the right treatment decision for you.
            </p>
            <hr/>
            <h5 className="font1">
            The Test Will Give You Information To Help You Answer These Questions:
            </h5>
            <ul>
              <li>Am I Likely To Benefit From Extended Anti-Estrogen Therapy?</li>
              <li>What Is My Risk Of Late-Distant Recurrence?</li>
            </ul>
            <hr/>
            <p>
              This test can be an important tool in helping you and your oncology team determine whether extending anti-estrogen therapy past 5 years may be beneficial for you.
            </p>
            <p>
              The test is performed only by Biotheranostics, Inc.
            </p>
            <div className="icon">
              <img src="/icon-bubble.svg" alt="" className="w-100" />
            </div>
            <p className="text-center text-md-left">
              <button className="btn btn-primary with-border" onClick={() => handleShow("patient")}>Download The Conversation Guide</button>
            </p>
          </div>
        </div>
        <div className="round-bg"></div>
      </section>

      {/* <!-- JOURNEY --> */}
      <div className="container">            
        <div className="row">
          <div className="col-12 text-center p-5">
            <h2 className="mb-4">
              Your Breast Cancer Timeline
            </h2>
            <p>
              If you’ve been diagnosed with breast cancer, it may be hard to think beyond the minute you’re in. But the results of your Breast Cancer Index test can help give you and your oncology team more certainty as you develop your treatment plan.
            </p>
          </div>
        </div>
      </div>

      {/* <!-- TIMELINE --> */}
      <section className="timeline">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12 order-2 order-md-1 col-md-6 left">
              <div className="odd">
                <h4>
                  Diagnosis is The First Step
                </h4>
                <p className="timeline-subtext">
                  The first point on the timeline for cancer treatment is what doctors refer to as “Time Zero.” For patients, this can be a world-shattering time full of chaos and information overload.
                </p>
              </div>
            </div>
            <div className="col-12 order-1 order-md-2 col-md-6 right">
              <div className="zero">
                <img src="/timeline-zero.svg" alt="" className="w-100" />
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12 col-md-6 d-none d-md-block">
              &nbsp;
            </div>
            <div className="col-12 col-md-6 right">
              <div className="highlight bgc g4">
                <div className="curly">
                  <img src="/shape-curly.svg" alt="" className="w-100" />
                </div>
                <div className="copy">
                  <h4>You Are Here</h4>
                  <p>
                    You’ve recently been diagnosed with early-stage, HR+ breast cancer. Your doctor has told you that taking anti-estrogen therapy for at least 5 years can help reduce your risk of the cancer returning, but it is unclear whether longer treatment could also be beneficial.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 left">
              <div className="odd hline">
                <h4>
                  Nearly 95% of Women Do Not Benefit from Anti-Estrogen Therapy After 5 Years<sup>1-6</sup>
                </h4>
                <p>
                  While most women with early-stage, HR+ breast cancer are prescribed anti-estrogen therapy, the duration of treatment may vary from patient to patient — at least 5 years of anti-estrogen therapy is usually recommended to help prevent a recurrence of your cancer.<sup>12</sup>
                </p>
                <p>
                  Breast Cancer Index can help you and your doctor determine whether a longer course of treatment is likely to benefit you.
                </p>
                <h6>
                  Talk to your doctor about Breast Cancer Index to understand when the test may be right for you.
                </h6>
                <button className="btn btn-primary mt-4" onClick={() => handleShow("patient")}>Download The Conversation Guide</button>
              </div>
            </div>
            <div className="col-12 col-md-6 right d-none d-sm-block">
              <div className="photo1">
                <img src="/img-timeline1-1.jpg" alt="" className="w-100" />
              </div>
            </div>
            <div className="col-12">
              <div className="year5">
                <img src="/timeline-year-5.svg" alt="" className="w-100" />
              </div>
            </div>
            <div className="col-12 col-md-6 d-none d-md-block">
              <div className="photo2">
                <img src="/img-timeline1-2.jpg" alt="" className="w-100" />
              </div>
            </div>              
            <div className="col-12 col-md-6 right">
              <div className="even hline">
                <h4>
                  Know If Additional Treatment is Right For You
                </h4>
                <p>
                  For some women, extending anti-estrogen therapy through year 10 may reduce the risk of recurrence.
                </p>
                <h6>
                  Breast Cancer Index can help you and your doctor decide what is right for you.
                </h6>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12 cta">
              <div className="year10">
                <img src="/timeline-year-10.svg" alt="" className="w-100" />
              </div>
              <div className="button">
                <a href="/FAQs" className="btn btn-primary">Frequently Asked Questions</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <BackToTop />

      <SubFooter 
        image="/img-hero5.png"
        icons={[
          {
            href: "/contact-patients",
            img: "/icon-person.svg",
            alt: " Contact & Support",
            text1: "Contact",
            text2: "& Support"
          },{
            modal: true,
            img: "/icon-bubble.svg",
            alt: " Explore the Test Report",
            text1: "Download The",
            text2: "Conversation Guide",
          }
        ]} 
        type='hasHero'/>
      <Footer 
        showReferences={[true, false]}
        showTopLinks={true} topLinks={[
          { link: "/resources-providers", label: "For Providers" },
          { link: "/know-someone-with-breast-cancer", label: "For Supporters" }
        ]}
        showSecondaryLinks={true} secondaryLinks={[
          { link: "/FAQs", label: "FAQs" },
          { link: "/contact-patients", label: "Contact & Support" }
        ]} 
        type='patient'
      />
    </div>
  );
}

export default RecentlyBeenDiagnosed;
