//Copyright Breast Cancer Index || 2021
//When theme was going to be used for articles. This was abandoned due to designs

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop
} from "../../lib";

import articles from '../../util/resources-providers-articles.json';

import ReactGA from 'react-ga';

class ResourcesProviders extends React.Component {

  article = {};

  constructor(props) {

    super(props);

    this.article = articles.filter(article => {
      return (article.id === parseInt(this.props.match.params.id) );
    })[0];

  }

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return(
      <div>
        <Navbar type="providers" />

         {/* <!-- INDIVIDUAL ARTICLES --> */}
        <header className="page-header">
          <div className="container-fluid bgc g3 round">
            <div className="clover-container">
              <div className="copy headline">
                <h1 className="font1">
                  { this.article.headerTitle }
                </h1>
              </div>
              <div className="photo-clover">
                <img src="/clover-closed.svg" alt="" className="w-100" />
              </div>
            </div>
            <div className="bg-pattern"></div>
          </div>
        </header>
        
        {/* <!-- CONTENT AREA --> */}
        <div className="container articles">
          <div className="row">
            {/* <!-- LEFT COLUMN --> */}
            <div className="col-12">
                {/* <!-- FEATURED ARTICLE--> */}
                <div className="row">
                  <div className="col-12">
                    <article className="featured">
                      <div dangerouslySetInnerHTML={{ __html: this.article.content }} />
                    </article>
                  </div>
                </div>
            </div>
          </div>
        </div>

        <BackToTop />

        <SubFooter 
          image=""
          icons={[
            {
              href: "/order-test",
              img: "/icon-clipboard.svg",
              alt: " Order Breast Cancer Index",
              text1: "Order Breast",
              text2: "Cancer Index",
              className: "clipboard"
            },{
              href: "#",
              img: "/icon-clover.svg",
              alt: " Explore the Test Report",
              text1: "Explore the",
              text2: "Test Report"
            },{
              href: "/resources-providers",
              img: "/icon-gear.svg",
              alt: " Resources for Providers",
              text1: "Resources",
              text2: "for Providers"
            }
          ]} />
        <Footer
          showTopLinks={true} topLinks={[
            {link: "/", label: "For Patients"},
            {link: "/know-someone-with-breast-cancer", label: "For Supporters"}
          ]}
          showSecondaryLinks={true} secondaryLinks={[
            {link: "/order-test", label: "Order The Test"},
            {link: "/contact-providers", label: "Contact"}
          ]} />
      </div>
    )
  }

}

export default ResourcesProviders;
