//Copyright Breast Cancer Index || 2021

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop,
    FloatingPetal
} from "../../lib";

import ReactGA from 'react-ga';

const Article5 = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc hcp-gradient round">
          <div className="clover-container">
            <div className="copy headline middle">
              <h1 className="mt-0 color-hologic-blue">
                Nearly 40% Of
                 <br />
                Patients Fail To
                 <br />
                Take Their
                 <br />
                Endocrine
                 <br />
                Medication
                 <br />
                As Prescribed<sup>1-3</sup>
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />

      <div className="container content-page">
        <div className="row w-90 max-700 mx-auto">
          <div className="col-12 py-5 text-center">
            <h3 className="pb-2">
              Side Effects And Impact On Patient Quality Of Life Drives
              Noncompliance
            </h3>
            <h4 className="font1 clr c1">
              In The Extended Endocrine Setting, Nearly 40% Of Patients Fail To
              Take Their Medication As Prescribed<sup>1-3</sup>
            </h4>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">
          <div className="col-12">
            <h4 className="font1 text-center">
              Compliance & Adherence With Endocrine Therapy Is A Significant
              Challenge
            </h4>
          </div>
        </div>
        <div className="row w-90 max-700 mx-auto my-5">
          <div className="col-12 col-sm-6">
            <ul>
              <li>
                Up to 70% of women with breast cancer do not take medicine as
                prescribed during the first 5 years.<sup>4-6</sup>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-6">
            <ul>
              <li>
                Up to 40% discontinue between years 5 through 10.<sup>1,2</sup>
              </li>
            </ul>
          </div>
          <div className="col-12 py-5 text-center">
            <b>
              Adherence Rate<sup>7-9</sup>
            </b>
          </div>
          <div className="col-12 py-3 text-center">
            <img
              src="/articles/article5-chart-main.svg"
              alt=""
              className="w-100"
            />
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto my-5">
          <div className="col-12">
            <h5 className="font1 clr c1 text-center">
              Poor Compliance Negatively Affects Outcomes
            </h5>
            <p className="py-3 text-center">
              <b>
                Reasons for Discontinuation<sup>2</sup>
              </b>
            </p>
          </div>
          <div className="col-12 col-sm-3 text-center">
            <img
              src="/articles/article5-chart-4.svg"
              alt=""
              className="w-50 w-100-sm"
            />
          </div>
          <div className="col-12 col-sm-3 mb-5 mb-sm-0 text-center">
            <img
              src="/articles/article5-chart-3.svg"
              alt=""
              className="w-50 w-100-sm"
            />
          </div>
          <div className="col-12 col-sm-3 mb-5 mb-sm-0 text-center">
            <img
              src="/articles/article5-chart-2.svg"
              alt=""
              className="w-50 w-100-sm"
            />
          </div>
          <div className="col-12 col-sm-3 mb-5 mb-sm-0 text-center">
            <img
              src="/articles/article5-chart-1.svg"
              alt=""
              className="w-50 w-100-sm"
            />
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto my-5">
          <div className="col-12">
            <h5 className="font1 clr c1 text-center">
              Introducing A Tool That May Help With Compliance
            </h5>
            <div className="row">
              <div className="col-12 col-sm-5 py-3 pt-sm-5 pt-sm-0">
                <img
                  src="/articles/article5-chart-5.svg"
                  alt=""
                  className="w-100"
                />
              </div>
              <div className="col-12 col-sm-7">
                <p className="pt-2 pt-sm-5">
                  In a Breast Cancer Index<sup>&reg;</sup> decision impact study
                  led by Yale and UPMC, after receiving their results, 82% of
                  patients that were recommended extended endocrine therapy
                  reported they were more likely to be compliant with their
                  treatment plan.<sup>10</sup>
                </p>
                <p className="text-center text-sm-left">
                  <a
                    href="https://www.futuremedicine.com/doi/full/10.2217/bmt-2019-0001"
                    className="btn btn-primary hcp mt-3 w-250 mx-auto"
                  >
                    Read The Study
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <p className="ref">
              <strong>References:</strong>{' '}
			  <strong>1.&nbsp;</strong>Goss PE et al. N Engl J Med. 2016 Jul 21;375(3):209-19.{' '}
			  <strong>2.&nbsp;</strong>Mamounas et al. Lancet Oncol 2019; 20: 88-99.{' '}
              <strong>3.&nbsp;</strong>Hershman et al. Breast Cancer Res Treat. 2011; 126(2): 529-537.{' '}
              <strong>4.&nbsp;</strong>Partridge, A. H. et al. J Clin Oncol; 21:602-606 2003.{' '}
			  <strong>5.&nbsp;</strong>Murphy CC, et al Breast Can Res Treat. 2012;134:459-4.{' '}
			  <strong>6.&nbsp;</strong>Sheppard VB et al. J Clin Oncol. 2014 Aug 1;32(22):2318-27.{' '}
			  <strong>7.&nbsp;</strong>Davies C et al., Lancet. 2013 ;381(9869):805-16.{' '}
			  <strong>8.&nbsp;</strong>Goss PE et al., N Engl J Med. 2003;349.{' '}
			  <strong>9.&nbsp;</strong>Goss PE et al. JNCI 2005; 97(17): 1262-71.{' '}
			  <strong>10.&nbsp;</strong>Sanft et al. Breast Cancer Manag 2019;8(1).
            </p>
            <a
              href="/resources-providers"
              className="btn btn-secondary hcp mt-5 w-250"
            >
              Back To All
            </a>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter
        site="hcp"
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard",
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: "Resources For Providers",
            text1: "Resources",
            text2: "For Providers",
          },
        ]}
        disclaimer=""
      />

      <Footer
        showTopLinks={true}
        topLinks={[
          { link: "/", label: "For Patients" },
          { link: "/know-someone-with-breast-cancer", label: "For Supporters" },
        ]}
        showSecondaryLinks={true}
        secondaryLinks={[
          { link: "/resources-providers", label: "Resources" },
          { link: "/contact-providers", label: "Explore The Report" },
          { link: "/contact-providers", label: "Contact" },
          { link: "/order-test", label: "Order The Test" },
        ]}
      />
    </div>
  );
}

export default Article5;
