import React from 'react';

import { Navbar, SubFooter, Footer, BackToTop, FloatingPetal, SEO } from "../../lib";

import ReactGA from 'react-ga';

function PressReleasePage() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='Annals of Oncology Features Breast Cancer Index ' description='A pivotal clinical study validating Breast Cancer IndexTM earns the distinction of being selected for the cover of the Annals of Oncology. Read more here.' hideSuffix={true}/>
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc g3 round">
          <div className="clover-container">
            <div className="copy headline">
              <h1 className="mt-0">
                The Power <br />To Predict
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/img/clover-open2.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />


      <div className="container content-page">
      <div className="row w-90 mx-auto my-5">
          <div className="col-12 pt-5 pb-2 text-center">
            <h3 className="pb-2">
            Biotheranostics’ Pivotal Clinical Study of the Breast Cancer Index<sup>&reg;</sup> featured on the cover of the Annals of Oncology
            </h3>
            <h6 className="font1 clr c10 mt-5">
              SAN DIEGO, Calif., February 24, 2020
            </h6>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">
          <div className="col-12">
            <p>SAN DIEGO, Calif., February 24, 2020 - A pivotal clinical study validating Breast Cancer Index<sup>&reg;</sup> earns the distinction of being selected for the cover of the Annals of Oncology. The Translational-aTTom study (Trans-aTTom) featured on the journal cover and led by an international team of cancer researchers strengthens Breast Cancer Index clinical evidence as a predictive biomarker and highlights its clinical utility in helping physicians determine whether a patient is likely to benefit from extended adjuvant endocrine therapy. Study findings add to the growing body of evidence demonstrating that Breast Cancer Index is the only commercially available genomic assay with predictive evidence for the decision of extended endocrine therapy for HR+, early-stage breast cancer patients.</p>
            <p>Catherine Schnabel, Ph.D., Chief Scientific Officer, Biotheranostics, said, “We are pleased to be recognized for the scientific achievement on a critical Breast Cancer Index validation study which further strengthens its clinical utility as an endocrine response biomarker. Biotheranostics remains committed to strengthening the clinical evidence supporting Breast Cancer Index towards individualizing care for each HR+, early-stage breast cancer patient.”</p>
            <p>Don Hardison, Biotheranostics’ President and CEO adds, “We are thrilled that Breast Cancer Index was recognized by the Annals of Oncology as having reached the level of evidence to be featured on the cover. It further solidifies that 100% of women deserve to know what is right for them regarding extension of endocrine therapy. For providers, knowing if treatment is benefiting their patient gives them the confidence when prescribing an additional five years of endocrine therapy. We are fortunate to offer the only commercially available biomarker that predicts benefit of extended endocrine therapy.”</p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">
          <div className="col-12">
            <h4>About Breast Cancer Index<sup className="sup-sm">TM</sup></h4>
            <p>Breast Cancer Index is a molecular, gene expression-based test uniquely positioned to provide information to help physicians individualize treatment decisions for patients with early stage, ER+ breast cancer. This breakthrough test helps oncologists and patients navigate the difficult trade-off between taking steps to prevent recurrence of their disease and facing significant side effects and safety challenges related to unnecessary treatment. Breast Cancer Index holds guidelines designation from the American Joint Committee on Cancer for cancer staging based on molecular profile; ASCO<sup>®</sup> Clinical Practice Guideline, NCCN, European Group on Tumor Markers (EGTM), and St. Gallen to inform the chemotherapy decision; and ASCO<sup>®</sup> Clinical Practice Guideline and EGTM to inform the extended endocrine treatment dilemma. It is the only validated, commercially available test that provides risk of overall and late distant recurrence and predicts the likelihood of benefit from extended endocrine therapy. For more information, visit <a href="https://www.breastcancerindex.com" target="_blank">www.breastcancerindex.com</a>.</p>
            <h4>About Biotheranostics</h4>
            <p>
              Biotheranostics, Inc. operates a CLIA-certified and CAP-accredited diagnostic laboratory in
              San Diego, California. Biotheranostics, Inc., is a leading healthcare provider in the
              oncology field, assisting physicians in the treatment of cancer patients. Its suite of
              proprietary molecular diagnostic tests provides important information to physicians to
              tailor treatment to individual patients. The company’s <a href="https://breastcancerindex.com" target="_blank">Breast Cancer Index</a><sup>&reg;</sup> and <a href="https://www.cancertypeid.com/" target="_blank">CancerTYPE ID</a><sup>&reg;</sup> tests address a variety of unmet medical
              needs in the management of cancer patients, and extensive clinical studies have confirmed
              the accuracy, clinical validity, clinical utility, and cost-effectiveness of the tests.
              Learn more at <a href="http://www.biotheranostics.com" target="_blank">www.biotheranostics.com</a>.</p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <a href="/resources-providers" className="btn btn-secondary hcp w-250">Back To All</a>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard"
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: " Resources for Providers",
            text1: "Resources",
            text2: "for Providers"
          }
        ]} />

      <Footer
            showTopLinks={true} topLinks={[
              {link: "/", label: "For Patients"},
              {link: "/know-someone-with-breast-cancer", label: "For Supporters"}
            ]}
            showSecondaryLinks={true} secondaryLinks={[
              {link: "/providers-resources", label: "Resources"},
              {link: "/contact-providers", label: "Explore The Report"},
              {link: "/contact-providers", label: "Contact"},
              {link: "/order-test", label: "Order The Test"}
            ]} />

    </div>
  );

}

export default PressReleasePage;
