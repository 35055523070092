//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import { Navbar, SubFooter, Footer, BackToTop, FloatingPetal, SEO } from "../../lib";

import ReactGA from 'react-ga';

function PressReleasePage121720() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='IDEAL Study Expands Clinical Evidence' description='Biotheranostics’ Breast Cancer IndexTM IDEAL study expands clinical evidence for prediction of extended endocrine benefit to patients treated with aromatase inhibitors' />
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc g3 round">
          <div className="clover-container">
            <div className="copy headline">
              <h1 className="mt-0 color-hologic-blue">
                BCI Predicts Benefit <br />Irrespective of Risk Profile
              </h1>
            </div>
            <div className="photo-clover">                          
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />
      

      <div className="container content-page">
      <div className="row w-90 mx-auto my-5">
          <div className="col-12 pt-5 pb-2 text-center">
            <h3 className="pb-2">
              Breast Cancer Index<sup>&reg;</sup> demonstrates its ability to predict preferential endocrine benefit irrespective of risk for HR+ early-stage breast cancer patients
            </h3>
            <h6 className="font1 clr c10 mt-5">
              SAN DIEGO, Calif., December 17, 2020.
            </h6>
          </div>
        </div>       
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">   
          <div className="col-12">
            <p>
              San Diego, CA, Dec. 17, 2020. Evidence demonstrating the ability of Breast Cancer Index to predict
              preferential endocrine benefit irrespective of clinical risk for HR+ early-stage breast cancer patients was
              highlighted during a Spotlight session on December 9th at this years’ San Antonio Breast Cancer
              Symposium. The Investigation on the Duration of Extended Adjuvant Letrozole (IDEAL) trial examined the
              Breast Cancer Index (BCI) HOXB13/IL17BR (H/I) ratio and its ability to predict benefit from extended
              endocrine therapy.  The IDEAL study randomized patients to an additional 2.5 versus 5 years of extended
              letrozole after completing 5 years of endocrine therapy with tamoxifen or aromatase inhibitors (AIs) or a
              sequence of tamoxifen and AI.
            </p>
            <p>
              The study, which evaluated patient subsets based on risk, underscored the important role of tumor biology,
              showing that BCI as a molecular signature of endocrine responsiveness predicted which patients benefited
              from a full 10 vs 7.5 years of endocrine treatment vs those that did not benefit. BCI identified clinically low-
              risk patients (N0 or N1 &amp; T1 or Grade 1) who derived significant benefit from 10 years of endocrine therapy
              (BCI-H/I High P=0.004). BCI also identified patients with clinically high-risk disease (N+/T2+) who did not
              benefit from extended letrozole (BCI-H/I Low P=0.742).
            </p>
            <p>
              Results emphasized that clinical or genomic risk were not sufficient to identify patients with improved
              outcomes from extended endocrine therapy, making BCI imperative to the clinical question of individualizing
              continuation or de-escalation of treatment.  Study results from IDEAL, published recently in the <i>Clinical
              Cancer Research</i> journal, represent the first data published on BCI’s ability to predict benefit from extended
              AI therapy following 5 years of primary adjuvant AI therapy. These data add to the already significant body of
              evidence demonstrating the predictive ability of BCI, further establishing the test as the only commercially
              available multi-gene expression assay with validated predictive data.
            </p>
            <p>
              “The presentations at SABCS confirm again that for our HR+ patients with early-stage breast cancer, the
              Breast Cancer Index is hands down the best tool available to counsel our patients regarding the risk for a
              late recurrence and for benefit of extended endocrine therapy beyond 5 years.” VK Gadi, MD PhD, Breast
              Cancer Physician and Professor, University of Illinois Cancer Center.
            </p>
            <p>
              Peter Beitsch, MD, shares his thoughts on Breast Cancer Index, “There has been a trend toward treating all
              women with hormone receptor positive breast cancer with 10 years of endocrine therapy even though the
              absolute benefit is only ~4%. The Breast Cancer Index (BCI) allows us to sort out the patients who will
              benefit from extended endocrine therapy from those who will not (but who will suffer the harms). 
              Extended endocrine with benefit - absolutely. Extended endocrine without benefit - why treat?  BCI is
              precision medicine at its finest!”
            </p>
            <p>
              Don Hardison, Biotheranostics’ President and CEO adds, “Breast Cancer Index has become a best-in-class
              genomic tool for HR+ early-stage breast cancer patients and their health care providers who are faced with
              challenging clinical decisions on duration of endocrine therapy.”
            </p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">
          <div className="col-12">
            <h4>About Breast Cancer Index<sup className="sup-sm">TM</sup></h4>
            <p>Breast Cancer Index is a molecular, gene expression-based test uniquely positioned to provide information to help physicians individualize treatment decisions for patients with early stage, ER+ breast cancer. This breakthrough test helps oncologists and patients navigate the difficult trade-off between taking steps to prevent recurrence of their disease and facing significant side effects and safety challenges related to unnecessary treatment. Breast Cancer Index holds guidelines designation from the American Joint Committee on Cancer for cancer staging based on molecular profile; ASCO, NCCN, European Group on Tumor Markers (EGTM), and St. Gallen to inform the chemotherapy decision; and ASCO and EGTM to inform the extended endocrine treatment dilemma. It is the only validated, commercially available test that provides risk of overall and late distant recurrence and predicts the likelihood of benefit from extended endocrine therapy. For more information, visit <a href="https://www.breastcancerindex.com" target="_blank">www.breastcancerindex.com</a>.</p>
            <h4>About Biotheranostics</h4>
            <p>
              Biotheranostics, Inc. operates a CLIA-certified and CAP-accredited diagnostic laboratory in 
              San Diego, California. Biotheranostics, Inc., is a leading healthcare provider in the 
              oncology field, assisting physicians in the treatment of cancer patients. Its suite of 
              proprietary molecular diagnostic tests provides important information to physicians to 
              tailor treatment to individual patients. The company’s <a href="https://breastcancerindex.com" target="_blank">Breast Cancer Index</a><sup>&reg;</sup>; and <a href="https://www.cancertypeid.com/" target="_blank">CancerTYPE ID</a><sup>&reg;</sup> tests address a variety of unmet medical 
              needs in the management of cancer patients, and extensive clinical studies have confirmed 
              the accuracy, clinical validity, clinical utility, and cost-effectiveness of the tests. 
              Learn more at <a href="http://www.biotheranostics.com" target="_blank">www.biotheranostics.com</a>.</p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <a href="/resources-providers" className="btn btn-secondary hcp w-250">Back To All</a>
          </div>
        </div>
      </div>
      
      <BackToTop />

      <SubFooter 
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard"
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: " Resources for Providers",
            text1: "Resources",
            text2: "for Providers"
          }
        ]} />
      
      <Footer 
            showTopLinks={true} topLinks={[
              {link: "/", label: "For Patients"},
              {link: "/know-someone-with-breast-cancer", label: "For Supporters"}
            ]}
            showSecondaryLinks={true} secondaryLinks={[
              {link: "/providers-resources", label: "Resources"},
              {link: "/contact-providers", label: "Explore The Report"},
              {link: "/contact-providers", label: "Contact"},
              {link: "/order-test", label: "Order The Test"}
            ]} />

    </div>   
  );

}

export default PressReleasePage121720;
