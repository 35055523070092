//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import { Navbar, SubFooter, Footer, BackToTop, FloatingPetal, SEO } from "../../lib";

import ReactGA from 'react-ga';

function PressReleasePage042522() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='ASCO Now Includes Breast Cancer Index' description='ASCO<sup>®</sup> Clinical Practice Guideline Now Includes Breast Cancer Index<sup>&reg;</sup> to Guide Decisions about Extended Endocrine Therapy.' />
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc g3 round">
          <div className="clover-container">
            <div className="copy headline">
              <h1 className="mt-0 color-hologic-blue">
                ASCO Recognition Further Affirms the Use of BCI
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />


      <div className="container content-page">
      <div className="row w-90 mx-auto my-5">
          <div className="col-12 pt-5 pb-2 text-center">
            <h3 className="pb-2">
            ASCO<sup>®</sup> Clinical Practice Guideline Now Includes Breast Cancer Index<sup>&reg;</sup> to Guide Decisions about Extended Endocrine Therapy
            </h3>
            <h5><em>Guideline inclusion further validates the need to incorporate Breast Cancer Index into standard of care</em></h5>
            <h6 className="font1 clr c10 mt-5">
              Marlborough, Massachusetts. April 25, 2022.
            </h6>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">
          <div className="col-12">
            <p>
              Hologic, Inc. (Nasdaq: HOLX) announced today that the ASCO<sup>®</sup> Clinical Practice Guideline has published an update which expands the utility of Breast Cancer Index<sup>&reg;</sup> (BCI) within its Clinical Practice Guideline: “Biomarkers for Adjuvant Endocrine and Chemotherapy in Early-Stage Breast Cancer.” Specifically, ASCO now recognizes BCI as the only genomic test to help guide extended endocrine therapy decisions in early-stage, HR+ breast cancer patients with node negative or node positive (one-three positive nodes) disease when treated with five years of primary endocrine therapy without evidence of recurrence. A special article highlighting the new ASCO guidelines was recently published in the Journal of Clinical Oncology on this subject.<sup>1</sup>
            </p>
            <p>
              “The clinical decision to either extend or end adjuvant endocrine therapy after five years is a challenging decision for healthcare providers and their patients. I am pleased to see updated guidelines from ASCO affirming the use of a data-driven biomarker like Breast Cancer Index to predict likelihood of benefit from extended endocrine therapy, helping to better inform decision-making processes around treatment plans,” said Mark Pegram, MD, Chief Medical Consultant for Breast Oncology at Biotheranostics, a subsidiary of Hologic. “There is an extensive body of clinical evidence consistently proving the utility of BCI, and its addition to major oncology clinical guidelines like those from ASCO further underscores the test’s potential in clinical decision making regarding extended adjuvant endocrine therapy.”
            </p>
            <p>
              BCI is a proprietary molecular gene expression-based test that is uniquely positioned to provide information to help physicians individualize treatment plans beyond five years. It is also the only test to be recognized by other major clinical practice guidelines for prediction of which early-stage, HR+ breast cancer patients are likely to benefit from extended endocrine therapy.<sup>2</sup> Extended endocrine therapy has been demonstrated to help reduce the risk of recurrence in some women with early-stage, HR+ breast cancer. However, for breast cancer patients on extended endocrine therapy, the potential side effects and toxicities of treatment often have significant negative effects on health and quality of life, such as osteoporosis, bone fractures and joint pain.<sup>3-6</sup> As a result, it’s important to know if a patient is unlikely to benefit from extended endocrine therapy to help reduce these challenging side effects and health consequences.
            </p>
            <p>
              “We are pleased to see that ASCO updated its clinical practice guidelines to include BCI as the only genomic test to predict the value of extended endocrine therapy, reaffirming other guidelines within clinical oncology,” said Kevin Thornal, Hologic’s president, Diagnostic Solutions Division. “We look forward to continuing to improve women’s health by giving healthcare providers the information they need to make the best treatment decisions for their patients.”
            </p>
            <p>
              According to the ASCO Guideline Update, the purpose was to “update recommendations on appropriate use of breast cancer biomarker assay results to guide adjuvant endocrine and chemotherapy decisions in early-stage breast cancer.<sup>1</sup>” An updated literature search identified 24 randomized clinical trials and prospective-retrospective studies published from January 2016 to October 2021, which were evaluated by an Expert Panel to develop evidence-based recommendations.
            </p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">
          <div className="col-12">
            <h4>About Breast Cancer Index<sup className="sup-sm">TM</sup></h4>
            <p>Breast Cancer Index<sup>&reg;</sup> is a molecular, gene expression-based test uniquely positioned to provide information to help physicians individualize treatment decisions for patients with early-stage, HR+ breast cancer. This breakthrough test helps oncologists and patients navigate the difficult trade-offs between taking steps to prevent recurrence of their disease and facing significant side effects and safety challenges related to unnecessary treatment. Breast Cancer Index has guideline designation from the American Joint Committee on Cancer for cancer staging based on molecular profile. ASCO, the European Society for Medical Oncology (ESMO), the European Group on Tumor Markers (EGTM) and St. Gallen acknowledge Breast Cancer Index as a biomarker to inform the chemotherapy decision; and ASCO and EGTM acknowledge BCI as a biomarker to inform the extended endocrine treatment decision. It is the only validated, commercially available test that predicts benefit from extended endocrine therapy. Breast Cancer Index is intended for routine clinical use, and treatment decisions based on results are the responsibility of the physician. It is a laboratory developed test (LDT) performed in a single CLIA-certified and CAP-accredited diagnostic laboratory and is not required to be cleared or approved by the US Food and Drug administration. For more information, visit <a href="https://www.breastcancerindex.com" target="_blank" rel="noopener noreferrer">www.breastcancerindex.com</a>.</p>
            <h4>About Hologic</h4>
            <p>
              Hologic, Inc. is an innovative medical technology company primarily focused on improving women’s health and well-being through early detection and treatment. For more information on Hologic, visit <a href="http://www.hologic.com" target="_blank" rel="noopener noreferrer">www.hologic.com</a>.
            </p>
            <h4>Forward-Looking Statements</h4>
            <p>
              This press release may contain forward-looking information that involves risks and uncertainties, including statements about the use of the Breast Cancer Index test. There can be no assurance this test will achieve the benefits described herein or that such benefits will be replicated in any particular manner with respect to an individual patient. The actual effect of the use of the test can only be determined on a case-by-case basis depending on the particular circumstances and patient in question. In addition, there can be no assurance that this test will be commercially successful or achieve any expected level of sales based on the updated ASCO clinical practice guideline. Hologic expressly disclaims any obligation or undertaking to release publicly any updates or revisions to any such statements presented herein to reflect any change in expectations or any change in events, conditions or circumstances on which any such statements are based.
            </p>
            <p>
              Hologic, Breast Cancer Index and The Science of Sure are trademarks and/or registered trademarks of Hologic, Inc. in the United States and/or other countries.
            </p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-800 mx-auto pt-5">
          <div className="col-12 text-left">
            <div className="ref ref-heading">References:</div>
            <ol className="ref">
              <li><a href="https://ascopubs.org/doi/full/10.1200/JCO.22.00069" target="_blank" rel="noopener noreferrer">https://ascopubs.org/doi/full/10.1200/JCO.22.00069</a>. Accessed April 21, 2022.</li>
              <li><a href="https://www.nccn.org/professionals/physician_gls/pdf/breast.pdf" target="_blank" rel="noopener noreferrer">https://www.nccn.org/professionals/physician_gls/pdf/breast.pdf</a>. Accessed April 21, 2022.</li>
              <li>Femara Full Prescribing Information And Side Effects: <a href="https://www.breastcancer.org/treatment/hormonal-therapy/femara" target="_blank" rel="noopener noreferrer">https://www.breastcancer.org/treatment/hormonal-therapy/femara</a>.</li>
              <li>Armimidex Full Prescribing Information And Side Effects: <a href="https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=acbfaaa9-503c-4691-9828-76a7146ed6de" target="_blank" rel="noopener noreferrer">https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=acbfaaa9-503c-4691-9828-76a7146ed6de</a>.</li>
              <li>Aromasin Full Prescribing Information And Side Effects: <a href="http://labeling.pfizer.com/ShowLabeling.aspx?id=523" target="_blank" rel="noopener noreferrer">http://labeling.pfizer.com/ShowLabeling.aspx?id=523</a>.</li>
              <li>Tamoxifen Full Prescribing Information And Side Effects: <a href="http://www.nlm.nih.gov/medlineplus/druginfo/meds/a682414.html" target="_blank" rel="noopener noreferrer">http://www.nlm.nih.gov/medlineplus/druginfo/meds/a682414.html</a>.</li>
            </ol>
          </div>
        </div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <a href="/resources-providers" className="btn btn-secondary hcp w-250">Back To All</a>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: "Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard"
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: " Resources for Providers",
            text1: "Resources",
            text2: "for Providers"
          }
        ]} />

      <Footer
            showTopLinks={true} topLinks={[
              {link: "/", label: "For Patients"},
              {link: "/know-someone-with-breast-cancer", label: "For Supporters"}
            ]}
            showSecondaryLinks={true} secondaryLinks={[
              {link: "/providers-resources", label: "Resources"},
              {link: "/contact-providers", label: "Explore The Report"},
              {link: "/contact-providers", label: "Contact"},
              {link: "/order-test", label: "Order The Test"}
            ]} />

    </div>
  );

}

export default PressReleasePage042522;
