//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop,
    SEO
} from "../../lib";

import ReactGA from 'react-ga';

const NoticeOfPrivacy = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='Privacy Policy' description='Here at Breast Cancer Index, we value your privacy and security. You can read the full Breast Cancer Index privacy policy here for more information.' />
      <Navbar type="patients" />
      
      {/* <!-- TEMP LANDING --> */}
      <header className="page-header photo-grad">
        <div className="container-fluid bgc g3 round">
          <div className="clover-container">
            <div className="copy headline">
              <h1>
                Notice of Privacy                              
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-closed.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      {/* <!-- CONTENT AREA --> */}
      <div className="container content-page">
        <div className="row">
          <div className="col-12 pt-5 border-bottom">
            <h2 className="font1 clr c1 py-2">Biotheranostics, Inc. Notice of Privacy Practices</h2>
            <h6>Effective Date of this Notice: March 28, 2018</h6>

            <p>THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.</p>
            <p>This Notice of Privacy Practices (“notice”) applies to Biotheranostics and each of its business units and subsidiaries, as applicable (collectively, “Biotheranostics”, “we”, “us”, or “our”)</p>

            <h2 className="font1 clr c1 py-2">Background</h2>
            <p>Biotheranostics is a provider of laboratory testing services. In providing testing services,
              obtaining payment for these services, conducting healthcare operations, and other purposes
              permitted or required by law (“purposes”), we receive, create and disclose protected health
              information. This information is private and confidential. This notice describes information
              we collect, how we use that information, and when and to whom we may disclose it in the
              context of providing laboratory testing services. See our Website Privacy Statement regarding
              information we collect, use and disclose relating to our website.</p>
            
            <h2 className="font1 clr c1 py-2">Protected health information and our obligations</h2>
            <p>Protected health information or PHI is information that can be used to identify you and
              relates to current, past or future information created or received by Biotheranostics from
              physicians, patients, health plans, government, private or commercial payors or other sources
              about patients for whom testing is ordered from Biotheranostics. It may indicate the physical
              condition of a patient, the provision of health care to that patient, or payment for the
              provision of health care to that patient.</p>
            <p>Certain laws require Biotheranostics to maintain the privacy and security of PHI and to make
              available this notice of our legal duties, privacy practices and patient rights with respect to
              PHI. When we use or disclose PHI, we are required to abide by the terms of this notice (or
              other notice in effect at the time of the use or disclosure). In the event of a breach involving
              unsecured PHI, Biotheranostics is required to notify affected individuals as described below.
              This notice does not apply to non-diagnostic services that we may perform, such as clinical
              trials.</p>
            <p>If you share this information or these test results with anyone, you are responsible for any
              compromise of confidentiality that may result from such sharing.</p>

            <h2 className="font1 clr c1 py-2">Information collected and created by Biotheranostics</h2>
            <p>Biotheranostics collects the minimally necessary information for these purposes. This may
              include name, address, telephone number, social security number, date of birth, medical
              history, diagnosis, treatment, provider identification and treatment information, financial
              responsibility and payment information.</p>
            <p>Biotheranostics creates, through its testing services, information to be used by a physician in
              the diagnosis of disease or condition or in the treatment of a disease or condition.</p>

            <h2 className="font1 clr c1 py-2">Protection of PHI</h2>
            <p>Access to PHI is restricted to those employees of Biotheranostics who need it in order to
              provide services to clients and patients and conduct business operations. While there can be
              no guarantee of privacy, Biotheranostics maintains physical, technical and procedural
              safeguards, including policies and procedures, to reasonably protect PHI against
              unauthorized use and disclosure. We have a Privacy Officer who is responsible for overseeing
              the development, implementation and enforcement of policies and procedures designed to
              safeguard PHI against inappropriate use and disclosure consistent with the applicable law
              and educating Biotheranostics personnel about this.</p>

            <h2 className="font1 clr c1 py-2">Standard uses and disclosures of PHI</h2>
            <p>In the course of providing laboratory services, Biotheranostics uses PHI internally and
              discloses it to health care providers (doctors requesting services, laboratory personnel
              involved in ordering services and other caregivers), insurers, third party administrators, plan
              sponsors and other payors (employers, health care provider organizations and others who
              may be responsible for paying for or administering your health benefits); third-party service
              providers, vendors and consultants (business associates); government authorities; and their
              respective agents. They are required by law to keep PHI confidential. Some examples of what
              we do with the information we collect and the reasons it might be disclosed to third parties
              are described below.</p>
            <p>We may use or disclose PHI with or without your consent to provide health care services.
              Examples of these uses and disclosures include:</p>
            <ul>
              <li>Treatment – We use and disclose PHI for your treatment purposes, including the fulfillment of requests by physicians to perform laboratory testing services.</li>
              <li>Payment – We use and disclose PHI to obtain reimbursement for testing services. Examples of these payment activities include: billing, collections activities, determination of eligibility and obtaining authorization for services. We will only disclose your genetic testing results to an insurer with your authorization to do so. If you are insured under another person’s health insurance policy (for example, parent, guardian, spouse, domestic partner, or former spouse), we may also send invoices to the subscriber whose policy covers your health services.</li>
              <li>Health care operations – We may use and disclose PHI for our health care operations, such as performing quality checks on our testing, testing for accuracy of results, developing reference ranges for our tests, internal audits, accreditation functions, arranging for legal services, and for operational purposes.</li>
              <li>When sharing your information, we may do so electronically.</li>
            </ul>
            <p>Other activities permitted or required by law</p>
            <p>We may use or disclose PHI for other important activities permitted or required by law, with or without your authorization. These include:</p>
            <ul>
              <li>Provide Information on Health Care-Related Services – We may send you information related to your individual treatment, case management or care coordination; or to direct or recommend alternative treatment, therapies, health care providers, settings of care; or to describe a health-related product or service that is provided by us, unless we receive direct or indirect payment in exchange for making the communication. HIPAA considers these non-marketing activities. We will not disclose your PHI, including genetic information, to a third party for marketing purposes without your authorization.</li>
              <li>Required by Law – We may use or disclose PHI to the extent such use or disclosure is required by law and it complies with and is limited to the requirements of that law. We may use and disclose PHI for workers’ compensation claims and certain law enforcement purposes and in response to official subpoenas, court orders, discovery requests and other legal process. In addition, we use and disclose PHI in connection with health oversight activities (e.g., government audits of our compliance with certain laws and regulations; oversight of government-funded health benefits programs) and for certain health and safety concerns.</li>
              <li>Research – We use and disclose PHI in connection with research performed by us and by researchers outside Biotheranostics. This research generally is subject to the oversight of an Institutional Review Board. In most cases, while PHI may be used to help prepare a research project or to contact you to ask whether you want to participate in a study, it will not be further disclosed for research without your authorization. Sometimes, however, where permitted under federal law and institutional policy, and approved by an Institutional Review Board or a privacy board, PHI may be used or disclosed. In addition, PHI may be used or disclosed to compile limited or de-identified data sets that do not include your name, address, social security number or other direct identifiers. These data sets may, in turn, be used for research purposes.</li>
              <li>Family and Friends – Under certain circumstances, we may disclose PHI to family members, other relatives, or close personal friends or others that you identify to the extent it is directly relevant to their involvement with your care or payment related to your care.</li>
              <li>Personal Representatives – We may disclose PHI to your personal representative, as established under applicable law such as someone you have given a medical power of attorney or if someone is your legal guardian, or to an administrator, executor, or other authorized individual associated with your estate.</li>
            </ul>

            <h2 className="font1 clr c1 py-2">Uses and disclosures requiring authorization</h2>
            <p>It is possible to request that we disclose PHI to people in ways not described above. To
              authorize us to disclose your protected health information to a person or organization or for
              reasons other than those described in the section above, see the “Copy of notice, questions
              or complaints” section below for contact information at the bottom of this page. If you make
              a special authorization and later change your mind about this, you may send a letter to us to
              let us know that you would like to revoke the special authorization at any time, except to the
              extent that action has been taken in reliance on the authorization. In any communication with
              us, please provide your name, address, patient identification number, and a telephone
              number where we can reach you in case we need to contact you about your request.</p>
            <ul>
              <li>Marketing – We must obtain your written authorization in order to use your PHI for marketing purposes as defined by HIPAA. No authorization is required for marketing material provided to you in a face-to-face communication or for promotional gifts of nominal value.</li>
              <li>Sale of PHI – We must obtain your written authorization prior to any sale of your PHI, except as noted in the “Purchase or sale of business” section.</li>
            </ul>
            
            <h2 className="font1 clr c1 py-2">Your rights with respect to PHI</h2>
            <p>Subject to certain exceptions, the Health Insurance Portability and Accountability Act of 1996, as amended, and its implementing regulations (collectively “HIPAA”), establishes the following patient rights with respect to PHI.</p>
            <ul>
              <li>Receive a copy of this notice – You have a right to receive a paper copy of this notice upon your request.</li>
              <li>Request restrictions on uses and disclosures of PHI – You have a right to ask us in writing to restrict certain uses and/or disclosures of your PHI related to your treatment, your payment or routine health care operations. In addition, you may request PHI disclosure restrictions to family members, other relatives or close friends involved in your care. We will consider your request, but are not required to agree to such a restriction, unless the requested restriction involves a disclosure to your health plan for payment or healthcare operations purposes when you have directly paid us in full out-of-pocket, or when the uses or disclosures are required by law. If we do agree to a restriction, we will state the agreed upon restrictions in writing and will abide by them, except in emergency situations for purposes of treatment. Any restriction to which we agree is not effective to prevent uses or disclosures of PHI required by the Secretary of the Department of Health and Human Services to investigate or determine our compliance with HIPAA or for certain activities permitted or required by law.</li>
              <li>Confidential communications – You have a right to request, in writing, to receive confidential communications containing your PHI from us in ways or at locations that are outside our usual process, such as to a different address or by a different means. We will make every effort to accommodate reasonable requests.</li>
              <li>Copies of Lab reports – You have a right to access copies of your laboratory test results. You may receive your test results: online when this service is made available; or you may contact us to obtain a form to request a copy, in writing, and pay for the cost of copying, mailing, or electronic media on which the information is provided, such as a CD or flash drive, in which case we will provide it to you within 30 days of the written request or once the results are available. If you have questions about your test results, please contact your ordering physician.</li>
              <li>Correct or amend PHI – You have a right to request that we correct or amend the records described above for as long as we maintain them. You must make the request in writing and give us a reason for the amendment. We may deny your request if: (i) we determine that we did not create the record, unless the originator of the PHI is no longer available to act on the requested amendment; or (ii) if we believe that the existing records are accurate and complete. If we do deny your request to change you PHI, we will provide you a written explanation within 60 days of the reason for the denial and any additional information regarding further actions that you may take. Note that an amendment may take several forms; for example we may add an explanatory statement to a record rather than changing it.</li>
              <li>Accounting of disclosures – You have a right to receive an accounting of disclosures (a list) made by Biotheranostics to any third party in the six years prior to the date on which the accounting is requested in writing, unless you request a shorter period of disclosures. Under the law, this right does not apply to certain disclosures, including, but not limited to, disclosures made for the purposes of treatment, payment or health care operations; disclosures made to you or to others involved in your care; disclosures made with your authorization; disclosures made for national security or intelligence purposes or to correctional institutions or law enforcement purposes; or certain other disclosures. You must make any request for an accounting in writing and we may charge a fee to fill more than one request in any given year.</li>
              <li>Complain – If you believe your privacy rights have been violated, you have a right to file a complaint with us or with the Secretary of the Department of Health and Human Services (“DHHS").</li>
            </ul>
            <p>For more information, see <a href=" https://www.hhs.gov/hipaa/for-individuals/guidance-materials-for-consumers/index.html"> https://www.hhs.gov/hipaa/for-individuals/guidance-materials-for-consumers/index.html.</a></p>  

            <h2 className="font1 clr c1 py-2">How to exercise your rights</h2>            
            <p>To exercise any of your rights described above, see the “Copy of notice, questions or
              complaints” section below for contact information. In some cases, a written request or
              completed form may be required. We will respond to requests in a timely manner.</p>
            
            <h2 className="font1 clr c1 py-2">Purchase or sale of business</h2>
            <p>If Biotheranostics enters into a business transaction, such as a merger, acquisition by another
              company, selling some or all of its assets, or bankruptcy, your personal information will, in
              most instances, be part of the assets transferred. We will require the acquiring company to
              comply with the material provisions of this notice, as amended from time to time.</p>

            <h2 className="font1 clr c1 py-2">Information breach notification</h2>
            <p>We are required to provide notification to affected individuals if we discover a breach of
              unsecured PHI, unless a formal risk assessment demonstrates that there is a low probability
              that the PHI has been compromised. You will be notified without unreasonable delay within
              legally required timeframes after discovery of the breach. Such notification will include
              information about what happened and what can be done to mitigate any harm.</p>

            <h2 className="font1 clr c1 py-2">State law compliance</h2>
            <p>Our use and disclosure of PHI must comply not only with federal privacy regulations but also
              with applicable state law.</p>

            <h2 className="font1 clr c1 py-2">Distribution and updates of this notice</h2>
            <p>This notice is published on the Biotheranostics’ website at <a href="https://www.biotheranostics.com/privacy-policy">www.biotheranostics.com/privacy-policy</a>  and is made available in printed form upon request. See the “Copy of notice, questions
              or complaints” section below for how you can make such a request.</p>

            <h2 className="font1 clr c1 py-2">Changes to this notice</h2>
            <p>We may change the terms of this notice at any time. If we change this notice, we may make
              the new notice terms effective for all PHI that we maintain, including any information created
              or received prior to issuing the new notice. If we change this notice, we will post the new
              notice on our Internet site at <a href="https://www.Biotheranostics.com">www.Biotheranostics.com</a>. Please review this site periodically to
              ensure you are aware of any such update.</p>

            <h2 className="font1 clr c1 py-2">Communication with Biotheranostics</h2>
            <p>As a convenience, Biotheranostics may make available email addresses by which you can
              communicate with us. Please be advised that email is not a secure means of communication,
              therefore Biotheranostics cannot guarantee the secure transmission of any information that
              you send to us. This fact may also restrict our use of email in communicating any response to
              you – we will make every attempt to use alternate means of communicating anything that
              may be considered sensitive information.</p>

            <h2 className="font1 clr c1 py-2">Copy of notice, questions or complaints</h2>
            <p>If you would like to exercise any of your rights described above, request a paper copy of this
              notice, have questions about it, or believe its terms or any Biotheranostics privacy or security
              policy has been violated with respect to information about you, please let us know
              immediately by contacting us either by phone, mail or email as indicated below. Please
              provide your name, address, a telephone number where we can contact you, and a brief
              description of the request, question or complaint. If you prefer, you may lodge an anonymous
              complaint.</p>
          
            <p>Biotheranostics, Inc. Privacy Officer  <br />
              9620 Towne Center Drive, Suite 200  <br />
              San Diego, CA 92121  <br />
              Toll Free: 1-877-886-6739  <br />
              Email: <a href="mailto:info@biotheranostics.com">info@biotheranostics.com</a></p>
            
            <p>You also may contact the Secretary of the Department of Health and Human Services, Office for Civil Rights (OCR) using one of the methods identified at <a href="https://www.hhs.gov/ocr/privacy/hipaa/complaints/" target="_blank">www.hhs.gov/ocr/privacy/hipaa/complaints/ </a> which include fax, email, electronically via the OCR Compliant Portal or mail to your regional HHS Office.</p> 
             <br />
              The U.S. Department of Health and Human Services  <br />
              Office of Civil Rights  <br />
              200 Independence Avenue, S.W.  <br />
              Washington, D.C. 20201  <br />
              (202) 619-0257  <br />
              Toll Free: 1-877-696-6775

            <p>Please provide as much information as possible so that the complaint can be properly
              investigated. Biotheranostics will not retaliate against a person who files a complaint with us
              or with the Secretary of the Department of Health and Human Services.</p>
          
          
          </div>  
        </div>
      </div>

      <BackToTop />

      <SubFooter 
        image=""
        icons={[
          {
            href: "/contact-providers",
            img: "/icon-bubble.svg",
            alt: " Contact Breast Cancer Index",
            text1: "Contact Breast",
            text2: "Cancer Index"
          },{
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard"
          }
        ]} />
        
      <Footer
        showTopLinks={true} topLinks={[
          { link: "/", label: "For Patients" }
        ]}
        showSecondaryLinks={true} secondaryLinks={[
          { link: "/order-test", label: "Order The Test" },
          { link: "/contact-providers", label: "Contact" }
        ]}
        type='provider'
      />

    </div>
  );
}

export default NoticeOfPrivacy;
