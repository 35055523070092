//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import { Navbar, SubFooter, Footer, BackToTop, FloatingPetal, SEO } from '../../lib';

import countriesAndStates from '../../util/countries_and_states.json';

import ReactGA from 'react-ga';

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = { country: '', states_: ['State'], option: 'Mail me a brochure' };

    this.handleCountrySelection = this.handleCountrySelection.bind(this);
    this.handleChangeOption = this.handleChangeOption.bind(this);
  }

  handleCountrySelection = (event) => {
    this.setState({ country: event.target.value });
  };

  handleChangeOption = (event) => {
    this.setState({ option: event.target.value });
  };

  processCountries = () => {
    return countriesAndStates.map((country, idx) => {
      return <option key={idx}>{country.name}</option>;
    });
  };

  processStates = () => {
    let states_ = countriesAndStates.filter((country) => {
      return country.name === this.state.country;
    })[0];

    if (states_) {
      return states_.states.map((state_, idx) => {
        return <option key={idx}>{state_}</option>;
      });
    } else {
      return null;
    }
  };

  formContent = () => {
    if (this.state.option === 'Add me to email list (opt-in)') {
      return (
        <div>
          <div className='form-group'>
            <label htmlFor='firstName' className='hidden'>
              First Name
            </label>
            <input type='text' className='form-control' placeholder='First Name' id='field44500089-first' name='field44500089-first' defaultValue='' />
          </div>
          <div className='form-group'>
            <label htmlFor='lastName' className='hidden'>
              Last Name
            </label>
            <input type='text' className='form-control' placeholder='Last Name' id='field44500089-last' name='field44500089-last' defaultValue='' />
          </div>
          <div className='form-group'>
            <label htmlFor='email' className='hidden'>
              Email
            </label>
            <input type='email' className='form-control' placeholder='Email' id='field44500090' name='field44500090' required='required' defaultValue='' />
          </div>
          <div className='form-group'>
            <label htmlFor='zipcode' className='hidden'>
              Zip Code
            </label>
            <input
              type='text'
              className='form-control'
              placeholder='Zip Code'
              id='field44500095-zip'
              name='field44500095-zip'
              size='6'
              defaultValue=''
              required
            />
          </div>
          <div className='form-group arrow'>
            <label htmlFor='specialty' className='hidden'>
              Specialty
            </label>
            <select className='form-control' id='field44500092' name='field44500092' size='1'>
              <option>Specialty</option>
              <option>Oncology</option>
              <option>Pathology</option>
              <option>Surgeon</option>
            </select>
          </div>
          <div className='form-group'>
            <label htmlFor='npiNumber' className='hidden'>
              NPI Number
            </label>
            <input
              type='text'
              className='form-control'
              placeholder='NPI Number'
              id='field44500093'
              name='field44500093'
              size='50'
              maxLength='10'
              defaultValue=''
            />
          </div>
        </div>
      );
    } else if (this.state.option === 'Mail me a brochure') {
      return (
        <div>
          <div className='form-group'>
            <label htmlFor='firstName' className='hidden'>
              First Name
            </label>
            <input type='text' className='form-control' placeholder='First Name' id='field44500089-first' name='field44500089-first' defaultValue='' />
          </div>
          <div className='form-group'>
            <label htmlFor='lastName' className='hidden'>
              Last Name
            </label>
            <input type='text' className='form-control' placeholder='Last Name' id='field44500089-last' name='field44500089-last' defaultValue='' />
          </div>
          <div className='form-group arrow'>
            <label htmlFor='specialty' className='hidden'>
              Specialty
            </label>
            <select className='form-control' id='field44500092' name='field44500092' size='1'>
              <option>Specialty</option>
              <option>Oncology</option>
              <option>Pathology</option>
              <option>Surgeon</option>
            </select>
          </div>
          <div className='form-group'>
            <label htmlFor='npiNumber' className='hidden'>
              NPI Number
            </label>
            <input
              type='text'
              className='form-control'
              placeholder='NPI Number'
              id='field44500093'
              name='field44500093'
              size='50'
              maxLength='10'
              defaultValue=''
            />
          </div>
          <div className='form-group'>
            <label htmlFor='organization' className='hidden'>
              Organization
            </label>
            <input type='text' className='form-control' placeholder='Organization' id='field44500094' name='field44500094' size='50' required defaultValue='' />
          </div>
          <div className='form-group'>
            <label htmlFor='mailingAddressLine2' className='hidden'>
              Mailing Address
            </label>
            <input
              type='text'
              className='form-control'
              placeholder='Address Line 1'
              id='field44500095-address'
              name='field44500095-address'
              size='50'
              defaultValue=''
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='mailingAddressLine2' className='hidden'>
              Mailing Address
            </label>
            <input
              type='text'
              className='form-control'
              placeholder='Address Line 2'
              id='field44500095-address2'
              name='field44500095-address2'
              size='50'
              defaultValue=''
            />
          </div>
          <div className='form-group'>
            <label htmlFor='city' className='hidden'>
              City
            </label>
            <input
              type='type'
              className='form-control'
              placeholder='City'
              id='field44500095-city'
              name='field44500095-city'
              size='15'
              defaultValue=''
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='zipcode' className='hidden'>
              Zip Code
            </label>
            <input
              type='text'
              className='form-control'
              placeholder='Zip Code'
              id='field44500095-zip'
              name='field44500095-zip'
              size='6'
              defaultValue=''
              required
            />
          </div>
          <div className='form-group arrow'>
            <label htmlFor='country' className='hidden'>
              Country
            </label>
            <select className='form-control' onChange={this.handleCountrySelection} id='field44500095-country' name='field44500095-country' required>
              <option>Country</option>
              {this.processCountries()}
            </select>
          </div>
          <div className='form-group arrow'>
            <label htmlFor='state' className='hidden'>
              State
            </label>
            <select className='form-control' id='field44500095-state' name='field44500095-state' required>
              <option>State</option>
              {this.processStates()}
            </select>
          </div>
        </div>
      );
    } else if (this.state.option === 'Have somebody contact me') {
      return (
        <div>
          <input
            type='text'
            className='form-control'
            placeholder='Organization'
            id='field44500094'
            name='field44500094'
            size='50'
            required
            defaultValue='-'
            style={{ display: 'none' }}
          />
          <input
            type='email'
            className='form-control'
            placeholder='Email'
            id='field44500090'
            name='field44500090'
            required='required'
            defaultValue='-'
            style={{ display: 'none' }}
          />
          <input
            type='text'
            className='form-control'
            placeholder='Address Line 1'
            id='field44500095-address'
            name='field44500095-address'
            size='50'
            defaultValue='-'
            required
            style={{ display: 'none' }}
          />
          <div className='form-group'>
            <label htmlFor='firstName' className='hidden'>
              First Name
            </label>
            <input type='text' className='form-control' placeholder='First Name' id='field44500089-first' name='field44500089-first' defaultValue='' />
          </div>
          <div className='form-group'>
            <label htmlFor='lastName' className='hidden'>
              Last Name
            </label>
            <input type='text' className='form-control' placeholder='Last Name' id='field44500089-last' name='field44500089-last' defaultValue='' />
          </div>
          <div className='form-group'>
            <label htmlFor='zipcode' className='hidden'>
              Zip Code
            </label>
            <input
              type='text'
              className='form-control'
              placeholder='Zip Code'
              id='field44500095-zip'
              name='field44500095-zip'
              size='6'
              defaultValue=''
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='phone' className='hidden'>
              Phone
            </label>
            <input type='tel' className='form-control' placeholder='Phone' id='field44500091' name='field44500091' defaultValue='' />
          </div>
          <div className='form-group arrow'>
            <label htmlFor='specialty' className='hidden'>
              Specialty
            </label>
            <select className='form-control' id='field44500092' name='field44500092' size='1'>
              <option>Specialty</option>
              <option>Oncology</option>
              <option>Pathology</option>
              <option>Surgeon</option>
            </select>
          </div>
          <div className='form-group'>
            <label htmlFor='npiNumber' className='hidden'>
              NPI Number
            </label>
            <input
              type='text'
              className='form-control'
              placeholder='NPI Number'
              id='field44500093'
              name='field44500093'
              size='50'
              maxLength='10'
              defaultValue=''
            />
          </div>
          <div className='form-group'>
            <input
              type='checkbox'
              className='form-control'
              placeholder='Insurance'
              id='field45466856_1'
              name='field45466856[]'
              defaultValue='I have a insurance/billing-related matter.'
            />
            <label className='form-check-label' htmlFor='insurance'>
              I Have insurance/billing related matter.
            </label>
          </div>
        </div>
      );
    }
  };

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div>
        <SEO
          title='Contacting Breast Cancer Index Test Providers'
          description='Breast Cancer Index is changing the way patients and their providers approach treatment. Providers who want more information about BCI Testing, click here.'
          hideSuffix={true}
        />
        <Navbar type='providers' />

        {/* <!-- IMAGE HEADER --> */}
        <header className='page-header'>
          <div className='container-fluid mr-auto round'>
            <div className='image'></div>
          </div>
        </header>

        <FloatingPetal type='provider' />

        <div className='container content-page'>
          <div className='row'>
            <div className='col-12 py-5 border-bottom contact-new-layout'>
              <div>
                <h5>Contact & Support for Providers</h5>
                <p>Our Client Services Team is here to support you. Please contact us for any non-billing related support.</p>
                <h6>Contact The Client Services Team:</h6>
                <p>
                  <strong>Call:</strong> <a href='tel:8778866739'>(877) 886-6739</a> <br />
                  <strong>Email:</strong> <a href='mailto:clientservices@biotheranostics.com'>clientservices@biotheranostics.com</a> <br />
                  <strong>Hours:</strong> 6:00am - 5:00pm (PST)
                </p>
              </div>
              <div>
                <h5>Billing & Insurance</h5>
                <p>Please contact our Patient Services team for any billing related support.</p>
                <h6>Contact The Patient Services Team:</h6>
                <p>
                  <strong>Call:</strong> <a href='tel:8443198111'>(844) 319-8111</a> <br />
                  <strong>Email:</strong> <a href='mailto:patientservices@biotheranostics.com'>patientservices@biotheranostics.com</a> <br />
                  <strong>Hours:</strong> 6:00am - 4:00pm (PST)
                </p>
              </div>
              {/* <h1>For Providers:</h1>
              <p>Breast Cancer Index<sup>&reg;</sup> is changing the game for patients and their providers.</p>
              <p>For more information please call us at <a href="tel:877-886-6739"><b>(877) 886-6739</b></a>. For more specific information regarding Breast Cancer Index, please fill out the short form below.</p>
              <div className="w-80 mt-4">
                <iframe
                    src="https://biotheranostics1.formstack.com/forms/breastcancerindex_contact_form"
                    title="Breast Cancer Index Contact Form"
                    width="100%"
                    height="900"
                    style={{ boder: '1px solid #fff', borderWidth: '0' }} />
              </div> */}
            </div>
          </div>
        </div>

        <BackToTop />

        <SubFooter
          site='hcp'
          image=''
          icons={[
            {
              href: 'mailto:clientservices@biotheranostics.com',
              img: '/icon-bubble.svg',
              alt: 'Contact Breast Cancer Index',
              text1: 'Contact Breast',
              text2: 'Cancer Index',
            },
            {
              href: '/order-test',
              img: '/icon-clipboard.svg',
              alt: ' Order Breast Cancer Index',
              text1: 'Order Breast',
              text2: 'Cancer Index',
              className: 'clipboard',
            },
          ]}
        />

        <Footer
          showTopLinks={true}
          topLinks={[{ link: '/', label: 'For Patients' }]}
          showSecondaryLinks={true}
          secondaryLinks={[
            { link: '/order-test', label: 'Order The Test' },
            { link: 'mailto:clientservices@biotheranostics.com', label: 'Contact' },
          ]}
          type='provider'
        />
      </div>
    );
  }
}

export default Contact;
