//Copyright Breast Cancer Index || 2021
//Main Page

import React from "react";

function SinglePubItem(item) {
  return (
    <div key={item.id} className="col-12">
        <article className="featured">
          <h5>{item.tag[0]}</h5>
          <h1 dangerouslySetInnerHTML={{ __html: item.title }}></h1>
          <div className="row flex-column flex-lg-row">
            {item.headerImg ? <div className="col-12 d-block d-lg-none"><img className="mb-3 mt-2" src={item.headerImg} alt="" /></div> : null}
            <div className={`${item.headerImg ? 'col-12 col-lg-6' : 'col-12'} `}>
              <h2 dangerouslySetInnerHTML={{ __html: item.subtitle }}></h2>
              <p dangerouslySetInnerHTML={{ __html: item.preview }}></p>
              <div className="button">
                <a target="_blank" href={item.link} className="btn btn-primary hcp btn-animated my-3 px-5">
                  {item.id === 202206 ? 'Watch Now' : 'Keep Reading'}
                </a>
              </div>
            </div>
            {item.headerImg ? <div className="col-6 d-none d-lg-block"><img className="my-1" src={item.headerImg} alt="" /></div> : null}
          </div>
        </article>
      </div>
  )
}

function PubItem(pub) {
  if (pub.id == "1") {
    return (
      <div key={pub.id} className="col-12">
        <article className="featured">
          <h5>{pub.tag[0]}</h5>
          <h1 dangerouslySetInnerHTML={{ __html: pub.title }}></h1>
          {pub.headerImg ? <img src={pub.headerImg} alt="" /> : null}
          <h2 dangerouslySetInnerHTML={{ __html: pub.subtitle }}></h2>
          <p dangerouslySetInnerHTML={{ __html: pub.preview }}></p>
          <div className="button">
            <a
              target="_blank"
              href={pub.link}
              className="btn btn-primary hcp btn-animated my-3 px-5"
            >
              Keep Reading
            </a>
          </div>
        </article>
      </div>
    );
  } else {
    return (
      <div key={pub.id} className="col-12 col-md-6">
        <article>
          <h5>{pub.tag[0]}</h5>
          <h1 dangerouslySetInnerHTML={{ __html: pub.title }}></h1>
          {pub.headerImg ? <img className="mb-3 mt-2" src={pub.headerImg} alt="" /> : null}
          <h2>{pub.subtitle}</h2>
          <p dangerouslySetInnerHTML={{ __html: pub.preview }}></p>
          <div className="button">
            <a
              target="_blank"
              href={pub.link}
              className="btn btn-primary hcp btn-animated my-3 px-5"
            >
              {pub.id === 202206 ? 'Watch Now' : 'Keep Reading'}
            </a>
          </div>
        </article>
      </div>
    );
  }
}

function Publications(props) {
  let filter = props.filter || [],
    includes = props.includes || [],
    excludes = props.excludes || [],
    publications = props.publications;

  // validate
  if (includes.length && excludes.length) {
    throw new Error("includes and excludes cannot both be defined!");
  }

  if (includes.length) {
    publications = publications.filter((pub) => includes.includes(pub.id)); // includes
  } else if (excludes.length) {
    publications = publications.filter((pub) => !excludes.includes(pub.id)); // excludes
  }

  publications = publications.filter((pub) => {
    // default to all
    if (filter.length < 1) return true;
    // use reducer
    return pub.tag.reduce((filtered, t) => {
      return filtered || filter.includes(t.toLowerCase());
    }, false);
  });

  publications = publications.length === 1 ? <SinglePubItem {...publications[0]} /> : publications.map((pub) => <PubItem {...pub} />);

  return <div className="row">{publications}</div>;
}

export default Publications;
