//Copyright Breast Cancer Index || 2021
//Floating petal for the downloading guide
//Current

import React, { useState } from "react";

import DownloadModal from '../DownloadModal';

const FloatingPetal = ({ type }) => {

  const [show, setShow] = useState(false);
  const [mTitle, setTitleText] = useState("");
  const [mBody, setBodyText] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if(type === "patient"){
      setTitleText("Download the Conversation Guide");
      setBodyText("");
    }else if(type === "provider"){
      setTitleText("Order Breast Cancer Index");
      setBodyText("Download the Conversation Guide");
    }
    setShow(true);
  }

  function processContent(type) {
    if ( type === "provider" ) {
      return (
        <a target="_blank" href="/order-test" className="floaty-button hcp">
        Order Breast <br />Cancer <br />Index
        <img className="hover-none" src="/download-icon-dark.png" alt="Order Breast Cancer Index" />
        <img className="hover-show" src="/download-icon.png" alt="Order Breast Cancer Index" />
        </a>
      );
    } else {
      return (
        <a target="_blank" href="/getting-bci" className="floaty-button patient">
            How  <br /> Do I Get  <br /> The Test?
        </a>
      );
    }
  }
  
  return (
    <>
      <DownloadModal show={show} title={mTitle} body={mBody} type="ty" handleClose={handleClose}/>
      { processContent(type) }
    </>
  );
}
  
export default FloatingPetal;
