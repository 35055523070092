//Copyright Breast Cancer Index || 2021

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop,
    FloatingPetal
} from "../../lib";

import ReactGA from 'react-ga';

const Article11 = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const smallBold = {
    fontSize: '11px',
  }

  return (
    <div>
      
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc hcp-gradient round">
          <div className="clover-container">
            <div className="copy headline middle">
              <h1 className="mt-0 color-hologic-blue">
                BCI Predictive Validation: <br />Trans-aTTom 
              </h1>
            </div>
            <div className="photo-clover">                          
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />

      <div className="container content-page">
        <div className="row w-90 mx-auto">
          <div className="col-12 py-5 text-center">
            <h3 className="pb-2">
              Results from the Trans-aTTom Study
            </h3>
            <h4 className="font1 clr c1">
            Trans-aTTom was the second prospective-retrospective study validating Breast Cancer Index<sup>&reg;</sup> prediction and established level 1B evidence for BCI as a biomarker for extended endocrine therapy response.<sup>1,2</sup>
            </h4>
          </div>
        </div>
        <div className="border-bottom"></div>
      </div>

      <div className="container content-page">
        <div className="col-12 text-center">
          <p className="font1 mt-5">
            <b>
              STUDY DESIGN
            </b>
          </p>
        </div>
        <ul>
            <li>583 node positive patients with HR+, early-stage disease</li>
            <li>Randomized to 5 years of extended Tamoxifen (TAM) vs stopping after 5 years of primary adjuvant TAM</li>
          </ul>
        <div className="border-bottom"></div>
      </div>


      <div className="container content-page">
        <div className="row w-90 max-700 mx-auto pt-5">
          <div className="col-12 text-center">
            <h4 className="font1 clr c1 mx-auto my-3">
              Risk Reduction And Absolute Benefit
            </h4>
          </div>
        </div>
        <div className="row w-90 max-700 mx-auto pb-5">
          <div className="col-12 col-md-6 text-center">
              <b>BCI No (Not Likely to Benefit, H/I Low)</b> <br />was associated with no significant benefit from extexnded TAM. 
          </div>
          <div className="col-12 col-md-6 text-center">
              <b>BCI Yes (Likely to Benefit, H/I High)</b> <br />was associated with a 65% relative risk reduction and 10.2% absolute benefit.
          </div>
        </div>
        <div className="border-bottom"></div>
        </div>

        <div className="container content-page">
        <div className="row w-90 max-800 mx-auto pt-5">
          <div className="col-12 text-center">
            <h4 className="font1 clr c1 mx-auto my-3">
              In the Trans-aTTom study, BCI effectively stratified patients into those who would or would not be likely to benefit from extended treatment.
            </h4>
            <p className="font1 mt-5">
              <b>
                Relative Benefit Of Extended Tamoxifen By BCI (H/I) Status: Trans-aTTom N+ Cases<sup>1</sup>
              </b>
            </p>
            <div className="col-12 text-center">
              <img src="/articles/tamoxifen-chart-1.png" alt="" className="w-100" />
              </div>
          </div>
        </div>
        
        <div></div></div>
        
        
      
      {/* <!--QUARTER ROUND --> */}
      <section className="qtr-round-article my-5">
        <div className="container content">
          <div className="row">
            <div className="col-10 offset-1 col-sm-11 pr-0 pr-sm-5">
              <h2 className="pr-0 pr-sm-5">
                “Genomic classifiers that predict risk of late recurrence and/or benefit from extended endocrine therapy may help to further individualize the recommendation for extended aromatase inhibitor therapy.”<sup>3</sup>
                 <br /><h2 className="text-right">- Eleftherios Mamounas, MD</h2>
              </h2>
            </div>
          </div>
        </div>
        <div className="round-bg bgc hcp-gradient horizontal"></div>
      </section>

      <div className="container content-page">
      <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <h4 className="font1 clr c1 mx-auto mt-4 mb-5">
              Read the Full Publication
            </h4>
            <p><a href="https://www.annalsofoncology.org/article/S0923-7534(20)32589-8/fulltext" target="_blank">Breast Cancer Index and Prediction of Benefit From Extended Endocrine Therapy in Breast Cancer Patients Treated in the Adjuvant Tamoxifen—To Offer More? (aTTom) Trial »</a></p>
          </div>
        </div>
        <div className="border-bottom"></div>
      </div>


        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <p className="ref">
            <strong>References:</strong>{' '}
			<strong>1.&nbsp;</strong>Bartlett et al. Annals of Oncol 2019 doi: 10.1093/annonc/mdz289.{' '}
			<strong>2.&nbsp;</strong>Simon RM, et al. J Natl Cancer Inst 2009; 101(21):1446–1452.{' '}
			<strong>3.&nbsp;</strong>Mamounas EP et al. NSABP B-42. GS4-01. SABCS 2019. Dec 12, 2019.<br />Eleftherios Mamounas, MD is an advisor for Biotheranostics, Inc.
            </p>
            <a href="/resources-providers" className="btn btn-secondary hcp mt-5">Back To All</a>
          </div>
        </div>

      <BackToTop />

      <SubFooter 
        site='hcp'
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard"
          },{
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: "Resources For Providers",
            text1: "Resources",
            text2: "For Providers"
          }
        ]}
        disclaimer="" />
        
        <Footer 
            showTopLinks={true} topLinks={[
              {link: "/", label: "For Patients"},
              {link: "/know-someone-with-breast-cancer", label: "For Supporters"}
            ]}
            showSecondaryLinks={true} secondaryLinks={[
              {link: "/resources-providers", label: "Resources"},
              {link: "/contact-providers", label: "Explore The Report"},
              {link: "/contact-providers", label: "Contact"},
              {link: "/order-test", label: "Order The Test"}
            ]} />



    </div>
  );
}

export default Article11;
