//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop,
    SEO
} from "../../lib";

import ReactGA from 'react-ga';

const StatementNondiscrimination = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='Nondiscrimination Policy' description='Biotheranostics complies with applicable Federal civil rights laws and does not discriminate. Read our nondiscrimination policy here.' />
      <Navbar type="home" />
      
      {/* <!-- TEMP LANDING --> */}
      <header className="page-header photo-grad">
        <div className="container-fluid bgc g3 round">
          <div className="clover-container">
            <div className="copy headline">
              <h1>
                Nondiscrimination Statement                              
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-closed.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      {/* <!-- CONTENT AREA --> */}
      <div className="container content-page">
        <div className="row">
          <div className="col-12 pt-5 border-bottom">
            <h2 className="font1 clr c1 py-2">
              Biotheranostics Nondiscrimination Statement
            </h2>
            <p>
              Biotheranostics, Inc. complies with applicable Federal civil rights laws and does not discriminate on the basis of race, color, national origin, age, disability, or sex.
            </p>
            <p>
              Biotheranostics, Inc. tuân thủ luật dân quyền hiện hành của Liên bang và không phân biệt đối xử dựa trên chủng tộc, màu da, nguồn gốc quốc gia, độ tuổi, huyết tật, hoặc giới tính.
            </p>
            <p>
              Biotheranostics, Inc. cumple con las leyes federales de derechos civiles aplicables y no discrimina por motivos de raza, color, nacionalidad, edad, discapacidad o sexo.
            </p>
            <p>
              Biotheranostics, Inc. 遵守適用的聯邦民權法律規定，不因種族、膚色、民族血統、年齡、殘障或性別而歧視任何人。
            </p>
            
            <h2 className="font1 clr c1 py-2">Biotheranostics Nondiscrimination Notice</h2>
            <p><a target='_blank' href="http://www.biotheranostics.com/files/BiotheranosticsNoticeofNondiscrimination-Non-EnglishFINAL.pdf">Download our PDF to view this notice in other languages</a>.</p>
            <p>
              Biotheranostics, Inc. (BTX) complies with applicable Federal civil rights laws and does not discriminate on the basis of race, color, national origin, age, disability, or sex. BTX does not exclude people or treat them differently because of race, color, national origin, age, disability, or sex.
            </p>
            <p>BTX:</p>
            <p>
              <ul>
                <li>Provides free aids and services to people with disabilities to communicate effectively with us, such as:
                  <ul>
                    <li>Qualified sign language interpreters</li>
                    <li>Written information in other formats (large print, audio, accessible electronic formats, other formats)</li>
                  </ul>
                </li>
                <li>Provides free language services to people whose primary language is not English, such as:
                  <ul>
                    <li>Qualified interpreters</li>
                    <li>Information written in other languages</li>
                  </ul>
                </li>
                <p>If you need these services, contact BTX at 1-877-886-6739.</p>
                <p>If you believe that BTX has failed to provide these services or discriminated in another way on the basis of race, color, national origin, age, disability, or sex, you can file a grievance with us electronically, or by mail or phone at:</p>
                Karla Kelly | General Counsel | Compliance Officer <br />
                Biotheranostics, Inc. <br />
                9620 Towne Centre Drive, Suite 200 <br />
                San Diego, CA 92121 <br />
                (858) 587-5881, <a target='_blank' href="mailto:karla.kelly@biotheranostics.com">karla.kelly@biotheranostics.com</a> <br />
                <p>You can also file a civil rights complaint with the U.S. Department of Health and Human Services, Office for Civil Rights, electronically through the Office for Civil Rights Complaint Portal, available at https://ocrportal.hhs.gov/ocr/portal/lobby.jsf, or by mail or phone at:</p>
                U.S. Department of Health and Human Services <br />
                200 Independence Avenue, SW <br />
                Room 509F, HHH Building <br />
                Washington, D.C. 20201 <br />
                1-800-368-1019, 800-537-7697 (TDD) <br /> <br />

                Complaint forms are available at <a target='_blank' href="http://www.hhs.gov/ocr/office/file/index.html">http://www.hhs.gov/ocr/office/file/index.html</a> 

                <h2 className="font1 clr c1 py-2">Language Services</h2>                
                <p>
                  ATTENTION: If you speak a language other than English, language assistance services, free of charge, are available to you. Call 1-877-886-6739.
                </p>
                <p>
                  ATENCIÓN: si habla español, tiene a su disposición servicios gratuitos de asistencia lingüística. Llame al 1-877-886-6739.
                </p>
                <p>
                  注意：如果您使用繁體中文，您可以免費獲得語言援助服務。請致電 1-877-886-6739
                </p>
                <p>
                  CHÚ Ý: Nếu bạn nói Tiếng Việt, có các dịch vụ hỗ trợ ngôn ngữ miễn phí dành cho bạn. Gọi số 1-877-886-6739.
                </p>
              </ul>
            </p>

            

          </div>  
        </div>
      </div>

      <BackToTop />

      <SubFooter 
        image=""
        icons={[
          {
            href: "/contact-providers",
            img: "/icon-bubble.svg",
            alt: " Contact Breast Cancer Index",
            text1: "Contact Breast",
            text2: "Cancer Index"
          },{
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard"
          }
        ]} />
        
      <Footer
        showTopLinks={true} topLinks={[
          { link: "/", label: "For Patients" }
        ]}
        showSecondaryLinks={true} secondaryLinks={[
          { link: "/order-test", label: "Order The Test" },
          { link: "/contact-providers", label: "Contact" }
        ]}
        type='provider'
      />

    </div>
  );
}

export default StatementNondiscrimination;
