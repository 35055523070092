//Copyright Breast Cancer Index || 2021

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop,
    FloatingPetal
} from "../../lib";

import ReactGA from 'react-ga';

const Article9 = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc hcp-gradient round">
          <div className="clover-container">
            <div className="copy headline middle">
              <h1 className="mt-0 color-hologic-blue">
                Not Another
                 <br />
                Minute Of
                 <br />
                Assuming
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />

      <div className="container content-page">
        <div className="row w-90 mx-auto">
          <div className="col-12 py-5 text-center">
            <h3 className="pb-2">
              Breast Cancer Index<sup>&reg;</sup> Is Transforming Clinical
              Assumptions
            </h3>
            <h4 className="font1 clr c1">
              Looking Beyond Clinical Features Based On Individual Tumor Biology
              Can Impact Clinical Decision-Making
            </h4>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto">
          <div className="col-12 py-5 text-center">
            <p className="mb-4">
              <b>
                Identify Which Of Your Clinically Low-Risk Patients
                <br className="d-none d-sm-block" />
                May Benefit From Longer Treatment
              </b>
            </p>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-5  text-center d-flex align-items-center">
                <div>
                  In a clinical study of 547 patients considered to be
                  clinically low risk (T1N0), Breast Cancer Index identified
                  ~25% as likely to benefit from extended endocrine therapy and
                  high risk of late distant recurrence.<sup>1</sup>
                </div>
              </div>
              <div className="col-12 col-sm-7 text-center py-5 py-sm-0">
                <img
                  src="/articles/article9-chart-main.png"
                  alt=""
                  className="w-75"
                />
              </div>
            </div>
            <a
              href="https://www.nature.com/articles/s41523-017-0037-3"
              className="btn btn-primary hcp mt-5 w-250"
            >
              Read The Study
            </a>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto">
          <div className="col-12 py-5 text-center">
            <p className="mb-4">
              <b>
                Identify Which Of Your N1 Patients May Be Spared Extended
                Treatment
              </b>
            </p>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-5 text-center d-flex align-items-center">
                <div>
                  In a clinical validation of 402 patients with node-positive
                  disease (N1, 1-3 positive nodes) who are often considered to
                  be high risk and candidates for extended endocrine therapy,
                  Breast Cancer Index identified 22% as having limited risk of
                  late distant recurrence, and 69% of those were not likely to
                  benefit from endocrine therapy beyond 5 years.<sup>2</sup>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-7 text-center py-5 py-sm-0">
                <img
                  src="/articles/article9-chart1.svg"
                  alt=""
                  className="w-75"
                />
              </div>
            </div>
            <a
              href="https://clincancerres.aacrjournals.org/content/clincanres/23/23/7217.full.pdf"
              className="btn btn-primary hcp mt-5 w-250"
            >
              Read The Study
            </a>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <p className="ref">
              <strong>References:</strong>{' '}
			  <strong>1.&nbsp;</strong>Schroeder et al. NPJ Breast Cancer 2017; doi:10.1038/s41523-017-0037-3.{' '}
			  <strong>2.&nbsp;</strong>Zhang Y et al. Clin Cancer Res. 2017 Dec 1;23(23):7217-7224.
            </p>
            <a
              href="/resources-providers"
              className="btn btn-secondary hcp mt-5 w-250"
            >
              Back To All
            </a>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter
        site="hcp"
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard",
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: "Resources For Providers",
            text1: "Resources",
            text2: "For Providers",
          },
        ]}
        disclaimer=""
      />

      <Footer
        showTopLinks={true}
        topLinks={[
          { link: "/", label: "For Patients" },
          { link: "/know-someone-with-breast-cancer", label: "For Supporters" },
        ]}
        showSecondaryLinks={true}
        secondaryLinks={[
          { link: "/resources-providers", label: "Resources" },
          { link: "/contact-providers", label: "Explore The Report" },
          { link: "/contact-providers", label: "Contact" },
          { link: "/order-test", label: "Order The Test" },
        ]}
      />
    </div>
  );
}

export default Article9;
