//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import { Navbar, SubFooter, Footer, BackToTop, FloatingPetal, SEO } from "../../lib";

import ReactGA from 'react-ga';

function PressReleasePage52920() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='The Power to Predict' description='Biotheranostics and the Not Another Minute Campaign, exploring how BCI is revolutionary for early-stage, hormone-receptor-positive breast cancer patients.' />
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc g3 round">
          <div className="clover-container">
            <div className="copy headline">
              <h1 className="mt-0 color-hologic-blue">
                The Power <br />To Predict
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />


      <div className="container content-page">
      <div className="row w-90 mx-auto my-5">
          <div className="col-12 pt-5 pb-2 text-center">
            <h3 className="pb-2">
            Biotheranostics presents new studies of the Breast Cancer Index<sup>&reg;</sup> at the 2020 ASCO conference, expanding its clinical utility as a predictive tool for hormone receptor positive, early stage breast cancer
            </h3>
            <h6 className="font1 clr c10 mt-5">
              SAN DIEGO, Calif., May 29, 2020.
            </h6>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">
          <div className="col-12">
            <p>SAN DIEGO, Calif., May 29, 2020. Biotheranostics announces several new studies demonstrating comprehensive validation and clinical utility of its Breast Cancer Index genomic assay for early- stage, HR+ breast cancer that will be presented at the ASCO<sup>®</sup> Clinical Practice Guideline 2020 Virtual Conference.</p>
            <ul>
              <li>Breast Cancer Index and its ability to predict extended endocrine benefit from extended aromatase inhibitor therapy in the IDEAL (Investigation on the Duration of Extended Adjuvant Letrozole ) trial will be presented (Liefers et al), representing the largest validation to date with 908 HR+, early-stage breast cancer patients. It is also the first study to evaluate prediction of benefit from extended treatment with an aromatase inhibitor (AI) following initial treatment with an AI.</li>
              <li>Additional results from the Trans-aTTom (Adjuvant Tamoxifen to Offer More) study including 789 HR+ patients will be disclosed (Bartlett, et al) examining Breast Cancer Index predictive performance for extended endocrine benefit across HER2 subsets in HR+ patients treated with tamoxifen.</li>
              <li>Furthermore, results from an ongoing study in collaboration with Yale University (Foldi et al) evaluated the impact of Breast Cancer Index results on patient adherence to extended endocrine therapy.</li>
            </ul>
            <p>Catherine Schnabel, Ph.D., Chief Scientific Officer, Biotheranostics, said, “We are pleased to continue to strengthen the clinical utility evidence for the Breast Cancer Index with new pivotal large-scale studies premiering at ASCO this year that further establish its distinct and important role in endocrine therapy decision-making.”</p>
            <p>Don Hardison, Biotheranostics’ President and CEO adds, “Breast Cancer Index continues to demonstrate its ability to predict which hormone receptor positive patients are likely to benefit from additional anti-estrogen therapy vs those who will not. The convergence of our strong scientific evidence and our commercial efforts allows us to provide information that women and their providers deserve when making difficult joint decisions about prolonged treatment with anti- estrogen therapy. We are fortunate to offer the only commercially available biomarker that predicts benefit of extended endocrine therapy.”</p>

          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">
          <div className="col-12">
            <h4>About Breast Cancer Index<sup className="sup-sm">TM</sup></h4>
            <p>Breast Cancer Index is a molecular, gene expression-based test uniquely positioned to provide information to help physicians individualize treatment decisions for patients with early stage, ER+ breast cancer. This breakthrough test helps oncologists and patients navigate the difficult trade-off between taking steps to prevent recurrence of their disease and facing significant side effects and safety challenges related to unnecessary treatment. Breast Cancer Index holds guidelines designation from the American Joint Committee on Cancer for cancer staging based on molecular profile; ASCO, NCCN, European Group on Tumor Markers (EGTM), and St. Gallen to inform the chemotherapy decision; and ASCO and EGTM to inform the extended endocrine treatment dilemma. It is the only validated, commercially available test that provides risk of overall and late distant recurrence and predicts the likelihood of benefit from extended endocrine therapy. For more information, visit <a href="https://www.breastcancerindex.com" target="_blank">www.breastcancerindex.com</a>.</p>
            <h4>About Biotheranostics</h4>
            <p>
              Biotheranostics, Inc. operates a CLIA-certified and CAP-accredited diagnostic laboratory in
              San Diego, California. Biotheranostics, Inc., is a leading healthcare provider in the
              oncology field, assisting physicians in the treatment of cancer patients. Its suite of
              proprietary molecular diagnostic tests provides important information to physicians to
              tailor treatment to individual patients. The company’s <a href="https://breastcancerindex.com" target="_blank">Breast Cancer Index</a><sup>&reg;</sup>; and <a href="https://www.cancertypeid.com/" target="_blank">CancerTYPE ID</a><sup>&reg;</sup> tests address a variety of unmet medical
              needs in the management of cancer patients, and extensive clinical studies have confirmed
              the accuracy, clinical validity, clinical utility, and cost-effectiveness of the tests.
              Learn more at <a href="http://www.biotheranostics.com" target="_blank">www.biotheranostics.com</a>.</p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <a href="/resources-providers" className="btn btn-secondary hcp w-250">Back To All</a>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard"
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: " Resources for Providers",
            text1: "Resources",
            text2: "for Providers"
          }
        ]} />

      <Footer
            showTopLinks={true} topLinks={[
              {link: "/", label: "For Patients"},
              {link: "/know-someone-with-breast-cancer", label: "For Supporters"}
            ]}
            showSecondaryLinks={true} secondaryLinks={[
              {link: "/providers-resources", label: "Resources"},
              {link: "/contact-providers", label: "Explore The Report"},
              {link: "/contact-providers", label: "Contact"},
              {link: "/order-test", label: "Order The Test"}
            ]} />

    </div>
  );

}

export default PressReleasePage52920;
