//Copyright Breast Cancer Index || 2021

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop,
    FloatingPetal
} from "../../lib";

import ReactGA from 'react-ga';

const Article6 = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc hcp-gradient round">
          <div className="clover-container">
            <div className="copy headline middle">
              <h1 className="mt-0 color-hologic-blue">
                Breast Cancer
                 <br />
                Index<sup>&reg;</sup> Is
                 <br />
                Changing the
                 <br />
                Paradigm
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />

      <div className="container content-page">
        <div className="row w-90 max-700 mx-auto">
          <div className="col-12 py-5 text-center">
            <h3 className="pb-2">
              Breast Cancer Index (BCI) Is Changing Physician Behavior
            </h3>
            <h4 className="font1 clr c1">
            BCI results changed physician treatment recommendations in 30% of patients<sup>1</sup>
            </h4>
          </div>
        </div>
        <div className="row w-90 max-700 mx-auto pt-5">
          <div className="col-12">
            <h4 className="font-1 clr c1 text-center mb-5">
            Key Results from Prospective Decision-Impact Trials (Physician-Reported)<sup>1,2</sup>
            </h4>
          </div>
        </div>
        <div className="border-bottom"></div>
       
        <div className="row w-90 mx-auto pt-5">
          <div className="col-12">
            <p className="text-center">
              <b>
              In a prospective decision-impact study led by Yale University of 141 patients with early-stage, HR+ breast cancer on endocrine therapy for over 3.5 years, 30% of physician treatment recommendations regarding extended therapy changed after receiving a BCI result.<sup>1</sup>
              </b>
            </p>
          </div>
        </div>
      
        <div className="row w-90 max-500 mx-auto pb-5">
      
          <div className="col-12 text-center">
            <img
              src="/articles/article6_30_percent_nocopy.svg"
              alt=""
              className="w-100 my-3"
            />
            <a
              href="https://www.futuremedicine.com/doi/10.2217/bmt-2019-0001"
              className="btn btn-primary hcp mt-3 w-250"
            >
              Read The Study
            </a>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto pt-5">
          <div className="col-12">
            <p className="text-center">
              <b>
                In a prospective study of 96 estrogen receptor–positive (ER+)
                patients led by Yale University, over 25% of
                physician treatment recommendations related to EET changed after
                receiving the BCI result.<sup>2</sup>
              </b>
            </p>
          </div>
        </div>
        <div className="row w-90 max-700 mx-auto pb-5">
          <div className="col-12 mb-5">
            <ul>
              <li>
                BCI resulted in net decrease in recommendations for EET by 20%
              </li>
              <li>
                Twice as many patients reported feeling “strongly comfortable”
                with the treatment decision after receiving their BCI test
                results
              </li>
              <li>
                3X as many physicians felt strongly confident in their treatment
                recommendations
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-6">
            <p>
              <b>~1/2 of Patients Reported:</b>
            </p>
            <ul>
              <li>A change in their treatment preference</li>
              <li>
                Experiencing less anxiety (p=0.031), 13% had no change in
                anxiety
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-6">
            <p>
              <b>~2/3 of Patients Reported:</b>
            </p>
            <ul>
              <li>Decreased decisional conflict (p&#60;0.001)</li>
              <li>
                Knowing the results would make them more likely to be compliant
                with extended therapy
              </li>
            </ul>
          </div>
     
                  <a
                    href="https://link.springer.com/article/10.1007/s10549-015-3631-9"
                    className="btn btn-primary hcp mt-3 w-250 mx-auto"
                  >
                    Read The Study
                  </a>
                
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <p className="ref">
              <strong>References:</strong>{' '}
			  <strong>1.&nbsp;</strong>Sanft T, et al. Breast Cancer Manag. 2019;8(1).{' '}
			  <strong>2.&nbsp;</strong>Sanft T, et al. Breast Cancer Res Treat. 2015;154(3):533&#8209;541.
            </p>
            <a
              href="/resources-providers"
              className="btn btn-secondary hcp mt-5 w-250"
            >
              Back To All
            </a>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter
        site="hcp"
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard",
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: "Resources For Providers",
            text1: "Resources",
            text2: "For Providers",
          },
        ]}
        disclaimer=""
      />

      <Footer
        showTopLinks={true}
        topLinks={[
          { link: "/", label: "For Patients" },
          { link: "/know-someone-with-breast-cancer", label: "For Supporters" },
        ]}
        showSecondaryLinks={true}
        secondaryLinks={[
          { link: "/resources-providers", label: "Resources" },
          { link: "/contact-providers", label: "Explore The Report" },
          { link: "/contact-providers", label: "Contact" },
          { link: "/order-test", label: "Order The Test" },
        ]}
      />
    </div>
  );
}

export default Article6;
