//Copyright Breast Cancer Index || 2021
//Global navbar

import React, { useState } from 'react';

import { Dropdown, NavDropdown, Modal, Button } from 'react-bootstrap';

import { Link, NavLink } from 'react-router-dom';

import { Nav, Navbar, NavItem } from 'react-bootstrap';

import './Navbar.scss';

const CTA = () => {
    return (
        <div className='d-flex align-items-center notice'>
            <div className='text-center mx-auto'>
                Biotheranostics announces the inclusion of Breast Cancer Index in the ASCO<sup>®</sup> Clinical Practice Guideline to help guide extended endocrine therapy decisions.{' '}
                <a className='banner-link' href='/press-release-page-042522' target='_blank'>
                    Click here to learn more.
                </a>
            </div>
        </div>
    );
};

const Header = ({ type, showCTA }) => {
    const [showNavbar2, setNavbar2] = useState(false);
    const handleShow = () => {
        setNavbar2(!showNavbar2);
    };

    const [show, setShow] = useState(false);

    const handleCloseModal = () => setShow(false);
    const handleShowModal = () => setShow(true);

    function processContent(type) {
        if (type === 'home') {
            return (
                <>
                    <Navbar.Collapse className='collapse navbar-collapse flex-column w-100' id='navHome'>
                        <Nav className='navbar-nav ml-auto w-100 justify-content-end home-nav'>
                            <NavLink to='/resources-providers' activeClassName='nav-link-active'>
                                For Providers
                            </NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </>
            );
        } else if (type === 'blank') {
            return <></>;
        } else if (type === 'patients') {
            // <!-- PATIENT NAVBAR // HIDE OTHERWISE -->

            return (
                <Navbar.Collapse className='collapse navbar-collapse flex-column w-100' id='navbarSupportedContent'>
                    <Nav className='navbar-nav xl-center w-100'>
                        <NavDropdown title="I'm A Patient" id='nav-dropdown'>
                            <NavDropdown.Item className='dropdown-item w-100' href='/recently-been-diagnosed'>
                                Recently Diagnosed?
                            </NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item w-100' href='/already-on-estrogen-therapy'>
                                On or Restarting Therapy?
                            </NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item w-100' href='/what-to-expect'>
                                What the Test Tells You
                            </NavDropdown.Item>
                        </NavDropdown>

                        <NavLink to='/explore-the-report-patients' activeClassName='nav-link-active' className='nav-link ul'>
                            Explore the Report
                        </NavLink>
                        <NavLink to='/contact-patients' activeClassName='nav-link-active' className='nav-link ul'>
                            Support
                        </NavLink>
                        <NavLink to='/faqs' activeClassName='nav-link-active' className='nav-link ul'>
                            FAQs
                        </NavLink>
                        <NavLink to='/know-someone-with-breast-cancer' activeClassName='nav-link-active' className='nav-link ul'>
                            I Support A Patient
                        </NavLink>
                        <NavLink to='/resources-providers' activeClassName='nav-link-active' className='nav-link ul'>
                            For Providers
                        </NavLink>
                    </Nav>
                </Navbar.Collapse>
            );
        } else if (type === 'providers') {
            // <!-- HCP NAVBAR // HIDE OTHERWISE  -->

            return (
                <Navbar.Collapse className='collapse navbar-collapse flex-column w-100' id='navbarSupportedContent'>
                    <Nav className='navbar-nav ml-auto w-100 justify-content-end'>
                        <NavLink to='/resources-providers' activeClassName='nav-link-active' className='nav-link ul'>
                            Resources
                        </NavLink>
                        <NavLink to='explore-the-report-providers' activeClassName='nav-link-active' className='nav-link ul'>
                            Explore the Report
                        </NavLink>
                        <NavLink to='/order-test' activeClassName='nav-link-active' className='nav-link ul'>
                            Order the Test
                        </NavLink>
                        <NavLink to='/contact-providers' activeClassName='nav-link-active' className='nav-link ul'>
                            Contact
                        </NavLink>
                        <NavLink to='/' className='nav-link ul'>
                            For Patients
                        </NavLink>
                    </Nav>
                </Navbar.Collapse>
            );
        }
    }

    function processToggle(type) {
        if (type !== 'home') {
            return (
                <Navbar.Toggle
                    className='navbar-toggler'
                    type='button'
                    data-toggle='collapse'
                    data-target='#navbarSupportedContent'
                    aria-controls='navbarSupportedContent'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                >
                    <span className='navbar-toggler-icon'></span>
                </Navbar.Toggle>
            );
        } else if (type === 'home') {
            return (
                <Navbar.Toggle
                    className='home-hamburger'
                    type='button'
                    data-toggle='collapse'
                    data-target='#navHome'
                    aria-controls='navHome'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                >
                    <span className='icon'></span>
                </Navbar.Toggle>
            );
        }
    }

    return (
        <div className='fixed-top'>
            {showCTA && <CTA />}
            <Navbar className={'navbar navbar-static-top navbar-light navbar-expand-lg' + (type === 'home' ? ' nav-home' : '')} expand='lg'>
                <div className='container'>
                    <a href='/' className='navbar-brand d-flex mr-auto'>
                        <img src='/BCI_Hologic_Logo_RGB.png' alt='breast cancer index - not another minute' />
                    </a>
                    {processToggle(type)}
                    {processContent(type)}
                </div>
            </Navbar>
        </div>
    );
};

export default Header;
