//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop,
    SEO
} from "../../lib";

import ReactGA from 'react-ga';

const TermsAndConditions = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='Terms and Conditions' description='Breast Cancer Index is here to help women understand if anti-estrogen therapy is right for them. Read our BreastCancerIndex.com Terms and Conditions here.' />
      <Navbar type="home" />
      
      {/* <!-- TEMP LANDING --> */}
      <header className="page-header photo-grad">
        <div className="container-fluid bgc g3 round">
          <div className="clover-container">
            <div className="copy headline">
              <h1>
                Terms And Conditions                                
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-closed.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      {/* <!-- CONTENT AREA --> */}
      <div className="container content-page">
        <div className="row">
          <div className="col-12 pt-5 border-bottom">
            <h2 className="font1 clr c1 py-2">
              BreastCancerIndex.com Terms and Conditions
            </h2>
            
            <p>
            Biotheranostics maintains the BreastCancerIndex Web site as a service to the Internet community. The BreastCancerIndex Web site has been designed to provide general information about bioT3 to physicians. The information on this Web Site is provided solely on an “AS IS” and “AS AVAILABLE” basis. Biotheranostics is not engaged in rendering investment or medical advice via this Web Site, nor does it provide instruction on the appropriate use of products produced, supplied or under development by Biotheranostics, its affiliates, related companies, or its licensors or joint venture partners. Access and use of the Biotheranostics Web site is subject to the terms and conditions set forth herein and all applicable laws, statutes, and/or regulations. Biotheranostics does not, by or through this Web site, offer for sale any products or services.</p>

            <p>
            Biotheranostics may revise or modify these terms and conditions from time to time. Such changes, revisions, or modifications shall be effective immediately upon notice to you, which may be given by any means, including, without limitation, posting on the Biotheranostics Web Site. Any use of this Web Site by you after such notice shall be deemed to constitute acceptance of the changes.</p>

            <p>
            Biotheranostics will use reasonable efforts to include accurate and up-to-date information on the MetaststicCancerSolution Web site, however, any information presented on the Web site as of a particular date may only be accurate as of such date and Biotheranostics disclaims any responsibility to update such information. Information about companies other than Biotheranostics contained in news, press releases or otherwise that are posted on the Web Site should not be relied upon as being provided or endorsed by Biotheranostics.</p>

            <p>
            ALL CONTENT ON THIS WEB SITE IS PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTY OF ANY KIND EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, AND NON-INFRINGEMENT. Biotheranostics makes no warranty as to the accuracy, completeness, currency, or reliability of any content available through this Web Site. You are responsible for verifying any information before relying on it. Use of the Web Site and the content available on the Web Site is at your sole risk. Biotheranostics makes no representations or warranties that use of the Web Site will be uninterrupted or error-free. You are responsible for taking all necessary precautions to ensure that any content you obtain from the Web Site is free of viruses.</p>

            <p>
            BIOTHERANOSTICS SHALL NOT BE HELD RESPONSIBLE FOR ANY ACTION TAKEN THAT IS BASED ON THE INFORMATION PRESENTED ON THE MetaststicCancerSolution WEB SITE. BIOTHERANOSTICS EXPRESSLY DISCLAIMS ANY LIABILITY, WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH ACCESS TO OR USE OF THE WEB SITE, EVEN IF BIOTHERANOSTICS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING BUT NOT LIMITED TO RELIANCE BY ANY PARTY ON ANY CONTENT OBTAINED THROUGH THE USE OF THE WEB SITE, THE INABILITY TO USE THE WEB SITE, OR ANY ERRORS OF OMISSIONS IN THE CONTENT OF THE WEB SITE.</p>

            <p>By submitting any communication or material to Biotheranostics, you agree that: (1) if you are a healthcare professional you will not submit any information that would directly identify a patient or any information for which you do not have all necessary consents or authorizations to disclose; (2) you will only submit content that complies with applicable law, and will not submit content that is abusive, defamatory, obscene, infringing, threatening, repetitive, or otherwise inappropriate, or that contains any viruses or other software that may adversely affect the operation of another’s computer; (3) such content will be deemed non-confidential; and (4) you grant to Biotheranostics the irrevocable right to use, copy modify, publish, perform, transmit and display such content via any media in accordance with Biotheranostics’ Privacy Statement, and waive any moral rights you may have in such content. Subject to Biotheranostics’ Privacy Statement, Biotheranostics shall be free to use such content, including any ideas, concepts, know-how, or techniques contained in such content, for any reason.</p>

            <p>It is the policy of Biotheranostics to enforce its intellectual property rights to the fullest extent permitted by law. All product names, regardless of whether or not they appear in large print or with a trademark symbol, are the trademarks and service marks of Biotheranostics, its affiliates, related companies, or its licensors or joint venture partners, unless otherwise noted. All content of the MetaststicCancerSolution Web site, including any images or text, is protected by U.S. and foreign copyright laws and may not be distributed, downloaded, modified, reused, reposted, or otherwise used except that you may view, use, and download a single copy of this Web site for your informational, non-commercial use. Except as provided herein, no part of any content or software on this Web site may be copied, downloaded or stored in a retrieval system for any other purpose, nor may it be redistributed for any purpose, without the express written permission of Biotheranostics. The use or misuse of Biotheranostics’ trademarks, service marks copyrights, or other materials, except as permitted herein, is expressly prohibited and may be a violation of copyright law, trademark law, communications regulations and statutes, and other laws, statutes and/or regulations.</p>

            <p>Biotheranostics reserves the right to seek all remedies available at law and in equity for violations of these terms and conditions for using the MetaststicCancerSolution Web site, including the right to block access from a particular Internet address to the Web site.</p>

            <p>Biotheranostics' <a target='_blank' href='/privacy-policy'>privacy statement</a> (“privacy statement”), as it may change from time to time, is a part of these terms and conditions for using the MetaststicCancerSolution Web site and is incorporated herein by this reference. Biotheranostics reserves the right, and you authorize Biotheranostics, to the use and assignment of all information regarding your use of this Web site and all information provided by you in any manner consistent with Biotheranostics' <a target='_blank' href='/privacy-policy'>privacy statement</a></p>

            <p>If you have any questions about these terms and conditions, please contact us at <a href='privacy@biotheranostics.com'>privacy@biotheranostics.com</a></p>
          </div> 
        </div>
      </div>

      <BackToTop />

      <SubFooter 
        image=""
        icons={[
          {
            href: "/contact-providers",
            img: "/icon-bubble.svg",
            alt: " Contact Breast Cancer Index",
            text1: "Contact Breast",
            text2: "Cancer Index"
          },{
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard"
          }
        ]} />
        
      <Footer
        showTopLinks={true} topLinks={[
          { link: "/", label: "For Patients" }
        ]}
        showSecondaryLinks={true} secondaryLinks={[
          { link: "/order-test", label: "Order The Test" },
          { link: "/contact-providers", label: "Contact" }
        ]}
        type='provider'
      />

    </div>
  );
}

export default TermsAndConditions;
