//Copyright Breast Cancer Index || 2021

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop,
    FloatingPetal
} from "../../lib";

import ReactGA from 'react-ga';

const Article7 = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc hcp-gradient round">
          <div className="clover-container">
            <div className="copy headline middle">
              <h1 className="mt-0 color-hologic-blue">
                What Is Her
                 <br />
                Risk Of Late
                 <br />
                Distant
                 <br />
                Recurrence?
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />

      <div className="container content-page">
        <div className="row w-90 mx-auto">
          <div className="col-12 py-5 text-center">
            <h3 className="pb-2">
              Breast Cancer Index<sup>&reg;</sup> Prognostic Validation For Risk Of
              Late Distant Recurrence
            </h3>
            <h4 className="font1 clr c1">
              Breast Cancer Index Has Extensive Validation Demonstrating Its
              Ability To Determine Risk Of Late (Post 5-Year) Distant Recurrence
              In Women With Early-Stage, Hormone Receptor–Positive (HR+) Breast
              Cancer
            </h4>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto pt-5">
          <div className="col-12 text-center">
            <p>
              The ability of Breast Cancer Index to determine an individual’s
              risk of late distant recurrence has been validated across multiple
              studies, including various treatment histories, and a mix of pre-
              and post-menopausal patients.<sup>1-3</sup>
            </p>
            <p>
              For patients completing standard adjuvant endocrine therapy who
              are disease-free at 5 years.
            </p>
          </div>
        </div>
        <div className="row w-90 max-700 mx-auto pb-5">
          <div className="col-12 text-center">
            <img
              src="/articles/article7-chart-combined.svg"
              alt=""
              className="w-100"
            />
          </div>
        </div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <p>
              <b>
                Distant Recurrence Rate From Years 5 to 10 As Measured By Breast
                Cancer Index
              </b>
            </p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <h4 className="font1 clr c1 mx-auto mt-4 mb-5">
              Read the Full Breast Cancer Index Prognostic Validation Studies
            </h4>
            <p>
              1.{" "}
              <a
                href="https://clincancerres.aacrjournals.org/content/19/15/4196.long"
                target="_blank"
              >
                Breast Cancer Index Identifies Early-Stage Estrogen
                Receptor–Positive Breast Cancer Patients at Risk for Early- and
                Late-Distant Recurrence »
              </a>
            </p>
            <p>
              2.{" "}
              <a
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3918681"
                target="_blank"
              >
                Prediction of late distant recurrence in estrogen receptor
                positive breast cancer patients: prospective comparison of the
                Breast Cancer Index (BCI), Oncotype DX recurrence score, and
                IHC4 in TransATAC »
              </a>
            </p>
            <p>
              3.{" "}
              <a
                href="https://clincancerres.aacrjournals.org/content/early/2017/09/22/1078-0432.CCR-17-1688#"
                target="_blank"
              >
                A novel Breast Cancer Index for prediction of distant recurrence
                in HR+ early stage breast cancer with 1 to 3 positive nodes »
              </a>
            </p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <p className="ref">
              <strong>References:</strong>{' '}
			  <strong>1.&nbsp;</strong>Zhang Y, et al. Clin Cancer Res. 2013;19(15):4196&#8209;205.{' '}
			  <strong>2.&nbsp;</strong>Sgroi, et al., Lancet Oncology, 2013,14(11):1067&#8209;1076.{' '}
			  <strong>3.&nbsp;</strong>Zhang Y et al. Clin Cancer Res. 2017 Dec 1;23(23):7217&#8209;7224.
            </p>
            <a
              href="/resources-providers"
              className="btn btn-secondary hcp mt-5 w-250"
            >
              Back To All
            </a>
          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter
        site='hcp'
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard",
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: "Resources For Providers",
            text1: "Resources",
            text2: "For Providers",
          },
        ]}
        disclaimer=""
      />

      <Footer
        showTopLinks={true}
        topLinks={[
          { link: "/", label: "For Patients" },
          { link: "/know-someone-with-breast-cancer", label: "For Supporters" },
        ]}
        showSecondaryLinks={true}
        secondaryLinks={[
          { link: "/resources-providers", label: "Resources" },
          { link: "/contact-providers", label: "Explore The Report" },
          { link: "/contact-providers", label: "Contact" },
          { link: "/order-test", label: "Order The Test" },
        ]}
      />
    </div>
  );
}

export default Article7;
