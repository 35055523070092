//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop,
    SEO
} from "../../lib";

import ReactGA from 'react-ga';

const PrivacyStatement = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='Privacy Policy' description='Here at Breast Cancer Index, we value your privacy and security. You can read the full Breast Cancer Index privacy policy here for more information.' />
      <Navbar type="patients" />

      {/* <!-- TEMP LANDING --> */}
      <header className="page-header photo-grad">
        <div className="container-fluid bgc g3 round">
          <div className="clover-container">
            <div className="copy headline">
              <h1>
                Privacy Statement
              </h1>
            </div>
            <div className="photo-clover">
              <img src="/clover-closed.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      {/* <!-- CONTENT AREA --> */}
      <div className="container content-page">
        <div className="row">
          <div className="col-12 pt-5 border-bottom">
            <h2 className="font1 clr c1 py-2">Privacy Statement</h2>
            <h6><b>Last Updated:</b> June 2, 2020</h6>

            <h3 className="font1 clr c1 py-2">1. Please Read Carefully</h3>
            <p>This Privacy Statement describes the information Biotheranostics, Inc. (“Company”) collects about you though our website, how we use and share that information, and the privacy choices we offer.</p>

            <h3 className="font1 clr c1 py-2">2. Changes to this Privacy Statement</h3>
            <p>We may change this Privacy Statement from time to time. If we make changes, we will notify you by posting the updated policy on our website and revising the “Last Updated” date above. We encourage you to review the Privacy Statement whenever you use our website to stay informed about our information practices and about ways you can help protect your privacy.</p>

            <h3 className="font1 clr c1 py-2">3. Confidentiality of Medical Information</h3>
            <p>Certain protected health information that may be provided by you may be protected as described in our <a target='_blank' href='https://biotheranostics.com/privacy-practices.html'>Notice of Privacy Practices</a>. In the event of a conflict between the terms of this Privacy Statement and the terms of the <a target='_blank' href='https://biotheranostics.com/privacy-practices.html'>Notice of Privacy Practices</a>, the terms of the <a target='_blank' href='https://biotheranostics.com/privacy-practices.html'>Notice of Privacy Practices</a> shall control.</p>

            <h3 className="font1 clr c1 py-2">4. Use of Website</h3>
            <p>Your access to and use of our website is subject to certain terms and conditions, which are set forth in our Terms and Conditions.</p>

            <h3 className="font1 clr c1 py-2">5. Collection of Information</h3>

            <div className="ml-3">
              <h4 className="font1 clr c1 py-2">5.1 Information You Provide</h4>
              <p>We collect information you provide, such as when you email us or submit information
                through our website. We may collect, but are not limited to collecting, the following
                information: your name, address, email address, current state of treatment , and issues we
                can help you with.
              </p>

              <h4 className="font1 clr c1 py-2">5.2 Children</h4>
              <p>Company does not knowingly collect or maintain personally identifiable information
                from persons under 13 years of age without verifiable parental consent, and no part of the
                website is directed at persons under 13. If Company learns that personally identifiable
                information of persons less than 13 years of age has been collected without verifiable
                parental consent, Company will take the appropriate steps to delete this information. To
                make such a request, please contact us at <a href="mailto:privacy@biotheranostics.com">privacy@biotheranostics.com</a>.
              </p>

              <h4 className="font1 clr c1 py-2">5.3 Information We Collect from Your Use of the Website</h4>
              <p>We collect information about you when you use our website, including, but not limited to the following:</p>

              <ul className="mb-0">
                <li><b>Device Information.</b> We may automatically collect certain information about the computer or devices (including mobile devices) you use to access the website. For example, we may collect and analyze information such as (a) IP addresses, geolocation information (as described in the next section below), unique device identifiers and other information about your mobile phone or other mobile device(s), browser types, browser language, operating system, the state or country from which you accessed the website; and (b) information related to the ways in which you interact with the website, such as: referring and exit pages and URLs, platform type, the number of clicks, domain names, landing pages, pages and content viewed and the order of those pages, the amount of time spent on particular pages, the date and time you used the website, the frequency of your use of the website, error logs, and other similar information. As described further below, we may use third-party analytics providers and technologies, including cookies and similar tools, to assist in collecting this information.</li>
                <li><b>Location Information.</b> We may collect different types of information about your location, including general information (e.g., IP address, zip code) and more specific information (e.g., GPS-based functionality on mobile devices used to access the website), and may use that information to customize the website with location-based information and features. In order to do this, your location information may be shared with our agents, vendors or advertisers. If you access the website through a mobile device and you do not want your device to provide us with location-tracking information, you can disable the GPS or other location-tracking functions on your device, provided your device allows you to do this. See your device manufacturer’s instructions for further details.</li>
                <li><b>Cookies and Other Electronic Technologies.</b> We may use the tools outlined below in order to better understand users. As we adopt additional technologies, we may also gather additional information through other methods.

                  <ul className="mb-0">
                    <li><p><b>Cookies:</b> “Cookies” are small computer files transferred to your computing device that contain information such as user ID, user preferences, lists of pages visited and activities conducted while using the website. We use Cookies to help us improve or tailor the website by tracking your navigation habits, storing your authentication status so you do not have to re-enter your credentials each time you use the website, customizing your experience with the website, and for analytics and fraud prevention.</p>
                        <p>Cookies used by our business partners may collect information when you use the website, such as the IP address, mobile device ID, operating system, browser, web page interactions, the geographic location of your internet service provider, and demographic information such as sex and age range. These Cookies help Company learn more about our users’ demographics and internet behaviors.</p>
                        <p>For more information on cookies, visit <a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a>.</p>
                    </li>
                    <li><b>Website Analytics:</b> We may use third-party website analytics services in connection with the website, including, for example, to register mouse clicks, mouse movements, scrolling activity and text that you type into the website or mobile application. The information collected by website analytics providers does not collect information that would personally identify you, such as your name. We use the information collected from these services to help make the website easier to use.</li>
                    <li><b>Mobile Device Identifiers:</b> Mobile device identifiers are data stored on your mobile device that may track mobile device and data and activities occurring on and through it, as well as the applications installed on it. Mobile device identifiers enable collection of personal information (such as media access control, address and location) and traffic data. Mobile device identifiers help Company learn more about our users’ demographics and internet behaviors.</li>
                  </ul>

                </li>
              </ul>

              <h4 className="font1 clr c1 py-2">5.4 Aggregate or De-identified Data</h4>
              <p>We may aggregate and/or de-identify information collected by the website or via other means so that the information does not identify you. Our use and disclosure of aggregated and/or de-identified information is not subject to any restrictions under this Privacy Statement, and we may disclose it to others without limitation for any purpose, in accordance with applicable laws and regulations.</p>
            </div>

            <div className="jump" id="use-of-information"></div>
            <h3 className="font1 clr c1 py-2">6. Use of Information</h3>
            <p>We use the information that we collect for the following purposes:</p>
            <ul>
              <li>For the purposes for which you provided the information;</li>
              <li>To contact you when necessary or requested;</li>
              <li>To provide, maintain, administer, improve, or expand the website and our services, perform business analyses, or for other internal purposes to support, improve or enhance our business and other products and services we offer;</li>
              <li>To customize and tailor your experience of the website;</li>
              <li>To send emails and other communications that display content that we think will interest you and according to your preferences;</li>
              <li>To send you news and information about our services;</li>
              <li>To track and analyze trends and usage in connection with our website;</li>
              <li>To better understand who uses the website and how we can deliver a better user experience;</li>
              <li>To combine information received from third parties with the information that we have from or about you and use the combined information for any of the purposes described in this Privacy Statement;</li>
              <li>To use statistical information that we collect in any way permitted by law, including from third parties in connection with their commercial and marketing efforts;</li>
              <li>To prevent, detect, and investigate security breaches, fraud, and other potentially illegal or prohibited activities;</li>
              <li>To enforce the legal terms that govern your use of the website;</li>
              <li>To protect our rights or property;</li>
              <li>To administer and troubleshoot the website; and</li>
              <li>For any other purpose disclosed to you.</li>
            </ul>
            <p>We may use third-party service providers to process and store personal information in the United States and other countries.</p>

            <h3 className="font1 clr c1 py-2" id="sharing">7. Sharing of Information</h3>
            <p>We may share personal information about you as follows:</p>
            <ul>
              <li>With third parties to provide, maintain, and improve our services, including service providers who access information about you to perform services on our behalf;</li>
              <li>With service providers who access information about you to perform services on our behalf;</li>
              <li>With our affiliates and partners so that they may use such information for the purposes described in this Privacy Statement;</li>
              <li>In connection with, or during the negotiation of, any merger, sale of company stock or assets, financing, acquisition, divestiture or dissolution of all or a portion of our business (but only under non-disclosure and confidentiality agreements and protections);</li>
              <li>If we believe that disclosure is reasonably necessary to comply with any applicable law, regulation, legal process or governmental request; to enforce applicable user agreements or policies; to protect the security or integrity of our services; and to protect us, our users or the public from harm or illegal activities; and</li>
              <li>With your consent.</li>
            </ul>
            <p>We may also share aggregated, non-personally identifiable information with third parties.</p>

            <h3 className="font1 clr c1 py-2">8. Security</h3>
            <p>We take reasonable measures, including administrative, technical, and physical safeguards, to help protect personal information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. Unfortunately, no data transmission over the Internet can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, Company cannot ensure or warrant the security of any information you transmit to us or from our online products or services, and you do so at your own risk.</p>

            <h3 className="font1 clr c1 py-2">9. Your Privacy Choices</h3>

            <div className="ml-3">
              <h4 className="font1 clr c1 py-2">9.1 How You Can Access and Update Your Information</h4>
              <p>You may update or correct information about yourself at any time or by emailing us at <a href="mailto=privacy@biotheranostics.com">privacy@biotheranostics.com</a>.</p>

              <h4 className="font1 clr c1 py-2">9.2 Cookies</h4>
              <p>Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject cookies; however, our website may not function properly if you do so.</p>

              <h4 className="font1 clr c1 py-2">9.3 Options for Opting out of Cookies and Mobile Device Identifiers</h4>
              <p>Our website uses Google Analytics. You can learn more about how Google uses information it collects from the Google Analytics cookie at <a href=" https://policies.google.com/technologies/partner-sites"> https://policies.google.com/technologies/partner-sites</a>.</p>
              <p>If you are interested in more information about interest-based advertising and how you can generally control cookies from being put on your computer to deliver tailored advertising, you may visit the <a href="https://optout.networkadvertising.org/?c=1">Network Advertising Initiative’s Consumer Opt-Out</a> link, the <a href="https://optout.aboutads.info/">Digital Advertising Alliance’s Consumer Opt-Out</a> link or <a href="http://preferences-mgr.truste.com/">TRUSTe’s Advertising Choices Page</a> to opt-out of receiving tailored advertising from companies that participate in those programs.</p>
              <p>Please note that even after opting out of interest-based advertising, you may still see Company’s advertisements that are not interest-based (i.e., not targeted toward you). Also, opting out does not mean that Company is no longer using its tracking tools—Company still may collect information about your use of the website even after you have opted out of interest-based advertisements and may still serve advertisements to you via the website based on information it collects via the website.</p>

              <h4 className="font1 clr c1 py-2">9.4 How Company Responds to Browser “Do Not Track” Signals</h4>
              <p>We are committed to providing you with meaningful choices about the information collected on our website for third-party purposes, and that is why we provide above the Network Advertising Initiative’s “Consumer Opt-out” link, Digital Advertising Alliance’s Consumer Opt-Out Link, and TRUSTe’s Advertising Choices page. However, we do not recognize or respond to browser-initiated Do Not Track signals, as the Internet industry is currently still working on Do Not Track standards, implementations and solutions. For more information about DNT signals, visit <a href="http://allaboutdnt.com">http://allaboutdnt.com</a>.</p>

              <h4 className="font1 clr c1 py-2">9.5 Links to Other Websites</h4>
              <p>Our website may contain links to other websites and those websites may not follow the same privacy practices as Company. We are not responsible for the privacy practices of third party websites. We encourage you to read the privacy policies of such third parties to learn more about their privacy practices.</p>

              <h4 className="font1 clr c1 py-2">9.6 California Shine the Light Law</h4>
              <p>California law permits users who are California residents to request and obtain from us once a year, free of charge, a list of the third parties with whom we have disclosed their personal information (if any) for their direct marketing purposes in the prior calendar year, as well as the type of personal information disclosed to those parties. If you are a California resident and would like to request this information, please submit your request in an email to <a href="mailto:privacy@biotheranostics.com">privacy@biotheranostics.com</a>.</p>

              <h4 className="font1 clr c1 py-2">9.7 California Consumer Privacy Act of 2018 (“CCPA”)</h4>
              <p>This section only applies to California residents (“Consumers”). For the purposes of this section only, “Personal Information” means information that identifies, relates to, describes, references, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer or household.</p>
              <p>Consumers have the following rights:</p>

              <ul className="mb-0">
                <li><b>Right to Request Information.</b> You have the right to request that we disclose the following information to you, limited to the preceding twelve (12) months:</li>

                <ul className="mb-0">
                  <li>The categories of Personal Information that we collected about you.</li>
                  <li>The categories of sources from which the Personal Information is collected.</li>
                  <li>The business or commercial purpose for collecting or selling Personal Information.</li>
                  <li>The categories of third parties with whom we share Personal Information.</li>
                  <li>The specific pieces of Personal Information that we have collected about you.</li>
                  <li>The categories of Personal Information that we disclosed about you for a business purpose.</li>
                  <li>The categories of Personal Information that we sold about you and the categories of third parties to whom the Personal Information was sold, by category or categories of Personal Information for each category of third parties to whom the Personal Information was sold.</li>
                </ul>

                <p>Please note that we are only required to respond to such requests from you twice in a twelve-month period.</p>

                <li><b>Right of Deletion.</b> You have the right to request that we delete any Personal Information about you which we have collected from you.</li>
                <li>We do not and will not ever sell your personal information.</li>
                <li><b>Non-Discrimination.</b> We may not discriminate against you because you exercise any of your rights under the CCPA, including, but not limited to:</li>

                <ul className="mb-0">
                  <li>Denying goods or services to you.</li>
                  <li>Charging different prices or rates for goods or services, including through the use of discounts or other benefits or imposing penalties.</li>
                  <li>Providing a different level or quality of goods or services to you.</li>
                  <li>Suggesting that you will receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
                </ul>

                <li><b>Limited Rights of Job Applicants, Employees, and Contactors.</b> If you are a job applicant, employee or contractor, to the extent that we collect information from you in your role as such, you have the right, at or before the point of collection, to know the categories of Personal Information that we will collect or have collected and the purposes for which we will use the categories of Personal Information.</li>

                <p>You may submit requests for information by calling us at (877) 886-6739, by emailing us at <a href="privacy@biotheranostics.com">privacy@biotheranostics.com</a>.</p>
                <p>The categories of sources from which we collect Personal Information are:</p>

                <ul className="mb-0">
                  <li>directly from you;</li>
                  <li>directly from our web server logs; and</li>
                  <li>with cookies, web beacons, and device identifiers.</li>
                </ul>

                <p>The categories of third parties with whom we share Personal Information are described in detail in the <a href="#sharing">“Sharing of Information”</a> section of this Privacy Statement.</p>
                <p>The chart below describes:</p>

                <ul className="mb-0">
                  <li>The categories of Personal Information that we have collected about Consumers in the preceding twelve (12) months and may collect about you through our website.</li>
                  <li>The categories of Personal Information that we have disclosed about Consumers for a business purpose in the preceding twelve (12) months.</li>
                  <li>The categories of Personal Information that we have sold about Consumers in the preceding twelve (12) months.</li>
                </ul>

                <div className="table-scroller">
                  <div className="scroll">
                    ← SCROLL →
                  </div>
                  <div className="scroll-content">
                    <table class="table table-bordered">
                      <tr>
                        <th>Category</th>
                        <th>Examples D</th>
                        <th>Disclosed for a Business Purpose</th>
                        <th>Sold</th>
                      </tr>
                      <tr>
                        <td>A. Identifiers</td>
                        <td>Real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, or other similar identifiers</td>
                        <td>Yes</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>B. Personal Information categories described in Cal. Civ. Code § 1798.80(e)</td>
                        <td>Name, signature, address, telephone number, insurance policy number, medical information, or health insurance information</td>
                        <td>Yes</td>
                        <td>No</td>
                        </tr>
                      <tr>
                        <td>C. Protected classification characteristics under California or federal law</td>
                        <td>Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information)</td>
                        <td>No</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>D. Commercial information</td>
                        <td>Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies</td>
                        <td>No </td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>E. Biometric information</td>
                        <td>Genetic, physiological, behavioral, and biological characteristics, health</td>
                        <td>Yes</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>F. Internet or other similar network activity</td>
                        <td>Browsing history, search history, information on a consumer’s interaction with a website, application, or advertisement</td>
                        <td>Yes</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>G. Geolocation data</td>
                        <td>Physical location or movements</td>
                        <td>Yes</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>H. Sensory data</td>
                        <td>Audio, electronic, visual, thermal, olfactory, or similar information</td>
                        <td>No</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>I. Professional or employment-related information</td>
                        <td>Current or past job history or performance evaluations</td>
                        <td>No</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>J. Education information not covered by the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99))</td>
                        <td>Current or past educational history</td>
                        <td>No</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>K. Inferences drawn from other Personal Information</td>
                        <td>Profile reflecting a person’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes</td>
                        <td>No</td>
                        <td>No</td>
                      </tr>
                    </table>
                  </div>
                </div>

                <p>The purposes for which the categories of Personal Information shall be used, and the business or commercial purposes for collecting or selling personal information, are described in detail in the <a href="#use-of-information">Use of Information</a> section of this Privacy Statement.</p>
              </ul>
            </div>

            <h3 className="font1 clr c1 py-2">10. No Rights of Third Parties</h3>
            <p>This Privacy Statement does not create rights enforceable by third parties.</p>

            <h3 className="font1 clr c1 py-2">11. Questions</h3>
            <p>Please contact us with any questions or concerns regarding this Privacy Statement at <a href="mailto:privacy@biotheranostics.com">privacy@biotheranostics.com</a>.</p>

          </div>
        </div>
      </div>

      <BackToTop />

      <SubFooter
        image=""
        icons={[
          {
            href: "/contact-providers",
            img: "/icon-bubble.svg",
            alt: " Contact Breast Cancer Index",
            text1: "Contact Breast",
            text2: "Cancer Index"
          },{
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard"
          }
        ]} />

      <Footer
        showTopLinks={true} topLinks={[
          { link: "/", label: "For Patients" }
        ]}
        showSecondaryLinks={true} secondaryLinks={[
          { link: "/order-test", label: "Order The Test" },
          { link: "/contact-providers", label: "Contact" }
        ]}
        type='provider'
      />

    </div>
  );
}

export default PrivacyStatement;
