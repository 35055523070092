//Copyright Breast Cancer Index || 2021
//Main Page

import React from 'react';

import { Navbar, SubFooter, Footer, BackToTop, FloatingPetal, SEO } from "../../lib";

import ReactGA from 'react-ga';

function PressReleasePage1211() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <SEO title='The Power to Predict' description='Biotheranostics and the Not Another Minute Campaign, exploring how BCI is revolutionary for early-stage, hormone-receptor-positive breast cancer patients.' />
      <Navbar type="providers" />

      <header className="page-header">
        <div className="container-fluid bgc g3 round">
          <div className="clover-container">
            <div className="copy headline">
              <h1 className="mt-0 color-hologic-blue">
                The Power <br />To Predict
              </h1>
            </div>
            <div className="photo-clover">                          
              <img src="/clover-gold-open.svg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />
      

      <div className="container content-page">
      <div className="row w-90 mx-auto my-5">
          <div className="col-12 pt-5 pb-2 text-center">
            <h3 className="pb-2">
            Biotheranostics’ Breast Cancer Index<sup>&reg;</sup> predicts benefit from two-and-a-half versus five years of extended endocrine therapy in HR+ breast cancer patients treated in the IDEAL trial
            </h3>
            <h6 className="font1 clr c10 mt-5">
            SAN DIEGO, Calif., May 13, 2020
            </h6>
          </div>
        </div>       
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">   
          <div className="col-12">
            <p>SAN DIEGO, Calif., May 13, 2020. Novel evidence demonstrating the ability of Breast Cancer Index to predict preferential endocrine response in distinct subgroups of HR+ early stage breast cancer patients will be premiered at ASCO 2020. The Investigation on the Duration of Extended Adjuvant Letrozole (IDEAL) trial examined the Breast Cancer Index (BCI) HOXB13/IL17BR ratio (H/I) and its ability to predict benefit from extended endocrine therapy. The IDEAL study randomized patients to an additional 2.5 versus 5 years of extended endocrine therapy after completing 5 years of endocrine therapy with tamoxifen or aromatase inhibitors. Findings from the study demonstrate that the Breast Cancer Index was significantly predictive of extended endocrine benefit from 5 versus 2.5 years of extended letrozole in patients that received any primary adjuvant therapy including combination tamoxifen/aromatase inhibitor or aromatase inhibitor monotherapy.</p>
            <p>These findings add to the growing body of evidence demonstrating that Breast Cancer Index is the only commercially available genomic assay with predictive evidence to help inform the decision of extended endocrine therapy for HR+, early-stage breast cancer patients. Alongside previous validation studies, BCI clinical evidence for prediction of endocrine benefit has been shown in three distinct therapy groups, patients treated with Tamoxifen/Tamoxifen, Tamoxifen-Aromatase Inhibitor and now, for the first time Aromatase Inhibitor/Aromatase Inhibitor.</p>
            <p>Catherine Schnabel, Ph.D., Chief Scientific Officer, Biotheranostics, said, “Results from the IDEAL study is pivotal in that they strengthen BCI clinical utility to better align with current standards of care for endocrine treatment. As the decision of extending endocrine therapy becomes increasingly individualized, BCI has a distinct and important role as a genomic tool to identify patients that are likely to benefit and have improved outcomes with longer durations of endocrine therapy.”</p>
            <p>Don Hardison, Biotheranostics’ President and CEO adds, “We are thrilled that Breast Cancer Index continues to reach the high levels of evidence demonstrating the test’s ability to predict which hormone receptor positive women are likely to benefit from extended endocrine therapy and those that are not. It further solidifies that 100% of women deserve to know what is right for them regarding extension of endocrine therapy. For providers, knowing if treatment is benefiting their patient gives them the confidence when prescribing an additional 5 years of endocrine therapy versus stopping at seven years. We are fortunate to offer the only commercially available biomarker that predicts benefit of extended endocrine therapy.”</p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 mx-auto my-5">
          <div className="col-12">
            <h4>About Breast Cancer Index<sup className="sup-sm">TM</sup></h4>
            <p>Breast Cancer Index is a molecular, gene expression-based test uniquely positioned to provide information to help physicians individualize treatment decisions for patients with early stage, ER+ breast cancer. This breakthrough test helps oncologists and patients navigate the difficult trade-off between taking steps to prevent recurrence of their disease and facing significant side effects and safety challenges related to unnecessary treatment. Breast Cancer Index holds guidelines designation from the American Joint Committee on Cancer for cancer staging based on molecular profile; ASCO, NCCN, European Group on Tumor Markers (EGTM), and St. Gallen to inform the chemotherapy decision; and ASCO and EGTM to inform the extended endocrine treatment dilemma. It is the only validated, commercially available test that provides risk of overall and late distant recurrence and predicts the likelihood of benefit from extended endocrine therapy. For more information, visit <a href="https://www.breastcancerindex.com" target="_blank">www.breastcancerindex.com</a>.</p>
            <h4>About Biotheranostics</h4>
            <p>
              1Biotheranostics, Inc. operates a CLIA-certified and CAP-accredited diagnostic laboratory in
              San Diego, California. Biotheranostics, Inc., is a leading healthcare provider in the
              oncology field, assisting physicians in the treatment of cancer patients. Its suite of
              proprietary molecular diagnostic tests provides important information to physicians to
              tailor treatment to individual patients. The company’s <a href="https://breastcancerindex.com" target="_blank">Breast Cancer Index</a><sup>&reg;</sup> and <a href="https://www.cancertypeid.com/" target="_blank">CancerTYPE ID</a><sup>&reg;</sup> tests address a variety of unmet medical
              needs in the management of cancer patients, and extensive clinical studies have confirmed
              the accuracy, clinical validity, clinical utility, and cost-effectiveness of the tests.
              Learn more at <a href="http://www.biotheranostics.com" target="_blank">www.biotheranostics.com</a>.</p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row w-90 max-700 mx-auto py-5">
          <div className="col-12 text-center">
            <a href="/resources-providers" className="btn btn-secondary hcp w-250">Back To All</a>
          </div>
        </div>
      </div>
      
      <BackToTop />

      <SubFooter 
        image=""
        icons={[
          {
            href: "/order-test",
            img: "/icon-clipboard.svg",
            alt: " Order Breast Cancer Index",
            text1: "Order Breast",
            text2: "Cancer Index",
            className: "clipboard"
          },
          {
            href: "/resources-providers",
            img: "/icon-gear.svg",
            alt: " Resources for Providers",
            text1: "Resources",
            text2: "for Providers"
          }
        ]} />
      
      <Footer 
            showTopLinks={true} topLinks={[
              {link: "/", label: "For Patients"},
              {link: "/know-someone-with-breast-cancer", label: "For Supporters"}
            ]}
            showSecondaryLinks={true} secondaryLinks={[
              {link: "/providers-resources", label: "Resources"},
              {link: "/contact-providers", label: "Explore The Report"},
              {link: "/contact-providers", label: "Contact"},
              {link: "/order-test", label: "Order The Test"}
            ]} />

    </div>   
  );

}

export default PressReleasePage1211;
